.landing_banner_main_wrapper{
    width: 100%;
  padding-top: 0px;
    background-color: #FAFAFA;
    /*background-color: red;*/
    /*background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662102678/Rectangle_251_rthgt5.png");*/
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    /*box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.48);*/
    background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662109838/Vector_1_lstndg.png");
}


.landing_banner_inner_wrapper{
    width: 100%;
    height: 650px;
    margin-bottom:0px;
    margin-top: 0px;

}

.content_landing_wrapper_main{
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 600px;

}
.content_landing_wrapper{
    padding: 20px;
    padding-left: 60px;
    /*background-color: #d9d9d9;*/
    width: 100%;


}
.content_landing_wrapper div h3{
    font-size: 40px;
    font-family: Poppins;
    font-weight: bold;
    text-transform: uppercase;


}
.darkGray{
    color: #404A50;
}
.darkBlue {
    color: #1468BA;
}


.content_image_wrapper{
    padding: 10%;
    width: 100%;
    /*background-color: red;*/

}
.content_image_wrapper img{
    width: 100%;
}
.image_banner_class img{
    width: 80% !important;
}

.btn_landing_wrapper{
    margin-top: 50px;
}
.landing_banner_button{
    background-color: #FF9900;
    color: #ffffff;

    border-radius: 5px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;

}
.landing_banner_button:hover{
    background-color: orange;
    color: white;

}



@media screen and (max-width: 1150px) {
    .image_banner_class img{
        width: 100% !important;
    }
}



@media screen and (max-width: 950px) {
    .content_landing_wrapper{
        padding: 20px;
        padding-left: 60px;


    }
    .content_landing_wrapper div h3{
        font-size: 30px;
        font-family: Poppins;


    }
    .content_image_wrapper{
        padding: 30px;
        width: 100%;
        /*background-color: red;*/

    }
    .content_image_wrapper img{
        width: 100%;
    }

}


@media screen and (max-width: 800px) {
    .content_landing_wrapper div h3{
        font-size: 24px;
        font-family: Poppins;


    }
    .content_landing_wrapper{
        padding: 20px;
        padding-left: 60px;
        width: 100%;
        /*background-color: #FC6522;*/


    }
    .content_image_wrapper{
        padding: 3%;
        width: 100%;
        /*background-color: red;*/

    }
    .content_image_wrapper img{
        width: 100%;
    }
}


@media screen and (max-width: 680px) {
    .content_landing_wrapper{
        padding: 20px;
        padding-left: 40px;
        width: 100%;


    }
    .content_landing_wrapper div h3{
        font-size: 20px;
        font-family: Poppins;


    }
    .content_image_wrapper{
        padding-right: 30px;
        width: 100%;
        /*background-color: red;*/

    }
    .content_image_wrapper img{
        margin-left: 0px;
        width: 100%;
    }
}


@media screen and (max-width: 560px) {
    .content_landing_wrapper{
        padding: 30px;

    }
    .content_landing_wrapper div h3{
        font-size: 24px;
        text-align: center;
        font-family: Poppins;


    }
    .content_landing_wrapper_main{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /*background-color: #d9d9d9;*/
        height: 450px;
    }
    .landing_banner_main_wrapper{

        background-position: right;
        background-repeat: no-repeat;
        background-image:none;
         }
    .landing_banner_inner_wrapper{
        width: 100%;
        height: 400px;
    }
    .content_image_wrapper{
        padding-right: 0px;
        display: none;
        width: 200%;
        /*background-color: red;*/

    }
    .btn_landing_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

}
