.Chex_video_wrapper{
    width: 100%;
    /*height: 530px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.chex-demo-video_custom{
    border-radius: 3% ;
    height: 380px;
    width: 674px;
    border: none;
    z-index: 1;
}

@media screen and (max-width: 1024px) {

    .chex-demo-video_custom{
        border-radius: 3% ;
        height: auto;
        width: 100%;
        /*border: 1px solid red;*/
    }
}

@media only screen and (max-width: 767px) {
    .chex-demo-video_custom{
        border-radius: 3% ;

        width: 100%;
        /*border: 1px solid green;*/
    }
}


@media only screen and (max-width: 600px) {
    .chex-demo-video_custom{
        border-radius: 3% ;
        width: 90%;
        border: 1px solid blue;
    }
}


@media only screen and (max-width: 550px){

    .chex-demo-video_custom{
        border-radius: 3% ;
        width: 90%;
        border: 1px solid transparent;
    }
}
@media only screen and (max-width: 480px) {


    .chex-demo-video_custom {
        border-radius: 15px;

        width: 90%;
        /*border: 1px solid red;*/
        margin-left: -5px;
    }

}