.login-main-container {
  width: 100%;
  position: absolute;
  background: url('../../Assets/bg_img.png');
  height: 100%;
  background-size: cover;
  background-repeat: repeat;
  background-position: center top;
}

.login-image-background-signin {
  position: relative;
  width: 100%;

  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center top; */
}

.login-main-bg-signin {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 46px 0;
}

.login-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2000;
}

a {
  color: #fff;
}
.login-heading-logo {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 30px 0 0 0;
}
.logo-span-color {
  color: #ff9900;
}

.login-desp-heading {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0px;
  margin-bottom: 10px !important;
}

.login-form-main-container {
  text-align: center;
  width: 100%;
  margin: 30px auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.forgot-password-link {
  color: white;
  font-size: 15px;
  margin-bottom: 18px;
;
}

.login-form-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
}

.login-form-container {
  width: 45%;
}

@media screen and (min-width: 768px) {
  .login-image-background-signin {
    background-size: cover;
    background-position: center center;
  }
}

@media only screen and (max-width: 600px) {
  .ant-form-item-control-input {
    margin: auto !important;
    width: 500px !important;
  }
}
@media only screen and (max-width: 550px) {
  .ant-form-item-control-input {
    margin: none !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 600px) {
  .login-form-container {
    width: 40%;
  }
}

@media screen and (max-width: 600px) {
  .login-form-container {
    width: 100%;
  }
}
@media screen and (min-width: 1289px) {
  .login-form-container {
    width: 30%;
  }
}
