.companyInstructionHeading {
  font-size: 17px;
  font-weight: 900;
  padding: 10px 10px 10px 20px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
}

.inpection-instruction-btn-container {
  position: fixed;
  bottom: 10px;
  width: 29.899%;
}

.select-company-inspection-price {
  color: #ff9900;
  text-align: center;
  padding: 4px;
  background-color: #fff5e5;
  padding-left: 30px;
  padding-right: 30px;
}

.select-company-heading {
  font-size: 17px;
  font-weight: 900;
  /* border-bottom: 1px solid #cfdeed;   */
  padding: 15px;
}

.select-company-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #fbfbfb; */
  padding: 20px;
  border-top: 1px solid #cfdeed;
  margin-bottom: 5px !important;
  /* border-bottom: 1px solid #cfdeed; */
}

.company-name-text {
  color: #0b5eaf;
  font-size: 16px;
  font-weight: 700;
}

.inpection-instruction-btn-container {
  position: fixed;
  bottom: 0px;
  width: 29.899%;
}

@media only screen and (max-width: 1024px) {
  .inpection-instruction-btn-container {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .inpection-instruction-btn-container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .inpection-instruction-btn-container {
    width: 100%;
    background-color: white;
    /* padding: 20px; */
    height: 120px;
    margin-bottom: -50px;
  }

  .select-company-checkbox-container {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.select-company-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.select-company-header-content {
  width: 32%;
}

@media only screen and (max-width:1024px) {
  .select-company-header-content {
    width: 60%;
  }
}

@media only screen and (max-width:768px) {
  .select-company-header-content {
    width: 93%;
  }
}

@media only screen and (max-width:600px) {
  .select-company-header-content {
    width: 100%;
  }
}

.eRLuLF {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B !important;
}

.IPtEP {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B !important;
}

.license-plate {
  margin-left: 25px;
  width: 90%;
  /* margin-top: 2px; */
  margin-bottom: 10px;
}

.license-plate-text {
  height: 20px;
  padding-top: 20px;
  background-color: #f1f1f1;
}

.license-plate-text::placeholder {
  color: #9D9D9D !important;
  font-weight: 900;
  font-size: 18px;
  font-family: 'Poppins';
}

.license-plate-error-text {
  height: 20px;
  padding-top: 20px;
  background-color: #f1f1f1;
}

.license-plate-error-text::placeholder {
  color: red !important;
  font-weight: 900;
  font-size: 18px;
  font-family: 'Poppins';
}

@media only screen and (min-width:1800px) {
  .license-plate {
    margin-left: 35px !important;
  }
}

@media only screen and (max-width:1440px) {
  .license-plate {
    margin-left: 18px !important;
  }
}

@media only screen and (max-width:1024px) {
  .license-plate {
    margin-left: 27px !important;
  }
}

@media only screen and (max-width:468px) {
  .license-plate {
    margin-left: 20px !important;
  }
}

.oXDdG {
  height: 100vh !important;
}

.OGHxE {
  height: 100vh !important;
}

.drop-down.ant-select {
  width: 90%;
  height: 35px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 3px;
}

.drop-down.ant-select:hover {
  color: grey;
  background: #f1f1f1;
  border-color: #f1f1f1;
  background-color: #f1f1f1;
}

.drop-down.ant-select:focus {
  color: grey;
  background: #fff;
  border-color: #f1f1f1;
  background-color: #f1f1f1;
}

.drop-down-container {
  align-self: center;
  display: flex;
  justify-content: center;
}

.select-state-heading {
  margin-left: 14px;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 3px;
}

.select-company-checks_container {
  height: calc(107vh - 530px);
  overflow-y: scroll;
  box-sizing: border-box;
  /* margin-bottom: 60; */
}


@media only screen and (min-width:1500px) {
  .select-company-checks_container {
    height: 400px;
    overflow-y: hidden;
  }
}