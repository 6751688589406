.box_badge_wrapper_outer{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items:flex-end;
    flex-direction: row;
    /*background-color: red;*/
    margin-top: -80px;
    padding-bottom: 30px;
}

.text_techStart_para_landing{
    font-size: 28px;
}

.a_text_landing{
    font-size: 28px !important;
}

@media screen and (max-width: 643px){
    .box_badge_wrapper_outer{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items:center;
        flex-direction: row;
        flex-wrap: wrap;
        /*background-color: red;*/
        margin-top: 0px;
    }
    .text_techStart_para_landing{
        font-size: 24px;
    }

    .a_text_landing{
        font-size: 24px !important;
    }
}



/*key_benefits_wrapper_landing stying start*/

.key_benefits_wrapper_landing{

}


/*Community Wrapper Styling Start*/

.community_wrapper_Landing{
    /*margin-top: 20px;*/
    /*margin-bottom: 20px;*/
}







/*Landing Tailored Section styling start*/

.main_wrapper_tailored_section_outer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    padding-left: 100px;
    padding-right: 100px;

}


.main_wrapper_tailored_section_outer .heading_tailored_main{
    font-size: 32px;
    font-family: Poppins;
    font-weight: bold;
    text-align: center;
    color: #000000;
    padding-bottom: 20px;

}
.main_wrapper_tailored_section_outer .heading_tailored_main span{
    color: #FF9900;

}

.main_wrapper_tailored_section_inner{
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*flex-direction: column;*/
    /*background-color: white;*/
    /*background-color: rgba(123, 255, 0, 0.37);*/
    width: 100%;
    border-radius: 20px;
    padding-top: 4%;
    padding-bottom: 4%;
    box-shadow: 5px 5px 10px #A6B4C8;

}
.solution_wrapper_boxes_outer{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    /*background-color: rgba(0, 128, 0, 0.2);*/
    align-items: center;
    padding: 0px;

}

.solution_wrapper_box{
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*flex-direction: column;*/
    padding: 0px;
    width: 100%;
    /*width: 500px;*/
    height: 300px;
    /*background-color: rgba(255, 0, 0, 0.2);*/


}
.line_design{
    /*padding-right: 40px;*/
    /*padding-left: 40px;*/
    margin:0px;
    padding:0px;
    border-right: 0.5px solid #b4b1b1;

}
.solution_wrapper_box .image_wrapper_tailored_box{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;


}
.solution_wrapper_box .image_wrapper_tailored_box img{
    width:200px;
}
.image_wrapper_tailored_box .car_image_mobility{
    width: 170px !important;
}
.solution_wrapper_box h3{
    font-size: 20px;
    font-family: Poppins;
    font-weight: bold;
    color: #1468BA;
    text-align: center;
    padding-top: 10px;


}
.solution_wrapper_box p{

    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    color: #1B1C1F;
    text-align: center;
    padding-left: 70px;
    padding-right: 70px;


}

@media screen and (max-width: 1184px) {
    .solution_wrapper_box p{
        padding-left: 50px;
        padding-right: 50px;


    }

}

@media screen and (max-width: 1419px) {
    /*.solution_wrapper_box{*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    flex-direction: column;*/
    /*    padding: 30px;*/
    /*    width: 300px;*/
    /*    height: 350px;*/
    /*    !*background-color: rgba(255, 0, 0, 0.2);*!*/
    /*}*/


}



@media screen and (max-width: 1119px) {
    /*.solution_wrapper_box{*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    flex-direction: column;*/
    /*    padding: 30px;*/
    /*    width: 250px;*/
    /*    height: 350px;*/
    /*    !*background-color: rgba(255, 0, 0, 0.2);*!*/
    /*}*/


}




@media screen and (max-width: 1076px) {
    .main_wrapper_tailored_section_outer{
        padding: 50px;
        padding-left: 50px;
        padding-right: 50px;

    }
    .solution_wrapper_box h3{

        font-size: 16px;
    }
    .solution_wrapper_box p{

        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;


    }


}


@media screen and (max-width: 894px) {
    .solution_wrapper_box{
        padding: 10px;
        /*width: 240px;*/
        height: 300px;
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }
    .solution_wrapper_box .image_wrapper_tailored_box img{
        /*width: 80%;*/
    }
    .image_wrapper_tailored_box .car_image_mobility{
        width: 150px!important;
    }
    .solution_wrapper_box h3{
        font-size: 16px;
    }
    .solution_wrapper_box p{

        font-family: Poppins;
        font-size: 14px;



    }


}


@media screen and (max-width: 839px) {
    .solution_wrapper_box{

        padding: 10px;
        /*width: 340px;*/
        /*height: 300px;*/
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }
    .solution_wrapper_box .image_wrapper_tailored_box img{
        /*width: 80%;*/
    }
    .image_wrapper_tailored_box .car_image_mobility{
        width: 60% !important;
    }
    .solution_wrapper_box h3{
        font-size: 16px;
    }
    .solution_wrapper_box p{

        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;


    }

    .line_design{
        border: none;
    }

    .line_design_new{
        border-right: 0.5px solid #b4b1b1;
    }
}


@media screen and (max-width: 799px) {
    .solution_wrapper_box {
        padding: 10px;
        /*width: 300px;*/
        height: 320px;
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }




}

@media screen and (max-width: 767px) {
    .main_wrapper_tailored_section_outer{
        padding: 50px;
        padding-left: 80px;
        padding-right: 80px;

    }

    .main_wrapper_tailored_section_inner{
        padding-top: 0%;
        padding-bottom: 0%;
        padding-left: 4%;
        padding-right: 4%;
        box-shadow: 5px 5px 10px #A6B4C8;

    }
    .solution_wrapper_box {
        padding: 10px;
        /*width: 300px;*/
        height:auto;
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }
    .solution_wrapper_box .image_wrapper_tailored_box{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;


    }
    .solution_wrapper_box .image_wrapper_tailored_box img{
        width: 200px;
        padding-bottom: 0px;
    }
    .image_wrapper_tailored_box .car_image_mobility{
        width: 180px !important;

    }
    .solution_wrapper_box h3{
        font-size: 16px;
        padding-top: 20px;
    }
    .solution_wrapper_box p{

        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;


    }
    .line_design_new{
        border-right: none;

    }
    .bottom_line{
        border-bottom: 0.5px solid #b4b1b1;

    }
    .padding_style{
        padding: 20px;
    }


}

@media screen and (max-width: 719px) {
    .solution_wrapper_box {
        /*padding: 10px;*/
        /*width: 250px;*/
        /*height: 300px;*/
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }

}

@media screen and (max-width: 619px) {
    .main_wrapper_tailored_section_outer .heading_tailored_main{
        font-size: 30px;
    }

    .solution_wrapper_box {

        padding: 0px;

        /*background-color: rgba(255, 0, 0, 0.2);*/
    }


}

@media screen and (max-width: 440px) {
    .main_wrapper_tailored_section_outer{
        padding: 30px;
        padding-left: 10px;
        padding-right: 10px;

    }
    .main_wrapper_tailored_section_outer .heading_tailored_main{
        font-size: 20px;

    }

}