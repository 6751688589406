.review-card-container{
    max-width: 660px;
    width:400px;
    display: flex;
    flex-direction: column;

    /*height: 250px;*/
    background-color: #F9F9F9;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
}
.review-text{
    font-family: Poppins,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}
.name-rate-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /*float: right;*/
}
.reviewer-name{
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: -5px;
}

@media screen and (max-width: 517px){
    .review-card-container{
        padding:20px;
        margin-top: 30px;
        width:370px;
        /*height:270px;*/
    }
}
@media screen and (max-width: 340px){
    .review-card-container{
        padding:20px;
        margin-top: 30px;
        width:330px;
        /*height:300px;*/
    }
    .name-rate-container{
        margin-right: 20px;
    }
}
