@import "~antd/dist/antd.css";

.menuBar-contact-us {
  padding: 0 20px;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  z-index: 5;
  /* background-color: black; */
}

.ScrollBackColor{

  transition:0.4s;
  border-bottom: 1px solid black;
}
.ScrollBlackColor{
  background-color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
}

.menuContactWhite .leftMenu a{
    color: white;
}

.menuContactWhite {
  /*width: calc(100% - 150px);*/
  float: right;
}



.menuContactWhite .ant-menu-item {
  padding: 0px 5px;
}

.menuContactWhite .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuContactWhite .ant-menu-item a,
.menuContactWhite .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuContactWhite .ant-menu-horizontal {
  border-bottom: none;
}

.menuContactWhite .leftMenu {
  float: right;
}

.menuContactWhite .leftMenu a{
  color: #fff;
}

.menuContactWhite .leftMenu a:hover{
  color:#fff!important;
  text-decoration: none!important;
  border-bottom:0px solid #FF7A00!important;
}

.ant-menu-item-selected{
  color:#FF7A00!important;
  border:none!important;
  /*border-bottom: 4px solid red!important;*/
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
  border-bottom: 0px solid rgba(49, 44, 44, 0.133);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color:#FF7A00!important;
}
.menuContact .leftMenu a:hover{
  color:#000!important;
  text-decoration: none!important;
  border-bottom:0px solid #FF7A00!important;
}
.ankrPricing{
  color:#FF7A00!important;
  text-decoration: none!important;
  border-bottom:0px solid #FF7A00!important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after{
  border:none!important;
}
.ant-menu-horizontal:hover  > .ant-menu-item::after{
  border-bottom:none!important;
}
.ant-menu-submenu::after{
  border-bottom:none!important;
}

@media  screen and (max-width: 500px) {
  .menuBar-contact-us{
    width: 100%;
  }

}
.logo {
  /*width: 150px;*/
  float: left;
  margin-top: 5px;
}

.logo img {
  /*height: 50px;*/
  /*width: 150px;*/
  width:60%;
}

.logoWhite {
  /*width: 150px;*/
  float: left;

  margin-top: 7px;
}

.logoWhite img {
  padding-left: 20px;
  width:70%;
  /*height: 45px;*/
  /*width: 150px;*/
}

@media screen and (max-width: 970px){
  .logoWhite img {
    width:70%;

  }
  .logo img {

    width:70%;
  }

}
@media screen and (max-width: 939px){
  .logoWhite img {
    width:70%;
    padding-left:10px;

  }
  .logo img {

    width:70%;
    padding-left:10px;
  }


}

@media screen and (max-width: 860px){
  .logoWhite img {
    width:60%;
    padding-left:10px;

  }
  .logo img {

    width:60%;
    padding-left:10px;
  }


}
@media screen and (max-width: 840px){
  .logoWhite img {
    width:150px;
    padding-left:10px;

  }
  .logo img {

    width:150px;
    padding-left:10px;
  }


}

@media screen and (max-width: 814px){
  .logoWhite img {
    width:150px;
    padding-left:10px;

  }
  .logo img {

    width:150px;
    padding-left:10px;
  }
  .my-menu-item{
    font-size: 12px;
    padding:0px 13px;
  }


}

@media only screen and (max-width:767px) {
  .logo img {
    /*height: 33px;*/
    /*width: 100px;*/
    width: 70%;
    padding-left: 7px;
  }
  .logoWhite img{
    width: 70%;
    padding-left: 7px;
    /*height: 33px;*/
    /*width: 100px;*/
  }
}

@media only screen and (max-width:567px) {
  .logo img {
    /*height: 33px;*/
    width: 60%;
    /*width: 100px;*/
  }

  .logoWhite img {
    width: 60%;
    padding-left: 0px;
    margin-top:5px;
    /*height: 33px;*/
    /*width: 100px;*/
  }
  .menuBar-contact-us{
    padding: 10px 10px;
  }

}


@media only screen and (max-width:423px) {
  .logo img {
    /*height: 33px;*/
    /*width: 100px;*/
    width:60%;
    padding-left: 10px;
  }

  .logoWhite img {
    width: 60%;
    padding-left: 10px;
    margin-top:5px;
    /*height: 33px;*/
    /*width: 100px;*/
  }
  .menuBar-contact-us{
    padding: 10px 10px;
  }

}

@media only screen and (max-width:380px) {



}

.menuNavbarMain{
  background-color: transparent;
  color:white!important;

}
.ant-menu-submenu-title:hover{
  color:#FF7A00!important;
}
.ant-menu-submenu:hover{
  color:#FF7A00!important;
  border-bottom:none!important;
}
.ant-menu-submenu-popup:hover > .ant-menu-submenu-title{
  color:#FF7A00!important;
}
.ant-menu-sub{

  background-color:black!important;
}
@media screen and (max-width: 768px){
  .ant-menu-sub{

    background-color:#1B223B!important;
  }
  .ant-menu-submenu-arrow{
    color:white!important;
  }


}
.menuItemClr{
  color:white!important;
  text-decoration: none;

}
.dropDownMenuWrap{
  background-color: black;
  color: white;
}
.dropDownMenuWrap:hover{
  color:#FF7A00!important;
  /* border-bottom: 1px solid #FF7A00; */
  /*border-bottom:3px solid #FF7A00!important;*/
}
.linkNavbar_wrap_menu{

  color: white!important;
}
.linkNavbar_wrap{
  /* color: white!important; */
}
.ant-dropdown-link-contact-us {
  padding: 0px 25px;
}


/* .linkNavbar_wrap:hover{
 color: red!important;
} */
/*.logo a {*/
/*  display: inline-block;*/
/*  font-size: 20px;*/
/*  color:red;*/
/*  padding: 19px 20px;*/
/*  text-transform: capitalize;*/
/*}*/

.menuContact {
  /*width: calc(100% - 150px);*/
  /* padding-right: 120px; */
  float: right;
}
/* @media screen and (max-width: 1100px){
  .menuContact{
    padding-right: 12px!important;
  }

} */

.menuContact .ant-menu-item {
  padding: 0px 5px;
}

.menuContact .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuContact .ant-menu-item a,
.menuContact .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuContact .ant-menu-horizontal {
  border-bottom: none;
}

.menuContact .leftMenu {
  float: right;
}

.menuContact .leftMenu a{
  color: black;
}

.barsMenu-black {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: #000;
}
.barsMenu-black:hover {
  background-color: #000;
  border: none !important;
}
.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: white;
  position: relative;
}
.barsBtn:hover {
  border: none !important;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: white;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu-black>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu-black {
    display: block;
    border: none !important;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .logoWhite a {
    margin-left: -20px;
  }

  .menuContact .ant-menu-item,
  .menuContact .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .menuContactWhite .ant-menu-item,
  .menuContactWhite .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }

  .logoWhite a {
    padding: 10px 20px;
  }
}

/* @media  screen and (max-width: 1124px) {
  .menuBar-contact-us{
    width: 98%;
  }

} */

@media  screen and (max-width: 1180px) {
  .menuContact .ant-menu-item a, .menuContact .ant-menu-submenu-title a {
    padding: 10px 5px;
  }
  .menuContactWhite .ant-menu-item a, .menuContactWhite .ant-menu-submenu-title a {
    padding: 10px 5px;
  }

}

@media  screen and (max-width: 1380px) {
  .menuBar-contact-us{
    width: 100%;
  }

}
.my-menu-item{
  padding: 0 25px;

}

@media  screen and (max-width: 1006px) {
  .menuContact .ant-menu-item a, .menuContact .ant-menu-submenu-title a {
    padding: 10px 1px;
  }
  .menuContactWhite .ant-menu-item a, .menuContactWhite .ant-menu-submenu-title a {
    padding: 10px 1px;
  }

  .ant-dropdown-link-contact-us {
    padding: 0px 10px;
  }
  .my-menu-item{
    padding: 0 15px;

  }

}

/*.ant-menu-horizontal:hover {*/
/*  line-height: 46px;*/
/*  border: 0;*/
/*  border-bottom: 1px solid #57c717!important;*/
/*  box-shadow: none;*/
/*}*/

/*.ant-menu-item{*/
/*  border-bottom:3px solid green;*/

/*}*/



.linkNavbar_wrap_pricing{
  color: #000000!important;
}
.linkNavbar_wrap_pricing:hover{
  color: #1468BA!important;
}

@media screen and (max-width: 768px) {
  .linkNavbar_wrap_pricing{
    color: #ffffff!important;
  }
}
.linkNavbar_wrap_black {
    color: black!important;
}
.linkNavbar_wrap_black:hover {
  color: black !important;
  border-bottom: 2px solid black;
}

.linkNavbar_wrap_white {
  color: white!important;
}
.linkNavbar_wrap_white:hover {
color: white !important;
border-bottom: 2px solid white;
}



.my-menu-item:hover{
  color:#FF7A00!important;
}

.dropDown_newnav{
  background-color: black;
  padding-bottom: 10px;
  padding-top: 10px;
}
.menu-items_newnav{
   margin-top: 10px;
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: #FF7A00!important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent!important;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  color:white!important;
  background: #FF7A00!important;

}

.ant-menu-submenu-title:hover {
  color: white!important;
}


