.main_section_review_custom{
    background-color: #FFFFFF;
    margin-bottom: 100px;
    position: relative;
    margin-top: 40px;

}

.review_container_main_new  .rec.rec-arrow-right {

    top: 40%;
    right:6%;


}
.review_container_main_new .rec.rec-arrow-left{

    left: 6%;
    top: 40%;

}

@media only screen and (max-width: 1200px) {

   .review_container_main_new  .rec.rec-arrow-right {


       top: 40%;
       right:3%;


    }
    .review_container_main_new .rec.rec-arrow-left{

        left: 3%;
        top: 40%;

    }

}

@media only screen and (max-width:1014px) {

    .review_container_main_new  .rec.rec-arrow-right {
        top:inherit ;
        bottom:-20%;
        right:43%;


    }
    .review_container_main_new .rec.rec-arrow-left{
        top:inherit ;
        bottom:-20%;

        left:43%;


    }

}

@media screen and  (max-width: 767px){
    .main_section_review_custom{
        /*margin-bottom: 0px;*/
    }

}



.headingCustomerReviews_custom{
    padding-left: 0px;
    font-size:35px;
    font-family: Poppins;
    color: black;
    text-align: center;
    font-weight: bold;
}
.main_section_review_custom .para_heading_sub {
    font-size:18px;
    font-family: Poppins;
    color: black;
    text-align: center;
    font-weight: 400;
}
@media screen and (max-width: 767px) {
    .headingCustomerReviews_custom{
        font-size:28px;
    }

}
@media screen and (max-width: 570px) {
    .headingCustomerReviews_custom{

        font-size:20px;
    }

}
@media screen and (max-width: 455px) {
    .headingCustomerReviews_custom{

        font-size:24px;
    }
    .main_section_review_custom .para_heading_sub {
        font-size:14px;

    }

}
@media screen and (max-width: 320px) {
    .headingCustomerReviews_custom{

        font-size:20px;
    }
    .main_section_review_custom .para_heading_sub {
        font-size:12px;

    }

}


.review_container_main_new{
    width: 100%;

    background-color: #FFFFFF;
}



@media screen and  (max-width: 767px){
    .review_container_main_new{
        width: 100%;
        background-color: #FFFFFF;

        padding-top: 0px;
        margin-bottom: 30px;
    }

    .review_container_main_new .rec.rec-arrow-right {
        background-color: #F4F9FF;
        border-radius: 30px;
        position: absolute;
        right:40%;
        bottom:-10%;

    }
    .review_container_main_new .rec.rec-arrow:hover{
        background-color: #F4F9FF!important;
        color:black!important;
    }
    .review_container_main_new .rec.rec-arrow:focus{
        background-color: #F4F9FF!important;
        color:black!important;
    }

    .review_container_main_new .rec.rec-arrow:hover{
        background-color: #F4F9FF;
    }
    .review_container_main_new .rec.rec-arrow-left{

        background-color:#F4F9FF;
        border-radius: 30px;
        z-index: 1;
        bottom:-10%;
        position: absolute;
        left: 40%;

    }


}


@media only screen and (max-width: 480px) {
    .review_container_main_new{
        width: 100%;

        background-color: #FFFFFF;
        padding-top: 30px;
    }


    .review_container_main_new .rec.rec-arrow-right {

        right:35%;
        bottom:-10%;


    }
    .review_container_main_new .rec.rec-arrow-left{
        left: 35%;
        bottom:-10%;
    }

}


@media  screen and (max-width: 360px){
    .review_container_main_new  .rec.rec-arrow-right {

        right:30%;
        bottom:-10%;

    }
    .review_container_main_new .rec.rec-arrow-left{
        left: 30%;
        bottom:-10%;
    }

}


@media  screen and (max-width: 330px){
    .review_container_main_new .rec.rec-arrow-right {
        right:30%;
        bottom:-10%;
    }
    .review_container_main_new .rec.rec-arrow-left{
        left: 30%;
        bottom:-10%;
    }
}

