

/*Pricing Section Styling*/

.pricingSections_uber{

    padding:28px;
    padding-left: 100px;
    padding-right: 100px;
}
.headingPricing{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 31px;

    color: black;
}

.paragraphPricing{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;


    color: #414B55;

}
.new_btn_pricingSection{
    margin-top: 40px !important;
    margin-bottom: 10px !important;
}

.pricingPoints{
    display: flex;

    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;

}
.pricingPointsText{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /*line-height: 0px;*/
    color: #5D6F84;
}

@media screen and (max-width: 768px) {
    .pricingPoints{
        display: flex;

        flex-direction: row;
        padding-top: 5px;
        padding-bottom: 5px;

    }

}


.pricingCardRow{

    padding-top:30px;
    padding-bottom: 30px;
}
@media screen and (max-width: 767px){
    .pricingCardRow{
        margin-left: 170px;

    }
}

@media screen and (max-width: 672px){
    .pricingCardRow{
        margin-left: 120px;

    }
}

@media screen and (max-width: 540px){
    .pricingCardRow{
        margin-left: 80px;

    }
}

@media screen and (max-width: 500px){
    .pricingCardRow{
        margin-left: 70px;

    }
}
@media screen and (max-width: 473px){
    .pricingCardRow{
        margin-left: 50px;

    }
}

@media screen and (max-width: 450px){
    .pricingCardRow{
        margin-left: 0px;

    }
}



.pricingColumn{
    width: 100%;


}
.priceCardCompo{
    margin-right: 20px;

}


@media  screen and (max-width: 1200px) {
    .pricingColumn{
        margin-top: 50px;
    }
}


@media  screen and (max-width: 768px) {
    .pricingSections_uber{
        background: #FFF8F1;
        padding:28px;
        padding-left: 60px;
        padding-right: 60px;
    }
}
@media  screen and (max-width: 590px) {
    .pricingSections_uber{
        background: #FFF8F1;
        padding:28px;
        padding-left: 30px;
        padding-right: 30px;
    }

}

.btn_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_styling_uber{

    background-color:#FF7A00 ;
    padding:10px;
    padding-left:50px;
    padding-right: 50px;

    border:1px solid #FF7A00;
    border-radius: 50px;

    color:white;
    margin-bottom: 10px;

}

.btn_styling_uber:hover{

    background-color:#FFFFFF ;


    border:1px solid #FF7A00;

    color:#FF7A00;
    transition: 0.5s;

}
