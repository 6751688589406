@import url(https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap);
.intro-main-container {
  width: 100%;
}
.intro-imageBackground-main {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: url(/static/media/bg_img.f9acb53d.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}
@media screen and (min-width: 768px) {
  .intro-imageBackground-main {
    background-size: cover;
    background-position: center center;
  }
}
.intro-mainBg {
  background: linear-gradient(0deg, rgba(9, 11, 12, 0) 50%, #1b68b3 99.97%);
  position: relative;
  width: 100%;
  height: 100%;
}
.thank-contentMain-screen {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 90%;
}
.intro-headingH5 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #74b3fb;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0px;
}
.intro-headingH1 {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 0px;
  width: 100%;
}
.intro-headingSpan {
  color: #ff9900;
}
.intro-contentP {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;

}
.intro-content-footer-area-intro {
  position: absolute;
  bottom: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro-validation-text {
  text-align: center;
  color: white;
}
.intro-state-name {
  color: #ff7400;
}
.intro-MtB5 {
  /* margin-bottom: 42px; */
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-signinBtn {
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 8px 60px 15px;
  text-decoration: none;
  width: 260px;
}

.thankyou-contentP {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: white;
}
.thankyou-chex-contentP {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: white;
}
.thank-you-paragrpah {
  font-size: 14px;
  color: #b2c7f0;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.thank-text-contaner {
  width: 30%;
}

@media screen and (max-width: 600px) {
  .intro-signinBtn {
    width: 80%;
  }

  .thank-text-contaner {
    width: 100%;
  }
}
.intro-contentDiv-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}
.intro-signUpWith-email {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: none;
  padding: 12px 52px;
  text-decoration: none;
  width: 260px;
}
@media screen and (max-width: 600px) {
  .intro-signUpWith-email {
    width: 80%;
  }
}
.intro-signUpWith-email:active {
  outline: none;
  box-shadow: 0 0 0 0 !important;
}
.intro-signUpWith-email:focus {
  outline: none;
  box-shadow: 0 0 0 0 !important;
}

.App {
  text-align: center;
}

* {
  font-family: 'Poppins';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-modal-wrap {
  z-index: 100000000000000 !important;
  overflow: scroll ;
}

.input-field {
  background: rgb(231, 238, 245) !important;
  border: 1px solid rgb(231, 239, 248) !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 100% !important;
  margin: 0px auto 14px !important;
  padding: 12px 14px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: 'Poppins' !important;
  line-height: 24px !important;
  letter-spacing: 1px !important;
}

.form-container {
  width: 66%;
}
.button-wrapper {
  background:  #FF7A00!important;
  border-radius: 100px !important;
  border: 1px solid #FF7A00 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 10px 15px !important;
  text-decoration: none !important;
  cursor: pointer !important;
  width: 260px !important;
  margin: 0px auto !important;
  height: 54px !important;
}


.ant-input-affix-wrapper > input.ant-input {
  background: transparent;
}

.ant-select-selector {
  background: transparent !important;
  border: none !important;
  text-align: left !important;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

/* .form-item-style > .ant-form-item-control {
  margin-bottom: 10px;
} */

.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
  box-shadow: none !important;
  font-family: 'Poppins' !important;
  font-size: 16px !important;
}
.react-stars-wrapper-002155496152759251:focus {
  border: none !important;
}
@media screen and (min-width: 600px) {
  .form-container {
    width: 66%;
  }
  .button-wrapper {
    width: 30%;
  }
}

@media screen and (max-width: 600px) {
  .form-container {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .form-container {
    width: 40%;
  }
}

@media screen and (min-width: 1024px) {
  .form-container {
    width: 30%;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.login-main-container {
  width: 100%;
  position: absolute;
  background: url(/static/media/bg_img.2e602dac.png);
  height: 100%;
  background-size: cover;
  background-repeat: repeat;
  background-position: center top;
}

.login-image-background-signin {
  position: relative;
  width: 100%;

  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center top; */
}

.login-main-bg-signin {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 46px 0;
}

.login-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2000;
}

a {
  color: #fff;
}
.login-heading-logo {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 30px 0 0 0;
}
.logo-span-color {
  color: #ff9900;
}

.login-desp-heading {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0px;
  margin-bottom: 10px !important;
}

.login-form-main-container {
  text-align: center;
  width: 100%;
  margin: 30px auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.forgot-password-link {
  color: white;
  font-size: 15px;
  margin-bottom: 18px;
;
}

.login-form-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
}

.login-form-container {
  width: 45%;
}

@media screen and (min-width: 768px) {
  .login-image-background-signin {
    background-size: cover;
    background-position: center center;
  }
}

@media only screen and (max-width: 600px) {
  .ant-form-item-control-input {
    margin: auto !important;
    width: 500px !important;
  }
}
@media only screen and (max-width: 550px) {
  .ant-form-item-control-input {
    margin: none !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 600px) {
  .login-form-container {
    width: 40%;
  }
}

@media screen and (max-width: 600px) {
  .login-form-container {
    width: 100%;
  }
}
@media screen and (min-width: 1289px) {
  .login-form-container {
    width: 30%;
  }
}

.signup-main-container{
    width: 100%;
    position: relative;
}
.signup-imagebackground-signin{
    position: relative;
  width: 100%;
  background: url(/static/media/bg_img.2e602dac.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}
@media screen and (min-width: 768px) {
    .signup-imagebackground-signin {
      background-size: cover;
      background-position: center center;
    }
  }
  .signup-mainbg-signin{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 0;
  }
  .signup-header-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
  .signuparrow-back{
    color: #fff;
  }
  .signuparrow-back a{
    color: #fff;
  }
  .signup-content-vehicle-inspection{
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    color: #ffffff;
    align-self: center;
    margin: 0 auto;
  }
  .signup-main-screen{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #4a93e9;
    text-align: center;
    margin: 40px auto;
  }
  .signup-content-footer-area-signin{
    text-align: center;
    width: 100%;
    display: flex;
    align-items:center;
    flex-direction: column;
    padding: 0 20px;
    height: 100vh;
  }
  
.reset-main-container{
    width: 100%;
    position: relative;
}
.reset-image-background-signin{
    position: relative;
    width: 100%;
    background: url(/static/media/bg_img.2e602dac.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}

.reset-arrow-back {
  color: #fff;
}
@media screen and (min-width: 768px) {
    .reset-image-background-signin {
      background-size: cover;
      background-position: center center;
    }
  }
  .reset-main-bg-signin{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 0;
  }
  .reset-header-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
  .reset-arrow-back{
    color: #fff;
  }
  .reset-arrow-back a{ 
    color: #fff;
  }

  .reset-content-vehicle-inspection{
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    color: #ffffff;
    align-self: center;
    margin: 0 auto;
  }
  .reset-main-screen{
    font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #4a93e9;
  text-align: center;
  margin: 40px auto;
  }
  .reset-content-footer-area-signin{
    text-align: center;
    /* align-items: center; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 100vh;
    display: flex;
    align-items: center;
  }
.verify-email-container {
  background: linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%), url(/static/media/bg_img.2e602dac.png);
  width: 100%;
  height: 100vh;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.verify-image-container {
  position: relative;
  height: 100vh;
}

.verify-email-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 10px;
}

.email-content-container {
  width: 100%;
  padding: 0 15px;
}

.email-content-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #74b3fb;
  text-align: center;
  padding-top: 21px;
  padding-bottom: 30px;
  margin: 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.email-content-icon-wrapper {
  color: white;
}

.email-icon {
  color: #2584e0;

  font-size: 14px;
}

.email-btn {
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: 0;
  color: #ffffff;
}

.email-btn:focus {
  outline: none;
}

.email-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 0px;
}

.app-logo {
  color: #ff9900;
}

.email-footer-content-container {
  text-align: center;
}

.login-main-container {
  width: 100%;
  position: absolute;
  height: 100%;
}

.login-image-background-signin {
  position: relative;
  width: 100%;
  background: url(/static/media/bg_img.2e602dac.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.confirm-email-main-bg-signin {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 20px 0;
}

.confirm-email-loader {
  margin-top: 20px;
}

.login-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2000;
}

.login-title-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #74b3fb;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 30px;
}

.login-back-arrow {
  color: #fff;
  position: absolute;
  left: 30px;
  top: 30px;
}
a {
  color: #fff;
}

.login-arrow-link {
  color: #fff;
}

.login-heading-logo {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 50px 0 0 0;
}
.logo-span-color {
  color: #ff9900;
}

.login-desp-heading {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0px;
}

.login-form-main-container {
  text-align: center;
  width: 100%;
  margin: 30px auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.forgot-password-link {
  color: white;
  font-size: 15px;
  margin-bottom: 18px;
}

.login-form-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
}

@media screen and (min-width: 768px) {
  .login-image-background-signin {
    background-size: cover;
    background-position: center center;
  }
}

.input-field {
  background: rgb(231, 238, 245) !important;
  border: 1px solid rgb(231, 239, 248) !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 100% !important;
  margin: 0px auto 15px !important;
  padding: 15px 8px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: 'Poppins' !important;
  letter-spacing: 1px !important;
}

.vec-inspection-inspection_card {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 2px dashed #c9d4e4;
  justify-content: center;
}

.vec-inspection-camera_icon {
  padding: 5px;
  display: none;
}

.veh-inspection-downloadicon_center {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 18px;
  margin-top: 18px;
}

.veh-inspection-download_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7eff8;
  width: 40px;
  flex-direction: column;
  height: 40px;
  border-radius: 60%;
  /* margin-bottom: 20px; */
}

.veh-inspection-registrationcard_text {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #2a2a2a;
  margin-bottom: 0px;
  margin-top: 8px;
}

.veh-inspection-photo_text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #1262b1;
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.inspection-image {
  width: 100%;
  height: 103px;
  border-radius: 10px;
  
  object-fit: initial;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 14px !important;
}

.inspection-card-close_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #FF7A00;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}
.vec-inspection-nextstep_button {
  background-color: #4a93e9;
  width: 100%;
  padding: 7px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 100px;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 15px;
}

.instruction-modal>.ant-modal-wrap {
  top: -100px !important;
}

.button-container {
  background: linear-gradient(90deg, rgb(255, 122, 0) 0%, rgb(255, 153, 0) 94.74%) !important;
  border-radius: 100px !important;
  border: 1px solid rgb(255, 116, 0) !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-align: center;
  color: rgb(255, 255, 255) !important;
  padding: 14px 60px 15px !important;
  text-decoration: none !important;
  cursor: pointer !important;
  width: 80% !important;
  margin: 0px auto !important;
}

.inspection-capture_now-button {
  background: #FF7A00 !important;
  border-radius: 100px !important;
  border: 1px solid #FF7A00 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  text-align: center !important;
  color: #ffffff !important;
  padding: 10px 15px !important;
  text-decoration: none !important;
  cursor: pointer !important;
  width: 260px !important;
  margin: 0px auto !important;
  height: 54px !important;
}

.input-img-container {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.input-img-containerr {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: 105px;
}
.image-uploads {
  display: flex;
  align-items: center;
  justify-content: center;
}



.image-upload>input {
  display: none;
}

.instruction-modal-step-container {
  color: white;
  margin: auto;
  width: 100%;

}

.instruction-modal-step {
  margin-top: 10px;
  text-align: left;
  margin-left: 5px;
  font-size: 13px;
  width: 100%;
  line-height: 15px;
  color: #b2c7f0;
}

.ant-modal-close-x {
  color: white !important;
  margin-top: 35px;
}
.blurr--background {
  position: absolute;
  top: 18%;
  left: -5%;
  width: 110%;
  height: 100vh;
  object-fit: cover;
  -webkit-backdrop-filter: blur(105px);
          backdrop-filter: blur(105px); 
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity as needed */
  
}
.webCam--video{
   position: absolute;
   top: 75%;
  left: -5%;
  width: 110%; 
  /* height: 100%;  */
   object-fit: cover;
  z-index: 99999999px; 
  /* height: auto; */
 

}
/* .webcam-container {
  
  width: 100%;
  height: 200px;
  overflow: hidden;
} */
.webCam--image img{
  position: absolute;
   top: 75%;
  left: -2%;
  /* width: 110%; */
  /* height: 100vh; */
  /* object-fit: cover; */
  z-index: 99999999px;
  image-rendering: crisp-edges;
  /* image-rendering: pixelated; */

}
.video-save-recording{
  position: absolute;
  z-index: 999999;
  bottom: 0;
  margin-bottom: -76%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-left: 12%;
  /* text-align: center; */

 
 }
.video-recording{
  position:absolute;bottom:0;
  margin-bottom:-80%;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-left:30%;
 
 }
 .outer-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid white;
}
.outer--circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid white;
  background-color: white;
 
}
.outer--circle:first-child {
  margin-right: 66px; /* Add right margin to separate the icons */
}
@media only screen and (max-width: 500px) {
  
  .video-recording{
    position:absolute;bottom:0;
    margin-bottom:-90%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-left:30%;
   
   }
   .video-save-recording{
    position: absolute;
  z-index: 999999;
  bottom: 0;
  margin-bottom: -80%;
  display: flex;
  justify-content: center;
  margin-left: 12%;

   
   }
}
@media only screen and (max-width: 700px) {
  .ant-modal-close-x {
    margin-top: 135px;
  }
}


.ant-modal-content {
  top: 35% !important;
  box-shadow: none !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  height: 100% !important;
  /* background-color: #12579b !important; */
  padding: 10px 20px !important;
  width: 100% !important;
  margin-top: 84%;
}

.ant-modal-body {
  border-radius: 30px;
  height: 100% !important;
  /* display: flex !important; */
  align-items: center !important;
  flex-direction: column !important;
}

.modal-inside-image {
  /* height: 100p% !important; */
  width: 100% !important;
  display: flex;
  flex-direction: column;
  height: 200px;
}
.modal-inside--image {
  /* height: 100p% !important; */
  width: 100% !important;
  display: flex;
  flex-direction: column;
  height: 200px;
}

@media only screen and (max-width: 600px) {
  .modal-inside-image {
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .modal-inside-image {
    width: 100% !important;
  }
}

/* .ant-modal-wrap>div { */
  /* width: 100% !important;
  height: 100% !important; */
/* } */

.ant-modal-wrap.survay-modallsss>div {
  width: 100% !important;
  height: 650px !important;
}

.survay-modallsss {
  color: black !important;
}

.modal-video {
  width: 70% !important;
  height: 200px;

}

.modal-image {
  height: 200px;
  width: 100%;
  border-radius: 10px;
  /* margin-bottom: 10px; */
  margin-top: 10px;
}

.modal-images {
  width: 100%;
  height: 88vh;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
}

.instruction-moadl-video {
  width: 100%;
  height: 50%;
}

.video-container {
  /* background-color: red !important; */
  height: 88vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.instruction-modalls .ant-modal-content {
  /* background: #266fb7; */
  margin-top: 10px;
  border-radius: 30px;
  background: transparent;
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #051532 115.19%), #191a1b; */
}

.ant-modal-wrap.instruction-modalls {
  background: linear-gradient(0deg, #0A305E, #0A305E), #191A1B;
}

.instruction-modalls .ant-modal-close {
  top: -90%;
  right: -12%;
}

@media screen and (max-width: 700px) {
  .instruction-modalls .ant-modal-close {
    top: -130%;
    right: -12%;
  }
}



@media screen and (min-width: 600px) {
  .button-container {
    width: 50% !important;
  }
}

@media screen and (max-width: 700px) {
  .ant-modal-content {
    width: 100%;
  }

  .modal-video {
    width: 80% !important;
  }
}

@media only screen and (max-width: 500px) {
  .modal-video {
    width: 100% !important;
  }

  .instruction-modalls .ant-modal-close {
    top: -125%;
    right: -12%;
  }
}

@media only screen and (max-width: 900px) {
  .modal-image {
    width: 100%;
  }

  .instruction-modal-step-container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .instruction-modal-step-container {
    width: 100%;
  }

  .instruction-modal-step {
    margin-left: 10px;
  }
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  position: none !important;
  border-bottom: none !important;
}

.instruction-tab-names {
  color: white !important;
  font-size: 11px;
  font-weight: 600;
  border: 1px solid #ffffff !important;
  border-radius: 50px;
  text-align: center;
  padding: 3px 18px !important;
  transition: none !important;
  margin-left: 10px;

}

.instruction-tab-names:focus {
  color: white !important;
  font-size: 11px;
  font-weight: 600;
  border: 1px solid #FF8F00 !important;
  background-color: #FF8F00;
  border-radius: 50px;
  text-align: center;
  padding: 3px 18px !important;
  /* transition: none !important; */
  margin-left: 10px;

}

.instruction-tab-names:hover {
  color: white !important;
  font-size: 11px;
  font-weight: 600;
  border: 1px solid #FF8F00 !important;
  background-color: #FF8F00;
  border-radius: 50px;
  text-align: center;
  padding: 3px 18px !important;
  /* transition: none !important; */
  margin-left: 10px;

}

.lyft-must-field{
  color: #FF7A00 !important;
  /* textcolor: #FF7A00 !important; */
  /* font-size: 11px; */
  /* font-weight: bolder; */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 900;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
}



.main-heading-text {
  font-size: 26px;
  color: white;
  /* margin-top: 220px; */
}

.exterior-rear-circle {
  border: 1px solid white;
  border-radius: 100%;
  padding: 8px;
  width: 0px;
  height: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: 12px;
  margin-top: -205px;
}

.instruction-modal-text {
  color: white;
  margin-top: 4px;
  text-align: justify;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em !important;
  margin-left: 5px;
}

.interior-circle {
  border: 1px solid white;
  border-radius: 100%;
  padding: 8px;
  width: 0px;
  height: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: 16px;
  margin-top: 4px;
}

.interior-header {
  color: white;
  margin-top: 15px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}

.pic-take {
  font-family: Poppins;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  /* margin-top: 230px; */
}
.menu-image {
  height: 130px;
  background-size: cover;
  width: 100%;
  opacity: 0.7;
  background-image: url(/static/media/testing.64662491.png);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.img-container {
  background-image: linear-gradient(to right, rgba(255, 154, 59, 0.8), rgba(255, 122, 0, 0.8));
}

.image-text {
  position: absolute;
  text-align: center;
  z-index: 1000 !important;
  font-size: 22px;
  font-weight: 900;
  color: white !important;
}

.icon-bar {
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  padding: 14px;
  color: black;
  align-items: center;
  cursor: pointer;
}

.icon-text {
  margin-left: 10px;
}

.ant-drawer-body {
  padding: 0 !important;
}

.side-bar-close_container {
  border: 3px solid white;
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.bottom_text{
  position: absolute;
  bottom: 10px;
  color: #1b68b3;
  font-size: 24px;
  margin-left: 40%;
  font-weight: bold;
}
.referral_text{
  position: absolute;
  bottom: 10px;
  color: #1b68b3;
  font-size: 15px;
  margin-left: 30%;
  font-weight: bold;
}
.icon-container {
  color: white;
}

.vec-inspection-top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  position: relative;
}

.veh-inspection-chexai_text {
  /* font-family: Poppins; */
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}

.nav-back-arrow {
  color: #ffffff;
  font-size: 14px;  
  
}
.menu-icon{
  position: absolute;
  left: 20px;
  top: 25px;
}



@media only screen and (min-width: 600px) {
  .header-width {
    width: 100%;
  }

} 

/* @media only screen and (max-width: 900px) {
  .header-width {
    width: 80%;
  }
}  */
/* 
 @media only screen and (max-width: 600px) {
  .header-width {
    width: 100%;
    background-color: red;
  }
 
} */


.logout-modal-text{
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
    z-index: 1000;
    color: black;
}
.logout-inspection-text{
        font-size: 13px;
        font-style: italic;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
        margin-top: 10px;
}
.logout-modal-btn{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}
.logout-modal-btn-no{
    width: 135px;
    border-radius: 100px;
    border: 1px solid #1468BA;
    text-align: center;
    padding: 9px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.logout-modal-btn-yes{
    color: white !important;
    width: 135px;
    border-radius: 100px;
    background: linear-gradient(90deg, #FF7A00 0%, #FF9900 94.74%);
    text-align: center;
    padding: 9px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.logout-modal .ant-modal-content{
    height: 225px !important;
    cursor: pointer;
    width: 340px !important;
} 

.server-form-container {
  width: 100%;
}
.server-form-heading {
  margin-top: 50px;
  color: #414b55;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}
.server-form-price {
  font-size: 26px;
  color: #ff7a00;
  font-weight: bold;
}
.server-form-paragraph {
  font-size: 12px;
  line-height: auto;
  font-weight: 700;
  text-align: center;
  align-items: center;
  padding-top: 10px;
}
.server-form-sub-heading {
  font-size: 15px;
  font-weight: 700;
  color: #000;
  padding-top: 14px;
  text-align: center;
}
.server-form-title {
  color: #ff7a00;
}
.server-form-rating-stars {
  text-align: center;
  padding-top: 2px;
  margin-left: 20px;
}
.star-container svg {
  height: 26px !important;
  width: 22px !important;
}
.server-form-question {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  padding-top: 16px;
}
.server-form-checkboxes {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.server-form-checkbox-text {
  font-size: 12px;
  font-weight: 700;
}
.newcontainer {
  text-align: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.newcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.newcheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}
.newcontainer:hover input ~ .newcheckmark {
  background-color: #ccc;
}
.newcontainer input:checked ~ .newcheckmark {
  background-color: #fff;
}
.newcheckmark:after {
  content: '';
  position: absolute;
  display: none;
  background-color: #ccc;
}
.newcontainer input:checked ~ .newcheckmark:after {
  content: '\2713';
  display: block;
  height: 20px;
  width: 20px;
  text-align: center;
  color: #ff7a00;
}
.server-form-textarea {
  background-color: #dae6f1;
  border-radius: 3px;
  border: none;
  width: 100% !important;
}
.server-form-textarea::-webkit-input-placeholder {
  color: #8c9dae;
  font-size: 18px;
  padding: 10px;
}
.server-form-textarea:-ms-input-placeholder {
  color: #8c9dae;
  font-size: 18px;
  padding: 10px;
}
.server-form-textarea::placeholder {
  color: #8c9dae;
  font-size: 18px;
  padding: 10px;
}
.ant-modal-body {
  padding: 0px !important;
}

.survey-modal .ant-modal-content {
  border-radius: 0px !important;
  width: 30% !important;
}
.modal-styling .ant-modal-content {
  background-color: inherit !important;
}

.survay-modallsss .ant-modal-content {
  border-radius: 0px !important;
  width: 30% !important;
  border-radius: 30px !important;
  height: 110% !important;
}

@media only screen and (max-width: 450px) {
  .server-form-paragraph {
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
  }
  .server-form-sub-heading {
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
  }
  .server-form-rating-stars {
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
  }
  .server-form-question {
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
  }
  .server-form-checkboxes {
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.survey-button {
  background: #4a93e9 !important;
  border: none !important;
}
.survey-modal-link {
  color: #0057ac;
  font-size: 10px;
  text-align: center;
  margin-top: 8px;
}

@media only screen and (min-width: 425px) {
  .survey-button {
    width: 175px !important;
  }
}

@media only screen and (max-width: 900px) {
  .survay-modallsss .ant-modal-content {
    border-radius: 0px !important;
    width: 100% !important;
    border-radius: 30px !important;
  }
}

@media only screen and (min-width: 700px) {
  .server-form-textarea {
    background-color: #dae6f1;
    border-radius: 3px;
    border: none;
    width: 80% !important;
  }
}

.imageModal .ant-modal-header {
  display: none;
}

.imageModal .ant-modal-footer {
  display: none;
}

.imageModal .ant-modal-body {
  padding: 5px;
}

.imageModal .ant-modal-close-icon {
  color: #d07575;
  font-weight: bold;
  font-size: 20px;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.1);
}

.sure-modal .ant-modal-header {
  display: none;
}

.sure-modal .ant-modal-footer {
  display: none;
}

.sure-modal .ant-modal-content {
  border-radius: 15px;
  height: 60% !important;
  width: 60% !important;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.d-flex-1 {
  display: flex;
}

.progressNumber {
  color: blue;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  width: 100% !important;
  text-align: center !important;
}

.progressNumber .progress-bar {
  text-align: center !important;
  width: 100% !important;
}

.sure-modal .ant-modal-content .ant-modal-close {
  display: none;
}

.signature-data {
  width: 40%;
}

@media only screen and (max-width: 600px) {
  .signature-data {
    width: 100% !important;
  }

}



@media only screen and (max-width:1000px) {

  .sure-modal .ant-modal-content {
    border-radius: 15px;
    height: 60% !important;
    width: 50% !important;
  }
}

@media only screen and (max-width:768px) {

  .sure-modal .ant-modal-content {
    border-radius: 15px;
    height: 80% !important;
    width: 70% !important;
  }
}

@media only screen and (max-width:450px) {

  .sure-modal .ant-modal-content {
    border-radius: 15px;
    height: 55% !important;
    width: 85% !important;
  }

  .yes-btn-container {
    padding: 10px !important;
    font-size: 14px !important;
  }

  .no-btn-container {
    padding: 10px !important;
    font-size: 14px !important;
  }
}

.yes-btn-container {
  background-color: orange;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 100px;
  color: white;
  margin-top: 20px;
  font-size: 16px;
}

.no-btn-container {
  border: 2px solid orange;
  justify-content: center;
  align-items: center;
  padding: 13px;
  border-radius: 100px;
  margin-top: -20px;
  color: black;
  font-size: 16px;
}
.modal-styling {
  left: 0 !important;
  padding: 0 !important;
  right: 0 !important;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.checkmark::before {
  display: none !important;
}
.checkmark::after {
  left: 4px !important;
  top: -7px !important;
  width: 11px !important;
  height: 25px !important;
  border: 1px solid #fc6522 !important;
  border-width: 0 3px 3px 0 !important;
}

.btnComponentContainerModal {
  width: 70%;
  /* background-color: red; */
  margin-top: 20px;
}

.ant-modal-wrap.modalSize > div {
  height: 100% !important;
  width: 50% !important;
  background: white;
}

.textSize {
  font-size: 25px;
  font-weight: 500;
  color: black;
  text-align: center;
}

.lyft-user-heading {
  font-size: 27px;
  font-weight: bold;
}

.register-modal-btn {
}

.ant-modal-wrap{
  overflow-x: hidden !important;
}
@media screen and (max-width: 998px) {
  .ant-modal-wrap.modalSize > div {
    height: 50% !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  .register-modal-btn {
    font-size: 14px !important;
  }
}

.open-tab-bg_color {
  background: #ffffff;
  border-radius: 3px;
  padding: 10px;
  /* margin-top: 10px; */
}
.open-tab-container {
  cursor: pointer;
  padding: 3px 0px 5px 5px ;
  margin-top: 8px;
  margin-right:7px;
  box-shadow:0 0 4px silver;
  border:1px solid white;
  border-radius: 4px;

}
@media only screen and (max-width: 550px) {
  .open-tab-container {
  margin-right: 5px;
  }
}
@media only screen and (min-width: 1024px) {
  .open-tab-container {
  margin-right: 25px;
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 3px !important;
}
.arrow-icon-container {
  border: 2px solid #FF0000;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.arrow-icon-container-inreview{
  border: 2px solid #FF7A00;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.tab-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-text {
  color: #1468ba;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.tabs-content-bg-color {
  background-color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px;
  height: 650px;
  /* overflow-y: scroll; */
}

/* @media only screen and (max-width: 550px) {
  .tabs-content-bg-color {
    height: 560px;
  }
} */
@media only screen and (min-width: 550px) {
  .tabs-content-bg-color {
   border-radius: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .tabs-content-bg-color {
    width: 335px;

  }
}


.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: unset !important;
}



/* width */
::-webkit-scrollbar {
  width: 3px;
  margin-top: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgb(231, 231, 231); */
  border-radius: 10px;
  margin-top: 14px;
  margin-bottom: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: orange;
  border-radius: 10px;
}

.draft-message-show {
  font-weight: 900;
  color: #1467B8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
}

.draft-image-show {
  margin-top: 35px;
  width: 231.8px;
  height: 278px;
}
.content-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
 .tab-content{
  color: #1260AF !important; 
  font-size: 11px;
  font-weight: 600;
  border:1px solid #1261B0 !important;
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;

}
.tab-content:focus{
  color: #ffffff !important; 
  font-size: 11px !important;
  font-weight: 500 !important;
  background-color: #FF8F00 !important;
  border:1px solid #FF8F00 !important;
  border-radius: 50px !important;
  text-align: center !important;
  padding: 3px 15px !important;
}
/* .ant-tabs-tab-btn:focus{
  color: #ffffff !important; 
  font-size: 11px !important;
  font-weight: 500 !important;
  background-color: #FF8F00 !important;
  border:1px solid #FF8F00 !important;
  border-radius: 50px !important;
  text-align: center !important;
  padding: 3px 18px !important;
} */
.tab-content:hover{
  color: #ffffff !important; 
  font-size: 11px;
  font-weight: 500;
  background-color: #FF8F00 !important;
  border:1px solid #FF8F00 !important;
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;
}



/* .tab-content:hover{
  color: #ffffff; 
  font-size: 11px;
  font-weight: 500;
  background-color: #FF8F00;
  border:1px solid #FF8F00;
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;
} */

/* .tab-content:focus{
  color: #ffffff; 
  font-size: 11px;
  font-weight: 500;
  background-color: #FF8F00;
  border:1px solid #FF8F00;
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;
} */
.ant-tabs-ink-bar {
  display: none;
}

.text-reviewed{
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468ba;
}
.reviewed-content-container{
  background-color: white !important;
  margin: 15px !important;
  margin-left: -10px !important;
  border:1px solid rgb(245, 245, 245) !important;
  margin-top: 12px !important;

mix-blend-mode: normal !important;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
border-radius: 3px !important;
}

@media only screen and (max-width:1020px){
  .reviewed-content-container{
    margin-left: 0px !important;
    margin: 8px !important;
    margin-top: 12px !important;
  }
}

.document-container{
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-top: 10px;
  cursor: pointer;
}
.document-text{
font-size: 13px;
color:#1468ba;
margin-left: 10px;
font-weight: 600;

}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  text-shadow: none !important;
} 
.ant-tabs-content-holder{
  height: 500px !important;
}

.modal-content-container {
  display: flex;
  justify-content: space-between;
  width: 300px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.ant-modal-content {
  width: 360px !important;
  height: 380px !important;
  border-radius: 10px !important;
}

@media only screen and (max-width:350px) {
  .ant-modal-content {
    width: 340px !important;
  }
}


.ant-modal-content .companies-modal {
  width: 300px !important;
  height: 350px !important;
  border-radius: 10px !important;
  margin-left: 8px;
}

.ant-modal-footer {
  display: none !important;
}

.ant-modal-header {
  display: none !important;
}

.option-text {
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-left: 10px;
}

.modal-links-container {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 10px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.modal-links-container_noneborder {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 10px;
  margin-top: 4px;
}

.modal-company-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 10px; */
  width: 100%;
  padding: 0px;
}

.modal-links-text {
  color: #1468ba;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.modal-links-text:hover {
  color: #1468ba;
}

.checkbox-container {
  width: 16px;
  height: 16px;
  box-shadow: none;
}

.tab-all-tag {
  position: relative;
}

.tab-all-tag-content {
  /* border: 1px solid #20C18D; */
  /* background-color: #20C18D; */
  padding: 0px;
  margin-top: -2px;
  position: absolute;
  right: -11px;
  width: 69px;
  top: -12px;
  height: 15px;
  text-align: center;
  color: white;
  font-size: 8px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.reviewed-all-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #1468ba;
  padding: 0px !important;
  margin: -1px !important;

}

.reviewed-all-content {

  width: 92% !important;
  /* border: 1px solid white !important; */
  box-shadow: 0 0 4px silver !important;
  padding: 0px !important;
  margin-top: 10px !important;
  background: white !important;
  border-radius: 4px !important;
  position: relative !important;
  border: none !important;
}

@media only screen and (max-width: 1023px) {
  .reviewed-all-content {
    width: 97% !important;

  }
}

@media only screen and (max-width: 550px) {
  .reviewed-all-content {
    width: 98.6% !important;

  }
}


.reviewed-all-tag {
  border: 1px solid #20C18D;
  position: absolute !important;
  right: 0px !important;
  background-color: #20C18D !important;
  padding: 0px;
  width: 69px;
  text-align: center;
  color: white;

  margin-top: -14px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  text-align: center;
  color: white;
  font-size: 10px !important;
  height: 15px;
}

.reviewed-all-tag-text {
  font-size: 9px !important;
  margin-left: -3px;
  margin-top: 0px;
}
.certificate-input-conatainer {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.certificate-input-conatainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  border: 1px solid black;
  width: 18px;
  background-color: #fff;
}
/* On mouse-over, add a grey background color */
.certificate-input-conatainer:hover input ~ .checkmark {
  background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.certificate-input-conatainer input:checked ~ .checkmark {
  background-color: #fff;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.certificate-input-conatainer input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.certificate-input-conatainer .checkmark:after {
  left: 3px;
  top: -7px;
  width: 10px;
  height: 22px;
  border: solid #fc6522;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.vechcleText {
  font-family: Roboto Slab;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: left;
}
.tobecompletedTextBgColor {
  background: #e6e6e6;
  display: flex;
  align-items: center;
}
.tobecompletedText {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  margin-top: 12px;
  margin-left: 10px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff4a86;
}
.report-user-data {
  background-color: #f7f7f7;
  margin-top: -6px;
}
.inspectorText {
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.fallText {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #767170;
}
.dateColor {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #767170;
}
.inspectionText {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #767170;
}
.failText {
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 75px;
  letter-spacing: 0em;
  color: 'black';
}
.passText {
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 58px;
  letter-spacing: 0em;
  color: 'black';
}
.radiusContainer {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datebgcolorContaner {
  background-color: #f7f7f7;
  margin-top: -12px;
  padding: 15px;
}
.vechilebgColor {
  background-color: #767170;
  padding: 10px;
}
.tobecompletedbgColor {
  background-color: #f7f7f7;
  padding: 20px;
}
.tobecompletedText {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ff4a86;
}

.lyft-top-header_container {
  background: #47484f;
  margin-bottom: 10px;
}
.lift-image {
  width: 50px;
  height: 40px;
  background-repeat: no-repeat;
  margin-top: 25px;
  margin-left: 20px;
}
.caloiforniya-text {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  color: white;
  margin-top: 15px;
}
.lyft-driver-info_form_container {
  background: #e4e5e7;
  border: 1px solid #141414;
  box-sizing: border-box;
  padding: 15px;
}
.lyft-driver-tetxs_container {
  display: flex;
}
.lyft-driver-info_text {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin-right: 13px;
  margin-bottom: 10px;
}
.mandatory-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: 11px;
  color: #716e6e;
  margin-bottom: 0;
}
.lyft-driver-name_input {
  border: none;
  border: 1px solid #47484f;
  background-color: #e4e5e7;
  width: 100%;
  height: 60px;
  padding-bottom: 30px;
  padding-left: 5px;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
}
::-webkit-input-placeholder {
  color: #47484f !important;
}
:-ms-input-placeholder {
  color: #47484f !important;
}
::placeholder {
  color: #47484f !important;
}
.phone-num_input {
  border: none;
  border: 1px solid #47484f;
  background-color: #e4e5e7;
  width: 100%;
  height: 60px;
  padding-bottom: 30px;
  padding-left: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
}
.email_input {
  border: none;
  border: 1px solid #47484f;
  background-color: #e4e5e7;
  width: 100%;
  height: 60px;
  padding-bottom: 30px;
  padding-left: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
}
.vehicle-year-input {
  border: none;
  border: 1px solid #47484f;
  background-color: #e4e5e7;
  width: 100%;
  height: 60px;
  padding-bottom: 30px;
  padding-left: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
}
.vehicle-make-input {
  border: none;
  border: 1px solid #47484f;
  background-color: #e4e5e7;
  width: 100%;
  height: 60px;
  padding-bottom: 30px;
  padding-left: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
}
.vehicle-model-input {
  border: none;
  border: 1px solid #47484f;
  background-color: #e4e5e7;
  width: 100%;
  height: 60px;
  padding-bottom: 30px;
  padding-left: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
}
.license-plate-input {
  border: none;
  border: 1px solid #47484f;
  background-color: #e4e5e7;
  width: 99%;
  height: 60px;
  padding-bottom: 30px;
  padding-left: 5px;
  margin-top: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
}
.vin-input {
  border: none;
  background-color: #e4e5e7;
  border-bottom: 1px solid #47484f;
  margin-top: 30px;
  width: 100% !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
  margin-left: 5px;
}
.vin-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin-left: 5px;
}
.form-checkbox-fixone {
  width: 15px;
  margin-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: black;
  margin-right: 5px;
  margin-left: -70px;
}
.form-checkbox-fix {
  width: 15px;
  margin-top: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: black;
  margin-right: 5px;
}

.form-chexbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vehicle-inspection-heading_container {
  display: flex;
  margin-top: 10px;
}
.vehicle-inspection-heading {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin-right: 5px;
}
.inspector-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #716e6e;
  margin-top: 11px;
}
.inspector-point-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  /* margin-left: 10px; */
  color: #716e6e;
}
.pass-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #716e6e;
}
.fail-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #716e6e;
}
.foot-brakes-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}
.numeric-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #716e6e;
}
.vehicle-inspection_chexbox {
  margin-top: 24px;
  margin-left: 40px;
  width: 14px;
}
.vehicle-inspection_chexboxtwo {
  margin-top: 24px;
  width: 14px;
  margin-left: 10px;
}
.doted-border-box {
  border-style: dashed;
  border-color: black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-left: 20px;
  width: 100%;
}
.vehicle-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
  margin-top: 5px;
  color: black;
}
.box-pass-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin-left: -12px;
}
.box-fail-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin-top: 15px;
}
.circle-one-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0em;
  text-align: left;
  color: #716e6e;
}
.completed-inspector-main_container {
  background-color: #e4e5e7;
  padding: 10px;
}
.completed-by-inspector_text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  /* border-bottom: 1px solid black; */
  text-transform: uppercase;
}
.id-verified {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
  margin-top: 20px;
}
.yes-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  color: black;
  margin-top: 20px;
}

.no-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin-top: 20px;
}
.applicant-input-field {
  border-bottom: 1px solid black !important;
  background-color: #e4e5e7;
  border: none;
  width: 100%;
}
.applicant-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
  text-transform: uppercase;
}
.inspector-only-form {
  background-color: #e4e5e7;
  padding: 10px;
  margin-top: 15px;
}
.inspector-only-container {
  display: flex;
  align-items: center;
}
.inspector-only-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  text-transform: uppercase;
  margin-right: 10px;
}
.fields-mandatory-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}
.inspection-date-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
  margin-bottom: 0;
  /* margin-left: 20px; */
  margin-top: 35px;
  text-transform: uppercase;
}
.document-expires-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #47484f;
  /* margin-left: 20px; */
}
.inspector-only-input {
  border-bottom: 1px solid black !important;
  background-color: #e4e5e7;
  border: none;
  width: 95%;
}
.bottom-paragraph {
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #716e6e;
  margin-top: 10px;
}
.yes-circle {
  border: 8px solid green;
  width: 70px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pass-circle {
  border: 6px solid #fc6522;
  width: 62px;
  height: 62px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lyft-container {
  display: flex;
  flex-direction: column;
}
.label-text {
  margin-bottom: 12px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #191a1b;
  text-transform: uppercase;
}
.demi-text {
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  line-height: 36px;
  color: #191a1b;
  margin-bottom: 0;
  margin-left: 20px;
}
.demi-text-address {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  color: #191a1b;
  margin-bottom: 0;
  margin-left: 20px;
}
.demi-text-one {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #191a1b;
  margin-top: -7px;
  margin-left: 25%;
}
.demi-text-two {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #191a1b;
  margin-top: -5px;
  margin-left: 50%;
}
.demi-text-three {
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #191a1b;
  margin-top: -5px;
  margin-left: 43%;
}
.demi-text-four {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #191a1b;
  margin-bottom: 0;
}

.margin-top-container {
  margin-top: 100px;
}

.border-right-Container {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.custom-chexbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Create a custom checkbox */
.checked-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  border: 1px solid black !important;
  width: 18px;
  background-color: #fff;
}
/* On mouse-over, add a grey background color */
.custom-chexbox-container:hover input ~ .checked-checkbox {
  background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.custom-chexbox-container input:checked ~ .checked-checkbox {
  background-color: #fff;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checked-checkbox:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.custom-chexbox-container input:checked ~ .checked-checkbox:after {
  display: block;
}
/* Style the checkmark/indicator */
.custom-chexbox-container .checked-checkbox:after {
  left: 3px;
  top: -7px;
  width: 10px;
  height: 22px;
  border: solid #fc6522;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.progress-modal-text{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
    z-index: 1000;
}
.progress-inspection-text{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

}
.progress-modal-btn{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}
.progress-modal-btn-no{
    width: 120px;
    border-radius: 100px;
    border: 1px solid #1468BA;
    text-align: center;
    padding: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.progress-modal-btn-yes{
    color: white !important;
    width: 120px;
    border-radius: 100px;
    background: linear-gradient(90deg, #FF7A00 0%, #FF9900 94.74%);
    text-align: center;
    padding: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.progress-modal .ant-modal-content{
    height: 160px !important;
    cursor: pointer;
}
.inspection-modal-container .ant-modal-content {
  width: 100% !important;
  border-radius: 10px !important;
  /* overflow-y: scroll; */
  height: 100% !important;
  padding: 10px !important;
}

.modal-content-scroll {
  height: 400px;
  overflow-y: scroll;
  margin-top: 20px;
}

.inspection-modal-container .ant-modal-footer {
  display: none !important;
}
.inspection-modal-container .ant-modal-header {
  display: none !important;
}
.inspection-btn-align {
  display: flex;
  align-items: center;
  justify-content: center; 
}

/* @media only screen and (max-width:550px){
  .inspection-btn-align {
    position: absolute;
    width: 100%;
    bottom: 0px;
    background-color: #e5e5e5;
    height: 280px;
  }
} */

.inspection-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-top: 40px;
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  width: 200px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
}
.inspections-btn-text {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 3px;
}
.inspection-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  padding: 10px;
}
.select-company-text {
  color: black;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-left: 10px;
}
.additional-selection-container {
  background-color: #ffc700;
  width: 300px;
  padding: 1px;
}
.additional-selection-text {
  font-size: 9px;
  /* font-style: italic; */
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color: black;
}
.inspection-modal-content_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  padding: 10px;
  border-bottom: 0.5px solid rgba(85, 83, 83, 0.1);
}

.select-btn-container {
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  padding: 7px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}

.select-text {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.select-text:hover {
  color: white;
}
.inspection-modal-content_text {
  color: #1468ba;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 14px; */
  cursor: pointer;
}

.modal-input-field {
  font-family: 'poppins' !important;
  padding: 7px;
  width:  309px;
  color: black;
  font-size: 13px;
  font-weight: 400;
  background-color: rgb(233, 233, 233);
}

@media only screen and (max-width: 399px) {
  .inspection-modal-container .ant-modal-content {
    width: 100% !important;
  }
  .modal-input-field {
    width:  280px;
  }
}

.tick-background-color {
  background-color: rgb(233, 233, 233);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  border-radius: 3px;
  margin-right: 5px;
}
.select-companies-text {
  font-size: medium;
  text-align: left;
  margin-left: -1%;
  margin-top: 12px;
  color: black;
  font-size: 15px;
  margin-bottom: -20px;
}

.please-enter-text {
  text-align: start !important;
  color: black;
  font-size: 13px;
  margin-left: 5px;
  margin-top: 8px;
  margin-bottom: 2px;
}
.License-plate-text {
  text-align: start;
  color: black;
  font-size: 13px;
  margin-left: -131px;
  margin-top: 8px;
  margin-bottom: 2px;
}

@media only screen and (min-width: 400px) {
  .please-enter-text {
    margin-left: 5px;
  }
  .select-companies-text {
    margin-left: -1%;
  }
}

@media only screen and (max-width: 550px) {
  .inspection-btn-container {
    bottom: 28px;
  }
}


.after-reviewing-header_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .chexs-text{
   color: white;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 39px;
    letter-spacing: 0em;  
  }
.produc-card-bg_img{
    /* background: url(../../Assets/carimg.png); */
    background-repeat: no-repeat;
    background-size: contain;
    width: 147px;
    height: 100px;
    margin-top: 20px;
    position: relative;
   
  }
  .camera-icon_container{
    margin-left: 4px;
    margin-top: 3px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .product-name{
  color: black;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }
  .product-type{
    color: #1262B1;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }
.inspection-tile{
    cursor: pointer;
    width: 90%;
    /* height: 127px; */
    padding: 10px ;
    margin-top: 27px;
    /* margin-right:7px; */
    box-shadow:0 0 8px silver;
    border-radius: 4px;
    /* margin-left: 20px; */
    position: relative;
  
  }
  .inspection-tracking-id-text{
      margin-left: 15px;
      margin-top: 10px;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #1468BA;
  }
  .inspection-tracking-id{
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      margin-top: 15px;
      margin-left: 25px;
      color: #000000;
  }
  .inspection-tile-content{
      display: flex;
     
  }
  .inspection-date-created{
      margin-left: 50px;
      margin-top: 10px;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #1468BA;
  }
  .inspection-date{
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      margin-top: 15px;
      margin-left: 60px;
      color: #000000;
  
  }
  .progress-close-icon{
    position: absolute;
    right: 5px;
    top: 5px; 
}
.in-review-tile{
    background: #fff !important; ;
    cursor: pointer;
    width: 90%;
    border-bottom: none !important;
    /* height: 127px; */
    
    margin-top: 27px;
    /* margin-right:7px; */
    box-shadow:0 0 8px silver;
    border-radius: 4px !important;
    padding-bottom: 16px;
    margin-top: 30px !important;
    position: relative;
  }
  .in-review-tracking-id-text{
    margin-left: 15px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
  }
  .in-review-tracking-id{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 25px;
    color: #000000;
    margin-bottom: 2px;
  }
  .in-review-tile-content{
      display: flex;
     
  }
  .in-review-date-created{
    margin-left: 35px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
  }
  .in-review-date{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 48px;
    color: #000000;
  
  }
.submitted-tile{
    background: #fff !important;
    cursor: pointer;
    width: 90%;
    border-bottom: none !important;
    /* height: 127px; */

    margin-top: 27px;
    /* margin-right:7px; */
    box-shadow:0 0 8px silver;
    border-radius: 4px !important;
    margin-top: 30px !important;
    position: relative;

}
.submitted-tracking-id-text{
    margin-left: 15px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
}
.submitted-tracking-id{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 25px;
    color: #000000;
    margin-bottom: 2px;
}
.submitted-tile-content{
    display: flex;
    position: absolute;
}
.submitted-date-created{
    margin-left: 35px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
}
.submitted-date{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 48px;
    color: #000000;
}

.car-icon-style {
    width: 20px;
}
.certificates-tile {
  cursor: pointer;
  width: 90%;
  /* height: 127px; */
  padding: 10px;
  margin-top: 27px;
  /* margin-right:7px; */
  box-shadow: 0 0 8px silver;
  border-radius: 4px;
  /* margin-left: 20px; */
  position: relative;
  overflow: hidden !important;
}

@media only screen and (max-width: 352px) {
  .certificates-tile {
    padding: 5px;

  }
}

.certificates-tracking-id-text {
  margin-left: 10px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468BA;
}

@media only screen and (max-width: 352px) {
  .certificates-tracking-id-text {
    font-size: 12px;

  }
}


.certificates-tracking-id {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: 8px;
  color: #000000;
  text-align: center;
}

@media only screen and (max-width: 352px) {
  .certificates-tracking-id {
    font-size: 12px;

  }
}

.certificates-tile-content {
  display: flex;
  justify-content: space-around;
}

.certificates-date-created {
  margin-left: 17px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468BA;
}

@media only screen and (max-width: 352px) {
  .certificates-date-created {
    font-size: 12px;

  }
}

.certificates-date {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: 8px;
  margin-left: 20px;
  color: #000000;
  text-align: center;

}

@media only screen and (max-width: 352px) {
  .certificates-date {
    font-size: 12px;

  }
}

.certificates-button {

  margin-top: 10px;
  /* padding: 0px 30px; */
  /* width: 193px; */
  height: 39px;
  background: #1468BA;
  border-radius: 5px;
  border: 1px solid #1468BA;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
}

@media only screen and (max-width: 352px) {
  .certificates-button {
    font-size: 10px;
    /* width:150px; */
    height: 25px;

  }
}

.certificates-company {
  margin-left: 17px;
  margin-top: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1468BA;
}

@media only screen and (max-width: 352px) {
  .certificates-company {
    font-size: 12px;

  }
}

.certificates-company-name {
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  margin-top: 15px;
  margin-left: 10px;
  color: #000000;
  text-align: center;

}

@media only screen and (max-width: 352px) {
  .certificates-company-name {
    font-size: 12px;

  }
}

.ant-modal-close {
  display: none;
}

.footerContainer{
    background: #1B1C1F;
    margin-top: 60px;
    padding-top: 50px;
    /*padding-left: 200px;*/
    padding-bottom: 50px;
    color:White;
}
.aboutSection_custom{
    margin-right: 20px;
}
.footerLogoContainer{
    margin-bottom: 30px;
}
.footerAboutDescription{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #FFFFFF;
}

.headingFooter{
    font-family: Poppins;
    font-style: normal;
    width: 100%;
    font-weight: 600;
    /*background-color: red;*/
    font-size: 16px;
    margin-bottom:20px;
    line-height: 20px;
    color: #FFFFFF;

}
.footerMenuList{
    list-style: none;
    float: left;
    margin: 0;
    padding: 0;


}
.footerMenuList li{
    margin-bottom: 10px;
}
.footerMenuList li a{
    color: #808389!important;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}
.footerMenuList li a:hover{
    color: #FF7A00!important;
}
.textFooter{
    font-size: 14px;

    color: #FFFFFF;
    margin-bottom: 30px;

}
.iconsContainer{
    margin-bottom: 20px;
}
.iconsContainer a{
    /*background-color: red;*/
    /* padding:20px;*/
    margin-right: 15px;
}
.footerLinks_custom{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left!important;
    /*background-color: red;*/
}
.footerLinks_custom span{
    margin-bottom: 10px;


}
.footerLinks_custom span Link{
    color:#FFFFFF!important;
}

.footerLinks_custom span:hover{
    color: #FF7A00!important;
}
.menuContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom:20px;
    padding-left: 50px;

}

.copyrightText{
    background-color: #000000;
    padding: 10px;
    padding-top: 20px;
    text-align: center;
}
@media screen and (max-width: 900px){
    .textFooter{
        font-size:11px
    }


}

@media screen and (max-width: 768px){
    .menuContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;
        padding-left: 0px;

    }
    .headingFooter{
        text-align: center;
    }
    .aboutSection_custom{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left:30px;
        padding-right: 30px;
        text-align: center;
        margin-bottom:20px;
        margin-right: 0px;
        /*background-color: red;*/
    }
    .footerMenuList{
        text-align: center;

    }
    .headingFooter{

        font-size: 18px;

    }
    .ContactContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;
    }
    .textFooter{
        font-size: 14px;
    }
    .iconsContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .copyrightText{
        text-align: center;
    }
    .footerLinks_custom{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left!important;
        /*background-color: red;*/
    }
}

.ankr_phone_footer{
    text-decoration: none;

}
.ankr_phone_footer:hover{
    transition:0.8s;
    color:#FF7A00;
}




.menuBar {
  padding: 0 20px;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  z-index: 5;
}
.ScrollBackColor{
  width: 100%;
  background-color: #fff;
  transition:0.4s;
}
.logo_home img{
  width: 80%!important;
  padding-bottom: 8px;
  padding-left: 60px;

}
.login_text_ankr{
  background-color: #fff;
  border: 2px solid #FF7A00;
  color: #FF7A00;
  font-family: Poppins;
  font-weight: bold;
  border-radius: 5px;

  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;


}

@media screen and (max-width: 780px) {
  .menuBar {
    padding: 0 0px;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 5;
  }
  .ScrollBackColor{
    padding:0px;
  }
  .logo_home img{
    width: 80% !important;

  }
}


@media screen and (max-width: 767px) {
  .menuBar {
    padding: 0 20px;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 5;
  }
  .ScrollBackColor{
    padding-right:30px;
  }
  .logo_home img{
    width: 70% !important;
    padding-bottom: 8px;
    padding-left: 30px;

  }

}


@media screen and (max-width: 450px) {

  .logo_home img{
    width: 80% !important;
    padding-bottom: 8px;
    padding-left: 20px;

  }
}

@media screen and (max-width: 406px) {
 .logo_home{
   width: 75%;
 }
  .logo_home img{
    width: 100% !important;

  }
  .menubar{
    padding:10px!important;
  }
}
@media screen and (max-width: 370px) {
  .logo_home{
    width: 80%;
  }
   .logo_home img{
     width: 100% !important;
     padding-bottom: 8px;
     padding-left: 16px;
 
   }
   .menubar{
     padding:10px!important;
   }
 }
 @media screen and (max-width: 340px) {
  .logo_home{
    width: 83%;
  }
   .logo_home img{
     width: 100% !important;
     padding-bottom: 8px;
     padding-left: 10px;
 
   }
   .menubar{
     padding:10px!important;
   }
 }

.ant-menu-item-selected{
  color:#1468BA!important;
  border:none!important;
  /*border-bottom: 4px solid red!important;*/
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
  border-bottom: 0px solid rgba(49, 44, 44, 0.133);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color:#1468BA!important;
}
.menuCon .leftMenu a:hover{
  color:#1468BA!important;
  text-decoration: none!important;
  border-bottom:0px solid #1468BA!important;
}
.ankrPricing{
  color:#1468BA!important;
  text-decoration: none!important;
  border-bottom:0px solid #1468BA!important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after{
  border:none!important;
}
.ant-menu-horizontal:hover  > .ant-menu-item::after{
  border-bottom:none!important;
}
.ant-menu-submenu::after{
  border-bottom:none!important;
}
@media  screen and (max-width: 500px) {
  .menuBar{
    width: 100%;
  }

}
.logo_home {
  /*width: 150px;*/
  float: left;
  margin-top: 5px;
}

.header_logo_text{
  color:white;
}

.logo_home img {
  margin-top: 5px;
  width: 100%;
}
.menuNavbarMain{
  background-color: transparent;
  color:white!important;

}
.ant-menu-submenu-title:hover{
  color:#FF7A00!important;
}
.ant-menu-submenu:hover{
  color:#FF7A00!important;
  border-bottom:none!important;
}
.ant-menu-submenu-popup:hover > .ant-menu-submenu-title{
  color:#FF7A00!important;
}
.ant-menu-sub{

  background-color:black!important;
}
/*.menuNavbarMain:hover{*/
/*  color:#FF7A00!important;*/

/*}*/
@media screen and (max-width: 768px){
  .ant-menu-sub{

    background-color:#1B223B!important;
  }
  .ant-menu-submenu-arrow{
    color:white!important;
  }


}
.menuItemClr{
  color:white!important;
  text-decoration: none;

}
.dropDownMenuWrap{
  background-color: black;
  color: white;
}
.dropDownMenuWrap:hover{
  color:#FF7A00!important;
  /* border-bottom: 1px solid #FF7A00; */
  /*border-bottom:3px solid #FF7A00!important;*/
}
.linkNavbar_wrap_menu{

  color: white!important;
}
.linkNavbar_wrap{
  /* color: white!important; */
}


/* .linkNavbar_wrap:hover{
 color: red!important;
} */
/*.logo a {*/
/*  display: inline-block;*/
/*  font-size: 20px;*/
/*  color:red;*/
/*  padding: 19px 20px;*/
/*  text-transform: capitalize;*/
/*}*/

.menuCon {
  /*width: calc(100% - 0px);*/
  float: right;
  padding-top:10px;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: right;
}

.menuCon .leftMenu a{
  color: #000;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: black;
  border: none;
  outline: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: white;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: white;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo_home a {
    padding: 10px 20px;
  }
}

/* @media  screen and (max-width: 1124px) {
  .menuBar{
    width: 98%;
  }

} */

@media  screen and (max-width: 1180px) {
  .menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a {
    padding: 10px 5px;
  }
  .menuBar{
    width: 100%;
  }

}

@media  screen and (max-width: 1175px) {
  .menuBar{
    width: 100%;
  }
  .menuCon .my-menu-item{
     font-size: 12px;
    padding:0px 16px;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }
}
@media  screen and (max-width:975px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 12px;
    padding:0px 10px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:878px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 9px;
    padding:0px 5px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:875px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 10px;
    padding:0px 9px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:822px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 8px;
    padding:0px 6px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width: 960px) {
  .menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a {
    padding: 10px 1px;
  }
  .menuBar{
    width: 100%;
  }
}

@media  screen and (max-width:916px) {
  /* .menuBar{
    width: 100%;

  } */
  .logo_home img{
    width: 80%;

  }
  /* .menuCon .my-menu-item{
    font-size: 12px;
    padding:0px 9px;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  } */

}

/* @media  screen and (max-width:874px) {
  .menuBar{
    width: 100%;


  }
  .logo_home img{
    width: 50%;

  }
  .menuCon .my-menu-item{
    font-size: 8px;
    padding:0px 5px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

  .menuCon .ant-menu-item {
    padding: 0px 1px;
  }

  .menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
  }

  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
  }

} */


@media  screen and (max-width:833px) {
  /* .menuBar{
    width: 100%;

  } */
  .logo_home img{
    width: 70%;

  }
  /* .menuCon .my-menu-item{
    font-size: 10px;
    padding:0px 6px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  } */

  .menuCon .ant-menu-item {
    padding: 0px 1px;
  }

  .menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
  }

  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
  }

}

@media  screen and (max-width: 575px) {
  .logo_home img{
    width: 60% !important;

  }
  .barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    /*margin-top: 8px;*/
    margin-bottom: 12px;
    /*display: none;*/
    /*background: none;*/
  }
  .menuBar{
    /* width: 100%; */
    margin-left: 0px;
  }
}


/*.ant-menu-horizontal:hover {*/
/*  line-height: 46px;*/
/*  border: 0;*/
/*  border-bottom: 1px solid #57c717!important;*/
/*  box-shadow: none;*/
/*}*/

/*.ant-menu-item{*/
/*  border-bottom:3px solid green;*/

/*}*/

.styling_ankr_pricing{
  /*color: #ffffff!important;*/
}
.linkNavbar_wrap_pricing{
  color: #000000!important;
}
.linkNavbar_wrap_pricing:hover{
  color: #1468BA!important;
}
.my-menu-item{
  padding: 0 25px;

}
@media screen and (max-width: 768px) {
  .linkNavbar_wrap_pricing{
    color: #ffffff!important;
  }
}

.my-menu-item:hover{
  color:#FF7A00!important;
}

.dropDown_newnav{
  background-color: black;
  padding-bottom: 10px;
  padding-top: 10px;
}
.menu-items_newnav{
   margin-top: 10px;
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: #FF7A00!important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent!important;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  color:white!important;
  background: #FF7A00!important;

}

.ant-menu-submenu-title:hover {
  color: white!important;
}

@media screen and (max-width: 840px) {

  .my-menu-item {
    padding: 0 20px!important;
  }

}







.menuBar {
  padding: 0 20px;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  z-index: 5;
}
.ScrollBackColor{
  width: 100%;
  background-color: #fff;
  transition:0.4s;
}
.logo_home img{
  width: 80%!important;
  padding-bottom: 8px;
  padding-left: 60px;

}
.login_text_ankr{
  background-color: #fff;
  border: 2px solid #FF7A00;
  color: #FF7A00;
  font-family: Poppins;
  font-weight: bold;
  border-radius: 5px;

  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;


}

@media screen and (max-width: 780px) {
  .menuBar {
    padding: 0 0px;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 5;
  }
  .ScrollBackColor{
    padding:0px;
  }
  .logo_home img{
    width: 80% !important;

  }
}


@media screen and (max-width: 767px) {
  .menuBar {
    padding: 0 20px;
    overflow: auto;
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 5;
  }
  .ScrollBackColor{
    padding-right:30px;
  }
  .logo_home img{
    width: 70% !important;
    padding-bottom: 8px;
    padding-left: 30px;

  }

}


@media screen and (max-width: 450px) {

  .logo_home img{
    width: 80% !important;
    padding-bottom: 8px;
    padding-left: 20px;

  }
}

@media screen and (max-width: 406px) {
 .logo_home{
   width: 75%;
 }
  .logo_home img{
    width: 100% !important;

  }
  .menubar{
    padding:10px!important;
  }
}
@media screen and (max-width: 370px) {
  .logo_home{
    width: 80%;
  }
   .logo_home img{
     width: 100% !important;
     padding-bottom: 8px;
     padding-left: 16px;
 
   }
   .menubar{
     padding:10px!important;
   }
 }
 @media screen and (max-width: 340px) {
  .logo_home{
    width: 83%;
  }
   .logo_home img{
     width: 100% !important;
     padding-bottom: 8px;
     padding-left: 10px;
 
   }
   .menubar{
     padding:10px!important;
   }
 }

.ant-menu-item-selected{
  color:#1468BA!important;
  border:none!important;
  /*border-bottom: 4px solid red!important;*/
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
  border-bottom: 0px solid rgba(49, 44, 44, 0.133);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color:#1468BA!important;
}
.menuCon .leftMenu a:hover{
  color:#1468BA!important;
  text-decoration: none!important;
  border-bottom:0px solid #1468BA!important;
}
.ankrPricing{
  color:#1468BA!important;
  text-decoration: none!important;
  border-bottom:0px solid #1468BA!important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after{
  border:none!important;
}
.ant-menu-horizontal:hover  > .ant-menu-item::after{
  border-bottom:none!important;
}
.ant-menu-submenu::after{
  border-bottom:none!important;
}
@media  screen and (max-width: 500px) {
  .menuBar{
    width: 100%;
  }

}
.logo_home {
  /*width: 150px;*/
  float: left;
  margin-top: 5px;
}

.header_logo_text{
  color:white;
}

.logo_home img {
  margin-top: 5px;
  width: 100%;
}
.menuNavbarMain{
  background-color: transparent;
  color:white!important;

}
.ant-menu-submenu-title:hover{
  color:#FF7A00!important;
}
.ant-menu-submenu:hover{
  color:#FF7A00!important;
  border-bottom:none!important;
}
.ant-menu-submenu-popup:hover > .ant-menu-submenu-title{
  color:#FF7A00!important;
}
.ant-menu-sub{

  background-color:black!important;
}
/*.menuNavbarMain:hover{*/
/*  color:#FF7A00!important;*/

/*}*/
@media screen and (max-width: 768px){
  .ant-menu-sub{

    background-color:#1B223B!important;
  }
  .ant-menu-submenu-arrow{
    color:white!important;
  }


}
.menuItemClr{
  color:white!important;
  text-decoration: none;

}
.dropDownMenuWrap{
  background-color: black;
  color: white;
}
.dropDownMenuWrap:hover{
  color:#FF7A00!important;
  /* border-bottom: 1px solid #FF7A00; */
  /*border-bottom:3px solid #FF7A00!important;*/
}
.linkNavbar_wrap_menu{

  color: white!important;
}
.linkNavbar_wrap{
  /* color: white!important; */
}


/* .linkNavbar_wrap:hover{
 color: red!important;
} */
/*.logo a {*/
/*  display: inline-block;*/
/*  font-size: 20px;*/
/*  color:red;*/
/*  padding: 19px 20px;*/
/*  text-transform: capitalize;*/
/*}*/

.menuCon {
  /*width: calc(100% - 0px);*/
  float: right;
  padding-top:10px;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: right;
}

.menuCon .leftMenu a{
  color: #000;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: black;
  border: none;
  outline: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: white;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: white;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo_home a {
    padding: 10px 20px;
  }
}

/* @media  screen and (max-width: 1124px) {
  .menuBar{
    width: 98%;
  }

} */

@media  screen and (max-width: 1180px) {
  .menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a {
    padding: 10px 5px;
  }
  .menuBar{
    width: 100%;
  }

}

@media  screen and (max-width: 1175px) {
  .menuBar{
    width: 100%;
  }
  .menuCon .my-menu-item{
     font-size: 12px;
    padding:0px 16px;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }
}
@media  screen and (max-width:975px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 12px;
    padding:0px 10px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:878px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 9px;
    padding:0px 5px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:875px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 10px;
    padding:0px 9px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width:822px) {
  .menuBar{
    width: 100%;

  }
  .menuCon .my-menu-item{
    font-size: 8px;
    padding:0px 6px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

}
@media  screen and (max-width: 960px) {
  .menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a {
    padding: 10px 1px;
  }
  .menuBar{
    width: 100%;
  }
}

@media  screen and (max-width:916px) {
  /* .menuBar{
    width: 100%;

  } */
  .logo_home img{
    width: 80%;

  }
  /* .menuCon .my-menu-item{
    font-size: 12px;
    padding:0px 9px;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  } */

}

/* @media  screen and (max-width:874px) {
  .menuBar{
    width: 100%;


  }
  .logo_home img{
    width: 50%;

  }
  .menuCon .my-menu-item{
    font-size: 8px;
    padding:0px 5px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  }

  .menuCon .ant-menu-item {
    padding: 0px 1px;
  }

  .menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
  }

  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
  }

} */


@media  screen and (max-width:833px) {
  /* .menuBar{
    width: 100%;

  } */
  .logo_home img{
    width: 70%;

  }
  /* .menuCon .my-menu-item{
    font-size: 10px;
    padding:0px 6px !important;
  }
  .menuCon .my-menu-item .linkNavbar_wrap{
    padding:0px;
  } */

  .menuCon .ant-menu-item {
    padding: 0px 1px;
  }

  .menuCon .ant-menu-submenu-title {
    padding: 10px 20px;
  }

  .menuCon .ant-menu-item a,
  .menuCon .ant-menu-submenu-title a {
    padding: 10px 15px;
  }

}

@media  screen and (max-width: 575px) {
  .logo_home img{
    width: 60% !important;

  }
  .barsMenu {
    float: right;
    height: 32px;
    padding: 6px;
    /*margin-top: 8px;*/
    margin-bottom: 12px;
    /*display: none;*/
    /*background: none;*/
  }
  .menuBar{
    /* width: 100%; */
    margin-left: 0px;
  }
}


/*.ant-menu-horizontal:hover {*/
/*  line-height: 46px;*/
/*  border: 0;*/
/*  border-bottom: 1px solid #57c717!important;*/
/*  box-shadow: none;*/
/*}*/

/*.ant-menu-item{*/
/*  border-bottom:3px solid green;*/

/*}*/

.styling_ankr_pricing{
  /*color: #ffffff!important;*/
}
.linkNavbar_wrap_pricing{
  color: #000000!important;
}
.linkNavbar_wrap_pricing:hover{
  color: #1468BA!important;
}
.my-menu-item{
  padding: 0 25px;

}
@media screen and (max-width: 768px) {
  .linkNavbar_wrap_pricing{
    color: #ffffff!important;
  }
}

.my-menu-item:hover{
  color:#FF7A00!important;
}

.dropDown_newnav{
  background-color: black;
  padding-bottom: 10px;
  padding-top: 10px;
}
.menu-items_newnav{
   margin-top: 10px;
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: #FF7A00!important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent!important;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  color:white!important;
  background: #FF7A00!important;

}

.ant-menu-submenu-title:hover {
  color: white!important;
}

@media screen and (max-width: 840px) {

  .my-menu-item {
    padding: 0 20px!important;
  }

}




.menuBar-contact-us {
  padding: 0 20px;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  z-index: 5;
  /* background-color: black; */
}

.ScrollBackColor{

  transition:0.4s;
  border-bottom: 1px solid black;
}
.ScrollBlackColor{
  background-color: #000000;
  padding-top: 8px;
  padding-bottom: 8px;
}

.menuContactWhite .leftMenu a{
    color: white;
}

.menuContactWhite {
  /*width: calc(100% - 150px);*/
  float: right;
}



.menuContactWhite .ant-menu-item {
  padding: 0px 5px;
}

.menuContactWhite .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuContactWhite .ant-menu-item a,
.menuContactWhite .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuContactWhite .ant-menu-horizontal {
  border-bottom: none;
}

.menuContactWhite .leftMenu {
  float: right;
}

.menuContactWhite .leftMenu a{
  color: #fff;
}

.menuContactWhite .leftMenu a:hover{
  color:#fff!important;
  text-decoration: none!important;
  border-bottom:0px solid #FF7A00!important;
}

.ant-menu-item-selected{
  color:#FF7A00!important;
  border:none!important;
  /*border-bottom: 4px solid red!important;*/
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
  border-bottom: 0px solid rgba(49, 44, 44, 0.133);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
  color:#FF7A00!important;
}
.menuContact .leftMenu a:hover{
  color:#000!important;
  text-decoration: none!important;
  border-bottom:0px solid #FF7A00!important;
}
.ankrPricing{
  color:#FF7A00!important;
  text-decoration: none!important;
  border-bottom:0px solid #FF7A00!important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after{
  border:none!important;
}
.ant-menu-horizontal:hover  > .ant-menu-item::after{
  border-bottom:none!important;
}
.ant-menu-submenu::after{
  border-bottom:none!important;
}

@media  screen and (max-width: 500px) {
  .menuBar-contact-us{
    width: 100%;
  }

}
.logo {
  /*width: 150px;*/
  float: left;
  margin-top: 5px;
}

.logo img {
  /*height: 50px;*/
  /*width: 150px;*/
  width:60%;
}

.logoWhite {
  /*width: 150px;*/
  float: left;

  margin-top: 7px;
}

.logoWhite img {
  padding-left: 20px;
  width:70%;
  /*height: 45px;*/
  /*width: 150px;*/
}

@media screen and (max-width: 970px){
  .logoWhite img {
    width:70%;

  }
  .logo img {

    width:70%;
  }

}
@media screen and (max-width: 939px){
  .logoWhite img {
    width:70%;
    padding-left:10px;

  }
  .logo img {

    width:70%;
    padding-left:10px;
  }


}

@media screen and (max-width: 860px){
  .logoWhite img {
    width:60%;
    padding-left:10px;

  }
  .logo img {

    width:60%;
    padding-left:10px;
  }


}
@media screen and (max-width: 840px){
  .logoWhite img {
    width:150px;
    padding-left:10px;

  }
  .logo img {

    width:150px;
    padding-left:10px;
  }


}

@media screen and (max-width: 814px){
  .logoWhite img {
    width:150px;
    padding-left:10px;

  }
  .logo img {

    width:150px;
    padding-left:10px;
  }
  .my-menu-item{
    font-size: 12px;
    padding:0px 13px;
  }


}

@media only screen and (max-width:767px) {
  .logo img {
    /*height: 33px;*/
    /*width: 100px;*/
    width: 70%;
    padding-left: 7px;
  }
  .logoWhite img{
    width: 70%;
    padding-left: 7px;
    /*height: 33px;*/
    /*width: 100px;*/
  }
}

@media only screen and (max-width:567px) {
  .logo img {
    /*height: 33px;*/
    width: 60%;
    /*width: 100px;*/
  }

  .logoWhite img {
    width: 60%;
    padding-left: 0px;
    margin-top:5px;
    /*height: 33px;*/
    /*width: 100px;*/
  }
  .menuBar-contact-us{
    padding: 10px 10px;
  }

}


@media only screen and (max-width:423px) {
  .logo img {
    /*height: 33px;*/
    /*width: 100px;*/
    width:60%;
    padding-left: 10px;
  }

  .logoWhite img {
    width: 60%;
    padding-left: 10px;
    margin-top:5px;
    /*height: 33px;*/
    /*width: 100px;*/
  }
  .menuBar-contact-us{
    padding: 10px 10px;
  }

}

@media only screen and (max-width:380px) {



}

.menuNavbarMain{
  background-color: transparent;
  color:white!important;

}
.ant-menu-submenu-title:hover{
  color:#FF7A00!important;
}
.ant-menu-submenu:hover{
  color:#FF7A00!important;
  border-bottom:none!important;
}
.ant-menu-submenu-popup:hover > .ant-menu-submenu-title{
  color:#FF7A00!important;
}
.ant-menu-sub{

  background-color:black!important;
}
@media screen and (max-width: 768px){
  .ant-menu-sub{

    background-color:#1B223B!important;
  }
  .ant-menu-submenu-arrow{
    color:white!important;
  }


}
.menuItemClr{
  color:white!important;
  text-decoration: none;

}
.dropDownMenuWrap{
  background-color: black;
  color: white;
}
.dropDownMenuWrap:hover{
  color:#FF7A00!important;
  /* border-bottom: 1px solid #FF7A00; */
  /*border-bottom:3px solid #FF7A00!important;*/
}
.linkNavbar_wrap_menu{

  color: white!important;
}
.linkNavbar_wrap{
  /* color: white!important; */
}
.ant-dropdown-link-contact-us {
  padding: 0px 25px;
}


/* .linkNavbar_wrap:hover{
 color: red!important;
} */
/*.logo a {*/
/*  display: inline-block;*/
/*  font-size: 20px;*/
/*  color:red;*/
/*  padding: 19px 20px;*/
/*  text-transform: capitalize;*/
/*}*/

.menuContact {
  /*width: calc(100% - 150px);*/
  /* padding-right: 120px; */
  float: right;
}
/* @media screen and (max-width: 1100px){
  .menuContact{
    padding-right: 12px!important;
  }

} */

.menuContact .ant-menu-item {
  padding: 0px 5px;
}

.menuContact .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuContact .ant-menu-item a,
.menuContact .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuContact .ant-menu-horizontal {
  border-bottom: none;
}

.menuContact .leftMenu {
  float: right;
}

.menuContact .leftMenu a{
  color: black;
}

.barsMenu-black {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: #000;
}
.barsMenu-black:hover {
  background-color: #000;
  border: none !important;
}
.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: white;
  position: relative;
}
.barsBtn:hover {
  border: none !important;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: white;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu-black>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu-black {
    display: block;
    border: none !important;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .logoWhite a {
    margin-left: -20px;
  }

  .menuContact .ant-menu-item,
  .menuContact .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .menuContactWhite .ant-menu-item,
  .menuContactWhite .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }

  .logoWhite a {
    padding: 10px 20px;
  }
}

/* @media  screen and (max-width: 1124px) {
  .menuBar-contact-us{
    width: 98%;
  }

} */

@media  screen and (max-width: 1180px) {
  .menuContact .ant-menu-item a, .menuContact .ant-menu-submenu-title a {
    padding: 10px 5px;
  }
  .menuContactWhite .ant-menu-item a, .menuContactWhite .ant-menu-submenu-title a {
    padding: 10px 5px;
  }

}

@media  screen and (max-width: 1380px) {
  .menuBar-contact-us{
    width: 100%;
  }

}
.my-menu-item{
  padding: 0 25px;

}

@media  screen and (max-width: 1006px) {
  .menuContact .ant-menu-item a, .menuContact .ant-menu-submenu-title a {
    padding: 10px 1px;
  }
  .menuContactWhite .ant-menu-item a, .menuContactWhite .ant-menu-submenu-title a {
    padding: 10px 1px;
  }

  .ant-dropdown-link-contact-us {
    padding: 0px 10px;
  }
  .my-menu-item{
    padding: 0 15px;

  }

}

/*.ant-menu-horizontal:hover {*/
/*  line-height: 46px;*/
/*  border: 0;*/
/*  border-bottom: 1px solid #57c717!important;*/
/*  box-shadow: none;*/
/*}*/

/*.ant-menu-item{*/
/*  border-bottom:3px solid green;*/

/*}*/



.linkNavbar_wrap_pricing{
  color: #000000!important;
}
.linkNavbar_wrap_pricing:hover{
  color: #1468BA!important;
}

@media screen and (max-width: 768px) {
  .linkNavbar_wrap_pricing{
    color: #ffffff!important;
  }
}
.linkNavbar_wrap_black {
    color: black!important;
}
.linkNavbar_wrap_black:hover {
  color: black !important;
  border-bottom: 2px solid black;
}

.linkNavbar_wrap_white {
  color: white!important;
}
.linkNavbar_wrap_white:hover {
color: white !important;
border-bottom: 2px solid white;
}



.my-menu-item:hover{
  color:#FF7A00!important;
}

.dropDown_newnav{
  background-color: black;
  padding-bottom: 10px;
  padding-top: 10px;
}
.menu-items_newnav{
   margin-top: 10px;
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: #FF7A00!important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent!important;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
  color:white!important;
  background: #FF7A00!important;

}

.ant-menu-submenu-title:hover {
  color: white!important;
}



.priceCardContainer{
    background-color:white;
    border: 2px solid #C4C4C4;
    box-sizing: border-box;
    box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
    border-radius: 10px;
    /*width: 300px;*/
    position: relative;
    /*height: 200px;*/
    width:100%;
    height:180px;
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: center;
padding-top: 30px;
    padding-bottom: 10px;

    /*text-align: center;*/


    /*margin:100px;*/
}
.pricetagBg_new{
    position:absolute;
    top:-8%;
    left: 5%;
    background-color: #FF7A00;
    border-radius: 5px;

    text-align: left;
    padding-left:30px;
    padding-right: 30px;
    height: 30px;
}



.pricecardHeading{
    color: #FF7A00;
    font-family:Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 28px;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.pricecardParagraph{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    padding-right: 10px;
    padding-left:10px;

    color: #5D6F84;
}


.pricetagText_new{
    padding-top: 3px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding-top: 3px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;

    color: #FFFFFF;
}




@media screen and (max-width: 1100px){

    .pricecardHeading{

        font-size: 55px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph{

        font-size: 16px;


        color: #5D6F84;
    }

}

@media screen and (max-width:935px){
    .pricetagBg_new{
        position:absolute;
        top:-8%;
        left: 8%;
        background-color: #FF7A00;
        border-radius: 5px;

        text-align: left;
        padding-left:20px;
        padding-right: 20px;
        height: 30px;
    }
    .pricetagText_new{
        padding-top: 3px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        padding-top: 3px;
        width: 100%;
        height: 100%;
        text-transform: uppercase;

        color: #FFFFFF;
    }





    .pricecardHeading{

        font-size: 45px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph{

        font-size: 16px;
        padding-right: 3px;
        padding-left:3px;

        color: #5D6F84;
    }

}


@media screen and (max-width:893px){
    .priceCardContainer{
        background-color:white;
        border: 2px solid #C4C4C4;
        box-sizing: border-box;
        box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
        border-radius: 10px;
        /*width: 300px;*/
        /*height: 200px;*/
        width:100%;
        height:150px;
        display: flex;
        flex-direction: column;

        justify-content: center;

        align-items: center;
        padding-top: 30px;
        padding-bottom: 10px;

        /*text-align: center;*/


        /*margin:100px;*/
    }
.pricetagBg_new{
    top: -10%;
}

    .pricetagText_new{
        padding-top: 3px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        padding-top: 5px;
        width: 100%;
        height: 100%;
        text-transform: uppercase;

        color: #FFFFFF;
    }

    .pricecardHeading{

        font-size: 35px;
        line-height: 25px;
        margin-top: 10px;
    }
    .pricecardParagraph{

        font-size: 16px;
        padding-right: 3px;
        padding-left:3px;

        color: #5D6F84;
    }


}


@media screen and (max-width:830px){
.pricetagBg_new{
    padding-left:10px;
    padding-right: 10px;
}


    .pricecardHeading{

        font-size: 35px;
        line-height: 25px;
        margin-top: 10px;
    }
    .pricecardParagraph{

        font-size: 14px;
        padding-right: 3px;
        padding-left:3px;

        color: #5D6F84;
    }


}

@media screen and (max-width: 767px) {
    .pricetagBg_new{
        padding-left:30px;
        padding-right: 30px;
        top:-9%;
    }
    .pricetagText_new{
        padding-top: 3%;
        font-size: 14px;
    }

    .priceCardContainer{
        background-color:white;
        border: 2px solid #C4C4C4;
        box-sizing: border-box;
        box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
        border-radius: 10px;
        /*width: 300px;*/
        /*height: 200px;*/
        width:300px;
        height:150px;
        display: flex;
        flex-direction: column;

        justify-content: center;

        align-items: center;
        padding-top: 30px;
        padding-bottom: 10px;

        /*text-align: center;*/


        /*margin:100px;*/
    }


    .pricecardHeading{

        font-size: 45px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph{

        font-size: 16px;

    }

}



@media screen and (max-width: 550px) {

    .pricecardHeading{

        font-size: 50px;
        line-height: 25px;
        margin-top: 10px;
    }
    .pricecardParagraph{

        font-size: 18px;

    }


}






@media screen and (max-width: 450px) {
    .priceCardContainer{
        background-color:white;
        border: 2px solid #C4C4C4;
        box-sizing: border-box;
        box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
        border-radius: 10px;
        margin-left: 10px;
        /*width: 300px;*/
        /*height: 200px;*/
        width:100%;
        height:150px;

    }




}

@media screen and (max-width:376px) {
    .pricetagBg_new{
        padding-left:20px;
        padding-right: 20px;
        top:-10%;
    }
    .pricetagText_new{
        padding-top: 5%;
        font-size: 12px;
    }
    .pricecardHeading{

        font-size: 40px;
        line-height: 25px;
        margin-top: 10px;
    }
    .pricecardParagraph{

        font-size: 18px;

    }



}





.review-card-container{
    max-width: 660px;
    width:400px;
    display: flex;
    flex-direction: column;

    /*height: 250px;*/
    background-color: #F9F9F9;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
}
.review-text{
    font-family: Poppins,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}
.name-rate-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /*float: right;*/
}
.reviewer-name{
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: -5px;
}

@media screen and (max-width: 517px){
    .review-card-container{
        padding:20px;
        margin-top: 30px;
        width:370px;
        /*height:270px;*/
    }
}
@media screen and (max-width: 340px){
    .review-card-container{
        padding:20px;
        margin-top: 30px;
        width:330px;
        /*height:300px;*/
    }
    .name-rate-container{
        margin-right: 20px;
    }
}

.overlyCardWrapper{
    padding:10px
}
.cardContainer{

    /*max-width: 350px;*/
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    padding: 20px;

    height: 200px;
}
.cardIconImgs{
    width: 13%;
    padding-bottom: 10px;
}

.cardHeading{
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    margin: 0;
    color: #FFFFFF;
}
.description{
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}
@media only screen and (max-width: 1024px) {
    .cardContainer{
        /*background-color: red;*/
        max-width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin-right: 10px;
    }

}

@media only screen and (max-width:348px) {
    .cardHeading{
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        text-align: left;
        margin: 0;
        color: #FFFFFF;
    }
    .description{
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
    }

}

.image-confirmation-modal-text{
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
    z-index: 1000;
    color: black;
}
.confirmation-inspection-text{
        font-size: 13px;
        font-style: italic;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
        margin-top: 10px;
}
.image-confirmation-modal-btn{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}
.image-confirmation-modal-btn-no{
    width: 135px;
    border-radius: 100px;
    border: 1px solid #1468BA;
    text-align: center;
    padding: 9px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.image-confirmation-modal-btn-yes{
    color: white !important;
    width: 135px;
    border-radius: 100px;
    background: linear-gradient(90deg, #FF7A00 0%, #FF9900 94.74%);
    text-align: center;
    padding: 9px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.image-confirmation-modal .ant-modal-content{
    height: 235px !important;
    cursor: pointer;
    width: 340px !important;
} 

.video-confirmation-modal-text{
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
    z-index: 1000;
    color: black;
}
.video-confirmation-inspection-text{
        font-size: 13px;
        font-style: italic;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: black;
        margin-top: 10px;
}
.video-confirmation-modal-btn{
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-top: 20px;
}
.video-confirmation-modal-btn-no{
    width: 135px;
    border-radius: 100px;
    border: 1px solid #1468BA;
    text-align: center;
    padding: 9px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-top: 30px;
}
.video-confirmation-modal-btn-yes{
    color: white !important;
    width: 135px;
    border-radius: 100px;
    background: linear-gradient(90deg, #FF7A00 0%, #FF9900 94.74%);
    text-align: center;
    padding: 9px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.video-confirmation-modal .ant-modal-content{
    height: 175px !important;
    cursor: pointer;
    width: 340px !important;
} 

.in-review-tile{
    background: #fff !important; ;
    cursor: pointer;
    width: 90%;
    border-bottom: none !important;
    /* height: 127px; */
    
    margin-top: 27px;
    /* margin-right:7px; */
    box-shadow:0 0 8px silver;
    border-radius: 4px !important;
    padding-bottom: 16px;
    margin-top: 30px !important;
    position: relative;
  }
  .in-review-tracking-id-text{
    margin-left: 15px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
  }
  .in-review-tracking-id{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 25px;
    color: #000000;
    margin-bottom: 2px;
  }
  .in-review-tile-content{
      display: flex;
     
  }
  .in-review-date-created{
    margin-left: 35px;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #1468BA;
  }
  .in-review-date{
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    margin-top: 5px;
    margin-left: 48px;
    color: #000000;
  
  }

  .submitted-tile-tag-ready-review{
    border: 1px solid #ff8d0d;
    background-color: #ff8d0d;
    background: cover;
    /* padding: 0px 20px; */
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #fff;
    width: 74px;
    height: 15px;
    text-align: center;
    font-size: 10px;
    margin-right: -1px;
  }
.faqsWrapper{
    /*background-color: red;*/
    padding:15px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.questionStyleWrapper{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.faqsQuestionStyle{
    font-size: 16px;
    font-weight: bold;
    color: black;
    cursor: pointer;
    /*font-family: Roboto;*/
}
.answer{

    font-size: 14px;
    padding-top: 15px;
}


@media screen and (max-width: 460px){
    .faqsQuestionStyle{
        font-size: 14px;

    }
    .answer{

        font-size: 12px;

    }


}
.landing_banner_main_wrapper{
    width: 100%;
  padding-top: 0px;
    background-color: #FAFAFA;
    /*background-color: red;*/
    /*background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662102678/Rectangle_251_rthgt5.png");*/
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    /*box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.48);*/
    background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662109838/Vector_1_lstndg.png");
}


.landing_banner_inner_wrapper{
    width: 100%;
    height: 650px;
    margin-bottom:0px;
    margin-top: 0px;

}

.content_landing_wrapper_main{
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 600px;

}
.content_landing_wrapper{
    padding: 20px;
    padding-left: 60px;
    /*background-color: #d9d9d9;*/
    width: 100%;


}
.content_landing_wrapper div h3{
    font-size: 40px;
    font-family: Poppins;
    font-weight: bold;
    text-transform: uppercase;


}
.darkGray{
    color: #404A50;
}
.darkBlue {
    color: #1468BA;
}


.content_image_wrapper{
    padding: 10%;
    width: 100%;
    /*background-color: red;*/

}
.content_image_wrapper img{
    width: 100%;
}
.image_banner_class img{
    width: 80% !important;
}

.btn_landing_wrapper{
    margin-top: 50px;
}
.landing_banner_button{
    background-color: #FF9900;
    color: #ffffff;

    border-radius: 5px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;

}
.landing_banner_button:hover{
    background-color: orange;
    color: white;

}



@media screen and (max-width: 1150px) {
    .image_banner_class img{
        width: 100% !important;
    }
}



@media screen and (max-width: 950px) {
    .content_landing_wrapper{
        padding: 20px;
        padding-left: 60px;


    }
    .content_landing_wrapper div h3{
        font-size: 30px;
        font-family: Poppins;


    }
    .content_image_wrapper{
        padding: 30px;
        width: 100%;
        /*background-color: red;*/

    }
    .content_image_wrapper img{
        width: 100%;
    }

}


@media screen and (max-width: 800px) {
    .content_landing_wrapper div h3{
        font-size: 24px;
        font-family: Poppins;


    }
    .content_landing_wrapper{
        padding: 20px;
        padding-left: 60px;
        width: 100%;
        /*background-color: #FC6522;*/


    }
    .content_image_wrapper{
        padding: 3%;
        width: 100%;
        /*background-color: red;*/

    }
    .content_image_wrapper img{
        width: 100%;
    }
}


@media screen and (max-width: 680px) {
    .content_landing_wrapper{
        padding: 20px;
        padding-left: 40px;
        width: 100%;


    }
    .content_landing_wrapper div h3{
        font-size: 20px;
        font-family: Poppins;


    }
    .content_image_wrapper{
        padding-right: 30px;
        width: 100%;
        /*background-color: red;*/

    }
    .content_image_wrapper img{
        margin-left: 0px;
        width: 100%;
    }
}


@media screen and (max-width: 560px) {
    .content_landing_wrapper{
        padding: 30px;

    }
    .content_landing_wrapper div h3{
        font-size: 24px;
        text-align: center;
        font-family: Poppins;


    }
    .content_landing_wrapper_main{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /*background-color: #d9d9d9;*/
        height: 450px;
    }
    .landing_banner_main_wrapper{

        background-position: right;
        background-repeat: no-repeat;
        background-image:none;
         }
    .landing_banner_inner_wrapper{
        width: 100%;
        height: 400px;
    }
    .content_image_wrapper{
        padding-right: 0px;
        display: none;
        width: 200%;
        /*background-color: red;*/

    }
    .btn_landing_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

}


.badge_box_compo_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 20px;
    box-shadow: 5px 5px 10px #A6B4C8;
    background-color: #fff;
    width: 278px;

    height: 350px;
    text-align: center;
    border-radius: 30px;
    /*border: 0.5px solid #A6B4C8;*/

}
.icon_badge_card_box{
    box-shadow: inset 5px 5px 10px  #CACACA;
    border-radius:150%;
    width: 110px;
    height: 110px;
    border: 1px solid #CACACA;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon_badge_card_box img{
    width: 70%;
    padding: 10px;
}


.badge_box_compo_wrapper h3{
    font-size: 20px;
    margin-top: 20px;
    font-family: Poppins;
    color: #000;
    font-weight: bold;

}

.badge_box_compo_wrapper p{
    font-size: 14px;
    font-family: Poppins;

}


@media screen and (max-width: 859px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 200px;

        height: 360px;
        text-align: center;
        border-radius: 30px;


    }

    .icon_badge_card_box{
        box-shadow: inset 5px 5px 10px  #CACACA;
        border-radius: 100%;
        width: 90px;
        height: 90px;
        border: 1px solid #CACACA;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon_badge_card_box img{
        width: 90%;
        padding: 10px;
    }




}


@media screen and (max-width: 643px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 270px;

        height: 300px;
        text-align: center;
        margin: 10px;
        border-radius: 30px;


    }



}


@media screen and (max-width: 439px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 250px;

        height: 350px;
        text-align: center;
        margin: 20px;
        border-radius: 30px;


    }

    .icon_badge_card_box{
        box-shadow: inset 5px 5px 10px  #CACACA;
        border-radius: 100%;
        width: 110px;
        height:110px;
        border: 1px solid #CACACA;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon_badge_card_box img{
        width: 70%;
        padding: 10px;
    }

}

@media screen and (max-width: 263px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 250px;

        height: 390px;
        text-align: center;
        margin: 20px;
        border-radius: 30px;


    }


}
@media screen and (max-width: 215px){

    .badge_box_compo_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        padding: 20px;
        box-shadow: 5px 5px 10px #A6B4C8;
        background-color: #fff;
        width: 250px;

        height: 439px;
        text-align: center;
        margin: 20px;
        border-radius: 30px;


    }


}
.community_wrapper_main_outer{
    background-color: #1468BA;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;

    padding-bottom: 60px;
}

.community_wrapper_main_inner{
    width: 100%;
}
.community_wrapper_main_inner .community_wrapper_heading{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    font-family: Poppins;
    color: #ffffff;
    margin-bottom: 50px;

}

.community_wrapper_main_inner .community_wrapper_heading span{
    color: #FF9900;

}

.community_wrapper_counter{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    /*background-color: red;*/

}

.counter_box{
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;



}
.counter_box h2{
    font-size: 45px;
    font-weight: bold;
    text-align: center;
    font-family: Poppins;
    color: #ffffff;
}

.counter_box h3{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    font-family: Poppins;
    height: 40px;
    color: #ffffff;
}

.center_counter_box_line{
    padding-left: 50px;
    padding-right: 50px;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    margin-left: 30px;
    margin-right: 30px;
}


@media screen and (max-width: 820px) {
    .community_wrapper_main_inner .community_wrapper_heading{
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
        margin-bottom: 50px;

    }
    .counter_box h2{
        font-size: 35px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }

    .counter_box h3{
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }
    .center_counter_box_line{
        padding-left: 30px;
        padding-right: 30px;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        margin-left: 20px;
        margin-right: 20px;
    }


}




@media screen and (max-width:640px) {
    .community_wrapper_main_inner .community_wrapper_heading{
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
        margin-bottom: 40px;

    }
    .counter_box h2{
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }

    .counter_box h3{
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }
    .center_counter_box_line{
        padding-left: 20px;
        padding-right: 20px;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        margin-left: 10px;
        margin-right: 10px;
    }


}






@media screen and (max-width:439px) {

    .community_wrapper_counter{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        /*background-color: red;*/

    }

    .community_wrapper_main_inner .community_wrapper_heading{
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
        margin-bottom: 40px;

    }
    .counter_box h2{
        font-size: 45px;
        font-weight: bold;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }

    .counter_box h3{
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        font-family: Poppins;
        color: #ffffff;
    }
    .center_counter_box_line{
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        border-left: none;
        border-right: none;
        margin-left: 0px;
        margin-right: 0px;

        margin-top: 10px;
        margin-bottom: 10px;
    }


}
.key_benefits_main_outer{
    background-color: #EBF7FA;
    padding: 30px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.key_benefits_image_wrapper{
    width: 100%;
}

.key_benefits_image_wrapper img{
    padding: 20%;
    width: 100%;

}

.key_benefits_content_wrapper_inner{
    display: flex;
    flex-direction: column;
    width: 100%;
  padding-right: 100px;
}
.key_benefits_content_wrapper_inner h2{
    font-size: 35px;
    font-family: Poppins;
    font-weight: bold;
    margin-bottom: 40px;
}

.key_benefits_content_wrapper_inner h2 span{
color: #FF9900;

}

.key_benefits_content_wrapper_inner .simple_info_benefits{
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins;
    color: #1B1C1F;
    padding-left: 30px;
}
.key_benefits_content_wrapper_inner .key_benefits_heighlight_content p{
    background-color: #1468BA;
    font-size: 20px;
    font-weight: 400;
    font-family: Poppins;
    color: #ffffff;
    padding: 10px;
    padding-left: 30px;
    border-radius: 10px;

}


@media screen and (max-width: 1050px) {

    .key_benefits_content_wrapper_inner{
        padding-right: 50px;
    }

    .key_benefits_content_wrapper_inner .simple_info_benefits{
        font-size: 16px;

    }
    .key_benefits_content_wrapper_inner .key_benefits_heighlight_content p{
        font-size: 16px;
    }

}


@media screen and (max-width: 860px) {
    .key_benefits_image_wrapper{
        width:70%;
    }

    .key_benefits_image_wrapper img{
        padding: 20%;
        width: 100%;

    }

    .key_benefits_content_wrapper_inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 100px;
    }

    .key_benefits_content_wrapper_inner{
        padding-right: 20px;
    }

    .key_benefits_content_wrapper_inner .simple_info_benefits{
        font-size: 16px;

    }
    .key_benefits_content_wrapper_inner .key_benefits_heighlight_content p{
        font-size: 16px;
    }

}


@media screen and (max-width:689px) {
    .key_benefits_main_outer{
        background-color: #EBF7FA;
        padding: 30px;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .key_benefits_image_wrapper{
        width:60%;
    }

    .key_benefits_image_wrapper img{
        padding: 10%;
        width: 100%;

    }



}


@media screen and (max-width: 450px) {
    .key_benefits_image_wrapper{
        width:70%;
    }

    .key_benefits_image_wrapper img{
        padding: 5%;
        width: 100%;

    }

    .key_benefits_content_wrapper_inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 0px;
    }

    .key_benefits_content_wrapper_inner{
        padding-right: 0px;
    }

    .key_benefits_content_wrapper_inner .simple_info_benefits{
        font-size: 14px;

    }
    .key_benefits_content_wrapper_inner .key_benefits_heighlight_content p{
        font-size: 14px;
    }

}
.chex_solution_wrapper_main{
    padding: 30px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 50px;
}
.chex_solution_wrapper_main p{
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
    text-align: center;
    color: #000000;
    padding-bottom: 20px;
    padding-left: 250px;
    padding-right: 250px;


}

.chex_solution_wrapper_main p span{
    color: #FF9900;

}

.chex_solution_wrapper_main .chex_solution_image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.chex_solution_wrapper_main .chex_solution_image img{
    width: 50%;

}


@media screen and (max-width: 980px){
    .chex_solution_wrapper_main p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 500;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
        padding-left: 120px;
        padding-right: 120px;


    }


}

@media screen and (max-width: 767px){
    .chex_solution_wrapper_main p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 500;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;


    }


}

@media screen and (max-width: 520px){

    .chex_solution_wrapper_main p{
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;


    }

    .chex_solution_wrapper_main .chex_solution_image{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    .chex_solution_wrapper_main .chex_solution_image img{
        width: 70%;

    }



}


@media screen and (max-width: 400px){
    .chex_solution_wrapper_main{
        padding: 30px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 20px;
    }
    .chex_solution_wrapper_main p{
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;


    }


}
.main_wrapper_help_business_outer{

    padding: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

}
.wrapper_reverse_order{
    flex-direction: row-reverse!important;
}

.wrapper_background_style {
    background-color: #FAFAFA;
}
.content_wrapper_help_business{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 60%;
    padding-right: 80px;


}

.wrapper_reverse_order .content_wrapper_help_business{
    padding-right: 0px !important;
    padding-left: 70px !important;
}

.content_wrapper_help_business h2{
    font-size: 32px;
    font-weight: bold;



}

.content_wrapper_help_business h2 .business_help_span_style{
    color: #FF9900;

}


.content_wrapper_help_business p{
    font-size: 18px;
    font-weight: 400;
}

.content_wrapper_help_business .btn_contact_wrapper_help_business{
    margin-top: 40px;
}

.content_wrapper_help_business .btn_contact_wrapper_help_business a{
    background-color: #1468BA;
    color: #ffffff;
    border: 1px solid #1468BA;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;

}

.content_wrapper_help_business .btn_contact_wrapper_help_business a:hover{
    background-color: #ffffff;
    /*border: 1px solid #1468BA;*/

    color: #1468BA;
    transition: 0.7s;



}

.image_wrapper_help_business{
    width: 70%;

}
.imageGovtStyle{
    width: 50% !important;
}

.wrapper_reverse_order .image_wrapper_help_business{
    width: 50% !important;
}

.image_wrapper_help_business img{
width: 100%;
    padding: 30px;
}


@media screen and (max-width: 1100px) {
    .main_wrapper_help_business_outer{

        padding: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 60%;
        padding-right: 50px;


    }

    .image_wrapper_help_business{
        width: 60%;

    }
    .imageGovtStyle{
        width: 50% !important;
    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 30px;
    }
    
}



@media screen and (max-width:850px) {
    .main_wrapper_help_business_outer{

        padding: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

    }
    .imageGovtStyle{
        width: 100% !important;
    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 80%;
        padding-right: 40px;


    }

    .image_wrapper_help_business{
        width: 80%;

    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 30px;
    }

    .content_wrapper_help_business .btn_contact_wrapper_help_business{
        margin-top: 20px;
    }

    .content_wrapper_help_business h2{
        font-size: 24px;
    }

    .content_wrapper_help_business p{
        font-size: 14px;

    }



}



@media screen and (max-width:648px) {
    .main_wrapper_help_business_outer{

        padding: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;


    }
    .wrapper_reverse_order{
        flex-direction:column-reverse!important;
    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        padding-right: 0px;


    }

    .image_wrapper_help_business{
        width: 80%;

    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 30px;
    }
    .wrapper_reverse_order .content_wrapper_help_business{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .wrapper_reverse_order .image_wrapper_help_business{
        width: 80% !important;
    }


    .content_wrapper_help_business .btn_contact_wrapper_help_business{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_wrapper_help_business h2{
        font-size: 26px;
        text-align: center;
    }

    .content_wrapper_help_business p{
        font-size: 16px;
        text-align: center;

    }



}







@media screen and (max-width:479px) {
    .main_wrapper_help_business_outer{

        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;


    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        padding-right: 0px;
        padding-top: 20px;


    }

    .image_wrapper_help_business{
        width: 80%;

    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 0px;
    }

    .content_wrapper_help_business .btn_contact_wrapper_help_business{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_wrapper_help_business h2{
        font-size: 22px;
        text-align: center;
    }

    .content_wrapper_help_business p{
        font-size: 14px;
        text-align: center;

    }



}






@media screen and (max-width:341px) {
    .main_wrapper_help_business_outer{

        padding: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;


    }
    .content_wrapper_help_business{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-right: 0px;
        padding-top: 20px;


    }

    .image_wrapper_help_business{
        width: 80%;

    }

    .image_wrapper_help_business img{
        width: 100%;
        padding: 0px;
    }

    .content_wrapper_help_business .btn_contact_wrapper_help_business{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_wrapper_help_business h2{
        font-size: 18px;
        text-align: center;
    }

    .content_wrapper_help_business p{
        font-size: 12px;
        text-align: center;

    }



}
.why_chex_component_wrapper_outer{
    background-color: #ffffff;
    /*border: 1px solid black;*/
    padding: 50px;


}

.why_chex_heading{
    text-align: center;

}
.why_chex_heading h2{
    font-size: 42px;
    font-family: Poppins;
    font-weight: bold;
    color: #1B1C1F;

}

.why_chex_heading .span_style_why{
    color: #FF9900;

}

.why_chex_steps_inner_main_wrapper{
    padding: 120px;
    background-image: url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470817/Vectorlogo_1_drg0is_ki4kh8.png');
    background-repeat: no-repeat;
    background-size: contain;
    /*background-color: red;*/

}

.why_chex_step_straight_wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    padding: 50px;
    /*background-color: green;*/

}
.why_chex_step_straight_wrapper p{
    font-size: 22px;
    font-family: Poppins;
    font-weight: 400;
    color: #1B1C1F;
    padding-left: 300px;

}

.why_chex_step_straight_wrapper img{
    width: 28%;
    padding: 0%;
}

.why_chex_step_reverse_wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}

.why_chex_step_reverse_wrapper img{
    width: 25%;
    padding: 0%;
}

.why_chex_step_reverse_wrapper p{
    font-size: 22px;
    font-family: Poppins;
    font-weight: 400;
    color: #1B1C1F;
    padding-right: 300px;
}


@media screen and (max-width: 1190px){
    .why_chex_step_straight_wrapper p{
        font-size: 22px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-left: 200px;

    }

    .why_chex_step_reverse_wrapper p{
        font-size: 22px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-right: 200px;
    }

}

@media screen and (max-width: 1000px){
    .why_chex_steps_inner_main_wrapper{
        padding: 50px;
        background-image: url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470817/Vectorlogo_1_drg0is_ki4kh8.png');
        background-repeat: no-repeat;
        background-size: contain;
        /*background-color: red;*/

    }

    .why_chex_step_straight_wrapper{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 30px;
        /*background-color: green;*/

    }


    .why_chex_step_straight_wrapper img{
        width: 35%;
        padding: 0%;
    }

    .why_chex_step_reverse_wrapper img{
        width: 29%;
        padding: 0%;
    }
    .why_chex_step_straight_wrapper p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-left: 100px;

    }

    .why_chex_step_reverse_wrapper p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-right: 100px;
    }

}





@media screen and (max-width: 700px){
    .why_chex_steps_inner_main_wrapper{
        padding: 20px;
        background-image: url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470817/Vectorlogo_1_drg0is_ki4kh8.png');
        background-repeat: no-repeat;
        background-size: contain;
        /*background-color: red;*/

    }
    .why_chex_heading h2{
        font-size: 32px;
        font-family: Poppins;
        font-weight: bold;
        color: #1B1C1F;

    }

    .why_chex_step_straight_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px;

        /*background-color: green;*/

    }


    .why_chex_step_reverse_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }


    .why_chex_step_straight_wrapper img{
        width: 55%;
        padding: 0%;
    }

    .why_chex_step_reverse_wrapper img{
        width: 45%;
        padding: 0%;
    }
    .why_chex_step_straight_wrapper p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 0px;
        padding-top: 30px;

    }

    .why_chex_step_reverse_wrapper p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-right: 0px;
        text-align: center;
        padding-top: 30px;
    }

}





@media screen and (max-width: 472px){
    .why_chex_component_wrapper_outer{
        background-color: #ffffff;
        /*border: 1px solid black;*/
        padding: 30px;


    }

    .why_chex_steps_inner_main_wrapper{
        padding: 0px;
        background-image: none;
        background-repeat: no-repeat;
        background-size: cover;
        /*background-color: red;*/

    }

    .why_chex_step_straight_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;

        /*background-color: green;*/

    }


    .why_chex_step_reverse_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }


    .why_chex_step_straight_wrapper img{
        width: 55%;
        padding: 0%;
    }

    .why_chex_step_reverse_wrapper img{
        width: 45%;
        padding: 0%;
    }
    .why_chex_step_straight_wrapper p{
        font-size: 16px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 0px;
        padding-top: 30px;

    }

    .why_chex_step_reverse_wrapper p{
        font-size: 16px;
        font-family: Poppins;
        font-weight: 400;
        color: #1B1C1F;
        padding-right: 0px;
        text-align: center;
        padding-top: 30px;
    }

}



@media screen and (max-width: 400px) {


    .why_chex_heading h2 {
        font-size: 27px;
        font-family: Poppins;
        font-weight: bold;
        color: #1B1C1F;

    }
}

.Chex_video_wrapper{
    width: 100%;
    /*height: 530px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.chex-demo-video_custom{
    border-radius: 3% ;
    height: 380px;
    width: 674px;
    border: none;
    z-index: 1;
}

@media screen and (max-width: 1024px) {

    .chex-demo-video_custom{
        border-radius: 3% ;
        height: auto;
        width: 100%;
        /*border: 1px solid red;*/
    }
}

@media only screen and (max-width: 767px) {
    .chex-demo-video_custom{
        border-radius: 3% ;

        width: 100%;
        /*border: 1px solid green;*/
    }
}


@media only screen and (max-width: 600px) {
    .chex-demo-video_custom{
        border-radius: 3% ;
        width: 90%;
        border: 1px solid blue;
    }
}


@media only screen and (max-width: 550px){

    .chex-demo-video_custom{
        border-radius: 3% ;
        width: 90%;
        border: 1px solid transparent;
    }
}
@media only screen and (max-width: 480px) {


    .chex-demo-video_custom {
        border-radius: 15px;

        width: 90%;
        /*border: 1px solid red;*/
        margin-left: -5px;
    }

}
.main_section_review_custom{
    background-color: #FFFFFF;
    margin-bottom: 100px;
    position: relative;
    margin-top: 40px;

}

.review_container_main_new  .rec.rec-arrow-right {

    top: 40%;
    right:6%;


}
.review_container_main_new .rec.rec-arrow-left{

    left: 6%;
    top: 40%;

}

@media only screen and (max-width: 1200px) {

   .review_container_main_new  .rec.rec-arrow-right {


       top: 40%;
       right:3%;


    }
    .review_container_main_new .rec.rec-arrow-left{

        left: 3%;
        top: 40%;

    }

}

@media only screen and (max-width:1014px) {

    .review_container_main_new  .rec.rec-arrow-right {
        top:inherit ;
        bottom:-20%;
        right:43%;


    }
    .review_container_main_new .rec.rec-arrow-left{
        top:inherit ;
        bottom:-20%;

        left:43%;


    }

}

@media screen and  (max-width: 767px){
    .main_section_review_custom{
        /*margin-bottom: 0px;*/
    }

}



.headingCustomerReviews_custom{
    padding-left: 0px;
    font-size:35px;
    font-family: Poppins;
    color: black;
    text-align: center;
    font-weight: bold;
}
.main_section_review_custom .para_heading_sub {
    font-size:18px;
    font-family: Poppins;
    color: black;
    text-align: center;
    font-weight: 400;
}
@media screen and (max-width: 767px) {
    .headingCustomerReviews_custom{
        font-size:28px;
    }

}
@media screen and (max-width: 570px) {
    .headingCustomerReviews_custom{

        font-size:20px;
    }

}
@media screen and (max-width: 455px) {
    .headingCustomerReviews_custom{

        font-size:24px;
    }
    .main_section_review_custom .para_heading_sub {
        font-size:14px;

    }

}
@media screen and (max-width: 320px) {
    .headingCustomerReviews_custom{

        font-size:20px;
    }
    .main_section_review_custom .para_heading_sub {
        font-size:12px;

    }

}


.review_container_main_new{
    width: 100%;

    background-color: #FFFFFF;
}



@media screen and  (max-width: 767px){
    .review_container_main_new{
        width: 100%;
        background-color: #FFFFFF;

        padding-top: 0px;
        margin-bottom: 30px;
    }

    .review_container_main_new .rec.rec-arrow-right {
        background-color: #F4F9FF;
        border-radius: 30px;
        position: absolute;
        right:40%;
        bottom:-10%;

    }
    .review_container_main_new .rec.rec-arrow:hover{
        background-color: #F4F9FF!important;
        color:black!important;
    }
    .review_container_main_new .rec.rec-arrow:focus{
        background-color: #F4F9FF!important;
        color:black!important;
    }

    .review_container_main_new .rec.rec-arrow:hover{
        background-color: #F4F9FF;
    }
    .review_container_main_new .rec.rec-arrow-left{

        background-color:#F4F9FF;
        border-radius: 30px;
        z-index: 1;
        bottom:-10%;
        position: absolute;
        left: 40%;

    }


}


@media only screen and (max-width: 480px) {
    .review_container_main_new{
        width: 100%;

        background-color: #FFFFFF;
        padding-top: 30px;
    }


    .review_container_main_new .rec.rec-arrow-right {

        right:35%;
        bottom:-10%;


    }
    .review_container_main_new .rec.rec-arrow-left{
        left: 35%;
        bottom:-10%;
    }

}


@media  screen and (max-width: 360px){
    .review_container_main_new  .rec.rec-arrow-right {

        right:30%;
        bottom:-10%;

    }
    .review_container_main_new .rec.rec-arrow-left{
        left: 30%;
        bottom:-10%;
    }

}


@media  screen and (max-width: 330px){
    .review_container_main_new .rec.rec-arrow-right {
        right:30%;
        bottom:-10%;
    }
    .review_container_main_new .rec.rec-arrow-left{
        left: 30%;
        bottom:-10%;
    }
}


.pricing_card_main_ride{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-shadow: 5px 5px 10px #A6B4C8;
    border-radius: 20px;
    width: 180px;

}
.card_debose_section_wrap{

    border-radius: 15px;
    padding: 15px;
    padding-bottom:0px;
    box-shadow: inset 5px 5px 10px #d3d3d3;
    margin-bottom: 30px;

}
.image_debose_part_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -5px;
    width: 130px;
    height: 70px;

    /*background-color: rgba(255, 0, 0, 0.5);*/

}
.imageLyft{
    width: 40% !important;
}
.imageDouble{
    width: 40% !important;
}
.card_debose_section_wrap .image_debose_part_wrapper img{
    width: 53%;
    /*padding-bottom: 10px;*/
}
.card_debose_section_wrap .image_debose_part_wrapper .para_debose_point{
    font-size: 16px;
    padding-top: 3px;
}

.content_section_pricing_card_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    /*background-co lor: red;*/
}
.content_section_pricing_card_wrapper .heading_content_pricing{
    font-size: 30px;
    font-weight: bold;
    font-family: Poppins;
    color: #FF9900;
}

.content_section_pricing_card_wrapper .para_pricing_content{
    font-size: 12px;
    font-weight: 400;
    margin-top:-10px;
    font-family: Poppins;

}

@media screen and (max-width:713px) {
    .pricing_card_main_ride{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        box-shadow: 5px 5px 10px #A6B4C8;
        border-radius: 20px;
        width: 180px;

    }
    
}


@media screen and (max-width:548px) {
    .pricing_card_main_ride{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        box-shadow: 5px 5px 10px #A6B4C8;
        border-radius: 20px;
        width: 180px;

    }

    .imageLyft{
        width: 40% !important;
    }
    .imageDouble{
        width: 40% !important;
    }
    .card_debose_section_wrap .image_debose_part_wrapper img{
        width: 53%;
        /*padding-bottom: 10px;*/
    }
    .card_debose_section_wrap .image_debose_part_wrapper .para_debose_point{
        font-size: 16px;
        padding-top: 3px;
    }

}
.pricing_card_main_ride{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    box-shadow: 5px 5px 10px #A6B4C8;
    border-radius: 20px;
    width: 180px;

}
.card_debose_wrap{

    border-radius: 15px;
    padding: 15px;
    padding-bottom:0px;
    box-shadow: inset 5px 5px 10px #d3d3d3;
    margin-bottom: 30px;

}
.image_debose_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -5px;
    width: 130px;
    height: 120px;

    /*background-color: rgba(255, 0, 0, 0.5);*/

}
.imageLyft{
    width: 40% !important;
}
.imageDouble{
    width: 40% !important;
}
.card_debose_section_wrap .image_debose_part_wrapper img{
    width: 53%;
    /*padding-bottom: 10px;*/
}
.card_debose_section_wrap .image_debose_part_wrapper .para_debose_point{
    font-size: 16px;
    padding-top: 3px;
}

.content_section_pricing_card_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    /*background-co lor: red;*/
}
 .heading_content_price{
    font-size: 45px;
    font-weight: 700;
    font-family: Poppins;
    color: #FF9900;
}
 .heading_content_price span{
     font-size: 30px;
 }

.content_section_pricing_card_wrapper .para_pricing_content{
    font-size: 12px;
    font-weight: 400;
    margin-top:-10px;
    font-family: Poppins;

}

@media screen and (max-width:713px) {
    .pricing_card_main_ride{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        box-shadow: 5px 5px 10px #A6B4C8;
        border-radius: 20px;
        width: 180px;

    }

}


@media screen and (max-width:548px) {
    .pricing_card_main_ride{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        box-shadow: 5px 5px 10px #A6B4C8;
        border-radius: 20px;
        width: 180px;

    }

    .imageLyft{
        width: 40% !important;
    }
    .imageDouble{
        width: 40% !important;
    }
    .card_debose_section_wrap .image_debose_part_wrapper img{
        width: 53%;
        /*padding-bottom: 10px;*/
    }
    .card_debose_section_wrap .image_debose_part_wrapper .para_debose_point{
        font-size: 16px;
        padding-top: 3px;
    }

}
.footerContainer{
    background: #1B1C1F;
    margin-top: 60px;
    padding-top: 50px;
    /*padding-left: 200px;*/
    padding-bottom: 50px;
    color:White;
}
.aboutSection_custom{
    margin-right: 20px;
}
.footerLogoContainer{
    margin-bottom: 30px;
}
.footerAboutDescription{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #FFFFFF;
}

.headingFooter{
    font-family: Poppins;
    font-style: normal;
    width: 100%;
    font-weight: 600;
    /*background-color: red;*/
    font-size: 16px;
    margin-bottom:20px;
    line-height: 20px;
    color: #FFFFFF;

}
.footerMenuList{
    list-style: none;
    float: left;
    margin: 0;
    padding: 0;


}
.footerMenuList li{
    margin-bottom: 10px;
}
.footerMenuList li a{
    color: #808389!important;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}
.footerMenuList li a:hover{
    color: #FF7A00!important;
}
.textFooter{
    font-size: 14px;

    color: #FFFFFF;
    margin-bottom: 30px;

}
.iconsContainer{
    margin-bottom: 20px;
}
.iconsContainer a{
    /*background-color: red;*/
    /* padding:20px;*/
    margin-right: 15px;
}
.footerLinks_custom{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left!important;
    /*background-color: red;*/
}
.footerLinks_custom span{
    margin-bottom: 10px;


}
.footerLinks_custom span Link{
    color:#FFFFFF!important;
}

.footerLinks_custom span:hover{
    color: #FF7A00!important;
}
.menuContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom:20px;
    padding-left: 50px;

}

.copyrightText{
    background-color: #000000;
    padding: 10px;
    padding-top: 20px;
    text-align: center;
}
@media screen and (max-width: 900px){
    .textFooter{
        font-size:11px
    }


}

@media screen and (max-width: 768px){
    .menuContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;
        padding-left: 0px;

    }
    .headingFooter{
        text-align: center;
    }
    .aboutSection_custom{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left:30px;
        padding-right: 30px;
        text-align: center;
        margin-bottom:20px;
        margin-right: 0px;
        /*background-color: red;*/
    }
    .footerMenuList{
        text-align: center;

    }
    .headingFooter{

        font-size: 18px;

    }
    .ContactContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;
    }
    .textFooter{
        font-size: 14px;
    }
    .iconsContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .copyrightText{
        text-align: center;
    }
    .footerLinks_custom{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left!important;
        /*background-color: red;*/
    }
}

.ankr_phone_footer{
    text-decoration: none;

}
.ankr_phone_footer:hover{
    transition:0.8s;
    color:#FF7A00;
}

.veh-inspection-chexai_text {
  /* font-family: Poppins; */
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}

.vec-inspection-top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.veh-inspection-mobilepaddind {
  text-align: center;
}

.veh-inspection-verification_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468ba;
  margin-left: -30px !important;
}
.modal-container {
  /* width: 400px; */
  /* max-height: 30vh; */
  /* height:30vh; */


}



.veh-inspection-verification__formtext {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  margin-left: -30px !important;
}
.veh-inspection-cards_container {
  display: flex;
  /* align-items: center; */
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: white;
}

.veh-inspection-first_card {
  width: 45%;
  padding: 10px;
}

.veh-inspection-second_card {
  width: 48%;
}

.vec-inspection-submit_button {
  background-color: #ff7a00;
  width: 80%;
  padding: 7px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 100px;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 15px;
  height: 54px !important;
}

.vec-inspection-Confirm__button {
  background-color: #ff7a00;
  width: 30%;
  padding: 4px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 4px;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 19px;
 margin-left: 5%;
  
}
.vec-inspection-Confirm__ {
  background-color: #ff7a00;
  width: 42.5%;
  padding: 4px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 100px;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 19px;
 margin-left: 28%;
  /* height: 23px !important; */
}
.vec-inspection-Confirm--{
  background-color: #ff7a00;
  width: 42.5%;
  padding: 4px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 100px;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 23px;
 margin-left: 28%;
}

.vec-inspection-Edit__button {
  background-color: #0c559a;
  width: 30%;
  padding: 4px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 4px;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 19px;
 margin-left: 30%;
  /* height: 23px !important; */
}

.vec-inspection-submitbtn_container {
  padding: 30px;
  text-align: center;
}

/* .ant-collapse {
  border-bottom: 6px solid red;
} */
.ant-collapse .ant-collapse-item {
  border-bottom: 7px solid white !important;
}
.ant-input[disabled] {
  color: #555555; /* Darker text color (adjust the color code as per your preference) */
}

.header-min-width {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.header-max-width {
  width: 32%;
}

.vehicle-inspection-content-container {
  background-color: white !important;
  border-radius: 5px !important;
  margin: 15px !important;
}

.collapse-number-container {
  background-color: #e7eff8 !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1468ba;
  border-radius: 4px;
}

.collapse-completed-number-container {
  background-color: #20C18D !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 4px;
}

.vehicle-inspection-collapse-style .ant-collapse.ant-collapse-icon-position-right.vehicle-inspection-content-container {
  border: none !important;
  box-shadow: 0px 10px 30px 0px #0000001A;

}

@media only screen and (max-width: 1024px) {
  .header-max-width {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .header-max-width {
    width: 93%;
  }
}

@media only screen and (max-width: 600px) {
  .header-max-width {
    width: 100%;
  }
}

.itsTXQ {
  border-bottom-left-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.fhkuzc {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B !important;
}

.jeqjDl {
  background: linear-gradient(182.55deg, #1876CC -200%, #001B51 445%), #191A1B !important;
}

.gQYBcx {
  background: linear-gradient(182.55deg, #1876CC -200%, #001B51 445%), #191A1B !important;
}

.kGlcxo {
  background: linear-gradient(182.55deg, #1876CC -200%, #001B51 445%), #191A1B !important;
}
/* .transcation-container {
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b; }*/
.trans-container {
  background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b;
  /*height: 100vh !important;*/
  /*position: absolute;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
}
.transcation-container{
  position: relative;
}
.arrows_top_header{

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items:center!important;
  padding-bottom: 10px;

}
.arrows_style{

  position: absolute;
  left:0%;
  /*justify-content: flex-start!important;*/
  /*align-items: flex-start;*/
}
.chex_wrapper_main_text{
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;

  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;
}
.transaction-screen-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10px; */
  padding: 10px;
}
.header-icon {
  color: #2584e0;
  width: 20px;
  height: 32px;
}
.header-text {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.transaction-payment {
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  font-font: Poppins;
  color: #FF7A00;
  margin-bottom: -8px;
}
.transaction-amount {
  font-size: 33px!important;
  font-font: Poppins;
  font-weight: 600;
}

.transaction-payment-heading{
  font-family: Poppins;
  font-weight: bold;
  font-size: 18px;
  color: black;
}

.amount-detail-style{
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(31, 59, 85, 0.67) !important;
  font-family: Inter, Arial;
  font-size: 14px;
}

.transaction-form-container {
  background-color: #fff;
  border-radius: 20px;
  border-bottom-left-radius:0px!important;
  border-bottom-right-radius:0px!important;
  width: 375px;
  padding: 20px;
  margin-bottom: 0px;
}
.input-field-name {
  font-size: 16px;
  font-weight: 600!important;
  color: #3F5162!important;
  margin-bottom: 5px!important;
  margin-top: 10px;
  font-family: 'Poppins' !important;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 1000px;
  padding: 15px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: none;
  border: 1px solid #DDE3E8;
  border: 0;
  outline: 0;
  border-radius: 12px;
  background-color: #fff;
  width: 100%;
}



.inputtransaction.StripeElement {
  display: block!important;
  margin: 10px 0 20px 0!important;
  max-width: 1000px!important;
  padding: 10px 14px!important;
  font-size: 1em!important;
  font-family: 'Source Code Pro', monospace;
  box-shadow: none!important;
  border: 1px solid #DDE3E8!important;
  outline: 0!important;
  border-radius: 12px!important;
  background-color: #fff!important;
  width: 100%!important;
}

@media only screen and (max-width: 600px) {

  .transaction-form-container {
    background-color: #fff;
    border-radius: 20px;
    width:365px;
    /*width:100%;*/
    padding: 20px;
    margin-bottom: 0px;
  }

}


@media only screen and (max-width: 500px) {
  .transcation-container{
    width: 100%;
  }
  .transaction-form-container {
    background-color: #fff;
    border-radius: 20px;
    border-bottom-left-radius:0px!important;
    border-bottom-right-radius:0px!important;
    width:100%;
    padding: 20px;
    margin-bottom: 0px;
  }

}




.cardholder-input_main{
  font-size: 18px;
  font-family: Poppins;
  box-shadow: none!important;
  background-color: #fff!important;
  color: #1F3B55!important;
  border: 1px solid #DDE3E8!important;
  font-weight: 500;
  border-radius: 12px;
  padding: 10px 14px!important;

}

.cardholder-input_main::-webkit-input-placeholder{
  font-size: 18px!important;
  background-color: #fff!important;
  color: #486288!important;

}

.cardholder-input_main:-ms-input-placeholder{
  font-size: 18px!important;
  background-color: #fff!important;
  color: #486288!important;

}

.cardholder-input_main::placeholder{
  font-size: 18px!important;
  background-color: #fff!important;
  color: #486288!important;

}
@media only screen and (min-width: 1360px) {
  .cardholder-input {
    width: 100%;
    /* height: 100% !important; */
  }
}

.certificate-input-conatainer {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.certificate-input-conatainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.fail-checkbox {
  margin-left: 10px;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  border: 1px solid black;
  width: 18px;
  background-color: #fff;
}
/* On mouse-over, add a grey background color */
.certificate-input-conatainer:hover input ~ .checkmark {
  background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.certificate-input-conatainer input:checked ~ .checkmark {
  background-color: #fff;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.certificate-input-conatainer input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.certificate-input-conatainer .checkmark:after {
  left: 3px;
  top: -7px;
  width: 10px;
  height: 22px;
  border: solid #fc6522;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media only screen and (max-width: 430px) {
  .vehicle-milage {
    font-size: 14px;
    margin-left: -5px;
  }
  .lisence-plate {
    font-size: 14px;
    margin-left: 20px;
  }
  .vin-inspection {
    font-size: 14px;
    margin-left: 20px;
  }
  .vehicle-make {
    font-size: 14px;
    margin-left: -5px;
  }
  .vehicle-model {
    font-size: 14px;
    margin-left: 20px;
  }
  .vehicle-year {
    font-size: 14px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 370px) {
  .vehicle-milage {
    font-size: 12px;
    margin-left: -30px;
  }
  .lisence-plate {
    font-size: 12px;
    margin-left: 15px;
  }
  .vin-inspection {
    font-size: 12px;
    margin-left: 15px;
  }
  .vehicle-make {
    font-size: 12px;
    margin-left: -30px;
  }
  .vehicle-model {
    font-size: 12px;
    margin-left: 15px;
  }
  .vehicle-year {
    font-size: 12px;
    margin-left: 15px;
  }
}
.fontFamily {
  font-family: Roboto Slab;
  font-weight: 400;
}

.veh-inspection-chexai_text {
  /* font-family: Poppins; */
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0;
}
.vec-inspection-top_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}
/* 
.veh-inspection-mobilepaddind {
  padding: 10x;
  display: flex;
  justify-content: center;
} */
.veh-status-inspection-verification_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #1468ba;
  margin-left: 0px;
}

/* .veh-inspection-cards_container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  margin-top: 10px;
  flex-wrap: wrap;
} */

.veh-inspection-second_card {
  width: 48%;
}
.vec-inspection-submit_button {
  background-color: #ff7a00;
  width: 80%;
  padding: 7px;
  border: none;
  outline: none;
  text-align: center;
  border-radius: 100px;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 15px;
  height: 54px !important;
}
.vec-inspection-submitbtn_container {
  padding: 30px;
  text-align: center;
}
/* .ant-collapse {
    border-bottom: 6px solid red;
  } */
.ant-collapse .ant-collapse-item {
  border-bottom: 7px solid white !important;
}
.vec-inspection-top_header {
  display: flex;
  justify-content: space-between !important;
}

.intro-main-container{
    width: 100%;
}
.intro-imageBackground-main{
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: url(/static/media/bg_img.f9acb53d.jpg);
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
} 
  @media screen and (min-width: 768px) {
    .intro-imageBackground-main {
      background-size: cover;
      background-position: center center;
    }
}
.intro-mainBgs {
    background: linear-gradient(182.55deg, rgba(24, 118, 204, 0.1), rgba(0, 27, 81, 1) 115.19%), rgba(25, 26, 27, 0.1);
    position: relative;
    width: 100%;
    height: 100%;
}
.intro-contentMain-screen{
  width: 100%;
}
.intro-headingH5{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #74b3fb;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0px;
}
.intro-headingH1{
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 86px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    padding-bottom: 0px;
    margin: 0px;
    width: 100%;
}
.intro-headingSpan {
  color: #ff9900;
}
.intro-contentP {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}
.intro-content-footer-area{
  position: absolute;
  bottom: 0%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.intro-MtB5 {
  /* margin-bottom: 42px; */
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-signinBtn{
    background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
    border-radius: 100px;
    border: 1px solid #ff7400;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    padding: 8px 60px 15px;
    text-decoration: none;
    width: 260px;
}
@media screen and (max-width: 600px) {
    .intro-signinBtn{
      width: 80%;
    }
}
.intro-contentDiv-button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
}
.intro-signUpWith-email{
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: none;
  padding: 12px 52px;
  text-decoration: none;
  width: 260px;
}
@media screen and (max-width: 600px) {
    .intro-signUpWith-email{
      width: 80%;
    }
}
.intro-signUpWith-email:active{
    outline: none;
    box-shadow: 0 0 0 0 !important;
}
.intro-signUpWith-email:focus {
    outline: none;
    box-shadow: 0 0 0 0 !important;
}
.transaction-main-container {
  background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b;
  display: flex;
  justify-content: center;
}
.transaction-screen-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 9%;
}
.header-icon {
  color: #2584e0;
  width: 25px;
  height: 32px;
}
.vehicledetail-text {
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.transaction-form-container-veh {
  background-color: #fff;
  border-radius: 30px;
  padding: 20px;
  margin: 0px;
  height: 100vh;
  margin-bottom: 50px;
}
.input-field-name {
  font-size: 16px;
  font-weight: 400;
  color: #889bad;
  margin-bottom: 6px;
  margin-top: 10px;
  font-family: 'Poppins' !important;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 1000px;
  padding: 15px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background-color: #e7eef5;
  width: 100%;
}


  .container {
    display: flex;
    justify-content: center;
  }


.year-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.year-text {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #889bad;
}
.year-date {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  color: #2a2a2a;
}

.login-main-container {
  width: 100%;
  position: absolute;
  height: 100%;
}

.login-image-background-signin {
  position: relative;
  width: 100%;
  background: url(/static/media/bg_img.2e602dac.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.confirm-email-main-bg-signin {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 20px 0;
}

.confirm-email-loader {
  margin-top: 20px;
}

.login-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2000;
}

.login-title-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #74b3fb;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 30px;
}

.login-back-arrow {
  color: #fff;
  position: absolute;
  left: 30px;
  top: 30px;
}
a {
  color: #fff;
}

.login-arrow-link {
  color: #fff;
}

.login-heading-logo {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 86px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 50px 0 0 0;
}
.logo-span-color {
  color: #ff9900;
}

.login-desp-heading {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0px;
}

.login-form-main-container {
  text-align: center;
  width: 100%;
  margin: 30px auto;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.forgot-password-link {
  color: white;
  font-size: 15px;
  margin-bottom: 18px;
}

.login-form-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;
}

.changes-paragraph{
  color: white;
    text-align: center;
    margin-top: 33px;
    font-size: 17px;
    margin-bottom: 33px;
}

@media screen and (min-width: 768px) {
  .login-image-background-signin {
    background-size: cover;
    background-position: center center;
  }
}

.doAndDoesContainer {
    /* background-color: red; */
    width: 50%;
    margin: auto;
    padding-bottom: 20px;
    text-align: center;
}
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
}
.doAndDoes-icon {
    font-size: 26px;
    color: #2584E0;
}
.doAndDoes-title {
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
}
.doAndDoes-content {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    padding-top: 20px;
    width: 80%;
    /* background-color: yellow; */
    margin: auto;
}
.doAndDoes-link {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10px;
    color: #FF7A00;
}
.doAndDoes-video {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    height: 30%;
}
.doAndDoes-paragraph {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: start !important;
    color: #888888;
    padding: 30px;
}

@media only screen and (max-width: 700px) {
    .doAndDoesContainer {
        width: 100%;
    }
    .doAndDoes-content {
    width: 90%;
    }
    .doAndDoes-video {
        padding: 30px !important;     
    }
}
.modal-styling {
    left: 0 !important;
    padding: 0 !important;
    right: 0 !important;
    width: 100%; 
    height: 100%;
    overflow: scroll;
}
.checkmark::before {
    display: none !important;
}  
.checkmark::after {
    left: 4px !important;
    top: -7px !important;
    width: 11px !important;
    height: 25px !important;
    border: 1px solid #FC6522 !important;
    border-width: 0 3px 3px 0 !important;
} 
.left-icon-termsAndPolicy {
    font-size: 20px !important;
}
.contact-us-container {
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b; */
  /* width: 30%; */
  position: relative;
  width: 100%;
  /* height: 821px; */
  /* background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b; */
}
.contact-us-main_container {
  background: linear-gradient(182.55deg, #1876cc -17.67%, #001b51 115.19%), #191a1b;
  /* height: 100%; */
  height: 100%;
  position: absolute;
  width: 100%;
  background-size: cover;
}
.contact-us-screen_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
  margin-top: 3%;
  padding: 10px;
}
.header-icon {
  color: #2584e0;
  width: 20px;
  height: 32px;
}
.header-text {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.contact-us-form_container {
  background-color: #fff;
  border-radius: 30px;
  padding: 20px 0px 20px 0px;
  margin-bottom: 0;
}
.contact-us-text {
  /* font-family: Gotham Pro; */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}
.input-fields-width {
  text-align: center;
  width: 95%;
  margin-left: 3%;
}
.text-area-field {
  text-align: center;
  /* margin-left: -8px; */
  margin-bottom: 15px;
}
.text-area-styling {
  background: rgb(231, 238, 245) !important;
  border: 1px solid rgb(231, 239, 248) !important;
  width: 95%;
  border-radius: 5px;
  padding-left: 8px;
  padding-top: 16px;
  font-family: 'Poppins' !important;
}
.text-area-styling::-webkit-input-placeholder {
  color: rgb(185, 179, 179);
  font-size: 17px;
  font-family: 'Poppins' !important;
}
.text-area-styling:-ms-input-placeholder {
  color: rgb(185, 179, 179);
  font-size: 17px;
  font-family: 'Poppins' !important;
}
.text-area-styling::placeholder {
  color: rgb(185, 179, 179);
  font-size: 17px;
  font-family: 'Poppins' !important;
}
.text-area-field :focus {
  border: 1px solid rgb(231, 239, 248) !important;
  outline: none;
  font-family: 'Poppins' !important;
}
.directlt-reach-text {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #8894a6;
  margin-top: 20px;
}
.email-container {
  display: flex;
  justify-content: center;
}
.email-text {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ff7a00;
  margin-left: 30px;
}
.contact-us-submit-btn {
  background: #2584e0 !important;
  border: none !important;
  outline: none !important;
  width: 90% !important;
}
@media only screen and (max-width: 700px) {
  .contact-us-container {
    width: 90%;
  }
  .contact-us-main_container {
    display: flex;
    justify-content: center;
    height: 900px;
  }
}
@media only screen and (min-width: 700px) {
  .contact-us-container {
    width: 40%;
  }
  .contact-us-main_container {
    display: flex;
    justify-content: center;
    height: 900px;
  }
}

.content-main-container {
  position: absolute;
  top: 0;
}
@media only screen and (max-width: 550px) {
  .content-main-container {
    position: absolute;
    width: 100%;
    top: 0;
  }
  /* .tabs-bottom-color{
    background-color: #e5e5e5;
    position: absolute;
    width: 100%;
    min-height: 33%;
    margin-top: -2px;
  } */
}

.main-container {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B;
  position: absolute;
  min-height: 100%;
  height: auto;
  width: 100%;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  /* overflow-y: scroll; */
  overflow: hidden;
}

/* @media  only screen and (max-width:550px){
  .main-container{
    overflow: hidden;
  }
} */

.modals-main-container {
  background-color: #ffffff;
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

@media only screen and (max-width: 550px) {
  .modals-main-container {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    width: 100%;
    min-height: 27.5%;
  }
}
@media only screen and (max-width: 375px) {
  .modals-main-container {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: absolute;
    width: 100%;
    height: 241.5px;
  }
}


.white-border-bottom_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
/* .white-border-bottom {
  border-bottom: 2px solid white;
  width: 80%;
  margin-top: 20px;
} */

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: unset !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

.ant-tabs-tabpane {
  overflow-y: scroll !important;
  height: 460px;
  overflow: hidden;
}
@media only screen and (max-width:450px){
  .ant-tabs-tabpane {
    height: 500px;
    overflow: hidden;
    overflow-y: scroll !important;
  }
}
.after-reviewing-main_container {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B;
  position: absolute;
  min-height: 100%;
  height: auto;
  width: 100%;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.after-reviewing-white_container {
  background-color: #fff;
  /* border-radius: 20px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 10px;
  height: auto;
  padding: 10px;

}

.after-reviewing-header-bottom_para {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #77A1DF;
}

.after-reviewing-wrap_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.after-reviewing-bottom_border {
  border-bottom: 1px solid #cccccc;
  width: 80%;
  margin-top: 30px;
}

.border-align-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-of-door_container {
  background: #FAFAFA;
  width: 98%;
  border-radius: 8px;
  padding: 10px;
  margin-top: -4px;
  margin-left: 4px;
}

.no-of-door_text {
  display: flex;
  justify-content: space-between;
  color: #414B55;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
}

.text-area-container {
  width: 98%;
  border: none;
  background: #FAFAFA;
  border-radius: 8px;
  margin-top: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-left: 4px;
}

.text-area-container:focus {
  outline: none;
  border: none;
}

.final-status-container {
  background: #F3F3F3;
  width: 98%;
  border-radius: 8px;
  padding: 10px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 4px;
}

.pass-container {
  display: flex;
  align-items: center;
}

.camera_img_container {
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;
  left: 0;
}

.img_container {
  height: 200px;
  width: 300px
}

.produc-card-bg_file {
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  height: 200px;

  position: relative;
}

.checked-icon-container {
  background-color: #75BA5C;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-right: 10px;
}

.Unchecked-icon-container {
  background-color: #d42316;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-right: 10px;
}

.pass-text {
  color: #414B55;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.main-container-width {
  width: 370px;
  align-self: center;
}

@media only screen and (max-width: 550px) {
  .main-container-width {
    width: 100% !important;
    align-self: center !important;
  }
}

.instruction-modalls .after-review-in-screen .ant-modal-close {
  right: -15% !important;
  top: -25% !important;
}

@media only screen and (max-width: 600px) {
  .instruction-modalls .after-review-in-screen .ant-modal-close {
    right: -8% !important;
  }

  .produc-card-bg_file {
    background-repeat: no-repeat;
    background-size: contain;
    /* width: 100%;
    height: 100%; */
    margin-top: 20px;
    position: relative;
  }

  .camera_img_container {
    margin-left: 30px;
  }
}

.ant-modal-close-x {

  margin-right: 23px;
}

.vechicle-detail-head-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-left: 8px;
  margin-top: 3px;
}

.after-review-number {
  font-weight: bold;
}
.inspectionInstructionHeading {
  font-size: 17px;
  font-weight: 900;
  padding: 10px 10px 10px 7px;
  background-color: #F0F2F6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  color: #000000;
  font-weight: 600;
}
.inspectionInstructionContentContainer {
  display: flex;
  padding-top: 10px;
  background-color: white;
  border-radius: 10px;
  height: 77vh;
}
.inspectionInstructionContent {
  font-size: 14px;
  color: #000000;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
  margin-top: 8px;
}
.carinspectionInstructionImg {
  width: 65px;
  height: 510px;
}
.inspectionInstructionContentHeading {
  font-size: 16px;
  color: #0B5EAF;
  font-weight: bolder;
  margin-top: 8px;
  font-family: 'Poppins', sans-serif;
}
.inspectionInstructionContentStyling {
  margin-top: 15px;
}
.inspectionInstructionContentStylings {
  margin-top: 50px;
}
.inspectionInstructionContentText {
  font-size: 14px;
  color: #414b55;
  font-weight: 500;
}
.inpection-instruction-btn-container {
  position: fixed;
  bottom: 10px;
  width: 29.899%;
}
@media only screen and (max-width: 1024px) {
  .inpection-instruction-btn-container {
    width: 60%;
  }
}
@media only screen and (max-width: 768px) {
  .inpection-instruction-btn-container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .inpection-instruction-btn-container {
    width: 100%;
    background-color: white;
    /* padding: 20px; */
    height: 15%;
    margin-bottom: -20px;
  }
}
.companyInstructionHeading {
  font-size: 17px;
  font-weight: 900;
  padding: 10px 10px 10px 20px;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
}

.inpection-instruction-btn-container {
  position: fixed;
  bottom: 10px;
  width: 29.899%;
}

.select-company-inspection-price {
  color: #ff9900;
  text-align: center;
  padding: 4px;
  background-color: #fff5e5;
  padding-left: 30px;
  padding-right: 30px;
}

.select-company-heading {
  font-size: 17px;
  font-weight: 900;
  /* border-bottom: 1px solid #cfdeed;   */
  padding: 15px;
}

.select-company-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #fbfbfb; */
  padding: 20px;
  border-top: 1px solid #cfdeed;
  margin-bottom: 5px !important;
  /* border-bottom: 1px solid #cfdeed; */
}

.company-name-text {
  color: #0b5eaf;
  font-size: 16px;
  font-weight: 700;
}

.inpection-instruction-btn-container {
  position: fixed;
  bottom: 0px;
  width: 29.899%;
}

@media only screen and (max-width: 1024px) {
  .inpection-instruction-btn-container {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .inpection-instruction-btn-container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .inpection-instruction-btn-container {
    width: 100%;
    background-color: white;
    /* padding: 20px; */
    height: 120px;
    margin-bottom: -50px;
  }

  .select-company-checkbox-container {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.select-company-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.select-company-header-content {
  width: 32%;
}

@media only screen and (max-width:1024px) {
  .select-company-header-content {
    width: 60%;
  }
}

@media only screen and (max-width:768px) {
  .select-company-header-content {
    width: 93%;
  }
}

@media only screen and (max-width:600px) {
  .select-company-header-content {
    width: 100%;
  }
}

.eRLuLF {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B !important;
}

.IPtEP {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B !important;
}

.license-plate {
  margin-left: 25px;
  width: 90%;
  /* margin-top: 2px; */
  margin-bottom: 10px;
}

.license-plate-text {
  height: 20px;
  padding-top: 20px;
  background-color: #f1f1f1;
}

.license-plate-text::-webkit-input-placeholder {
  color: #9D9D9D !important;
  font-weight: 900;
  font-size: 18px;
  font-family: 'Poppins';
}

.license-plate-text:-ms-input-placeholder {
  color: #9D9D9D !important;
  font-weight: 900;
  font-size: 18px;
  font-family: 'Poppins';
}

.license-plate-text::placeholder {
  color: #9D9D9D !important;
  font-weight: 900;
  font-size: 18px;
  font-family: 'Poppins';
}

.license-plate-error-text {
  height: 20px;
  padding-top: 20px;
  background-color: #f1f1f1;
}

.license-plate-error-text::-webkit-input-placeholder {
  color: red !important;
  font-weight: 900;
  font-size: 18px;
  font-family: 'Poppins';
}

.license-plate-error-text:-ms-input-placeholder {
  color: red !important;
  font-weight: 900;
  font-size: 18px;
  font-family: 'Poppins';
}

.license-plate-error-text::placeholder {
  color: red !important;
  font-weight: 900;
  font-size: 18px;
  font-family: 'Poppins';
}

@media only screen and (min-width:1800px) {
  .license-plate {
    margin-left: 35px !important;
  }
}

@media only screen and (max-width:1440px) {
  .license-plate {
    margin-left: 18px !important;
  }
}

@media only screen and (max-width:1024px) {
  .license-plate {
    margin-left: 27px !important;
  }
}

@media only screen and (max-width:468px) {
  .license-plate {
    margin-left: 20px !important;
  }
}

.oXDdG {
  height: 100vh !important;
}

.OGHxE {
  height: 100vh !important;
}

.drop-down.ant-select {
  width: 90%;
  height: 35px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 3px;
}

.drop-down.ant-select:hover {
  color: grey;
  background: #f1f1f1;
  border-color: #f1f1f1;
  background-color: #f1f1f1;
}

.drop-down.ant-select:focus {
  color: grey;
  background: #fff;
  border-color: #f1f1f1;
  background-color: #f1f1f1;
}

.drop-down-container {
  align-self: center;
  display: flex;
  justify-content: center;
}

.select-state-heading {
  margin-left: 14px;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 3px;
}

.select-company-checks_container {
  height: calc(107vh - 530px);
  overflow-y: scroll;
  box-sizing: border-box;
  /* margin-bottom: 60; */
}


@media only screen and (min-width:1500px) {
  .select-company-checks_container {
    height: 400px;
    overflow-y: hidden;
  }
}
.select-option-main-container {
  width: 100%;
  position: absolute;
  background: url(/static/media/bg_img.2e602dac.png);
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: scroll;
}

.select-option-image-background-signin {
  position: relative;
  width: 100%;

  /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; */
}

.select-option-main-bg-signin {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 0;
}

.select-option-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2000;
}

a {
  color: #fff;
}

.select-option-heading-logo {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 30px 0 0 0;
}

.logo-span-color {
  color: #ff9900;
}

.select-option-desp-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.155em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0px;
  margin-bottom: 10px !important;
}

.select-option-form-main-container {
  text-align: center;
  width: 100%;
  margin: 30px auto;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.forgot-password-link {
  color: white;
  font-size: 15px;
  margin-bottom: 18px;
  ;
}

.select-option-form-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 12px;
  margin-top: 10px;

}


.select-option-intro-validation-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 0px;
  letter-spacing: 0.155em;
  color: #DADADA;

}

@media screen and (min-width: 768px) {
  .select-option-image-background-signin {
    background-size: cover;
    background-position: center center;
  }
}

.select-option-tabs {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 50px;
  border: 2px solid white;
  /* padding: 10px 60px; */
  width: 271px;
  height: 51px;
  text-align: center;
  cursor: pointer;
}

.select-option-tabs:hover {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 50px;
  border: 2px solid #FF7A00;
  width: 271px;
  height: 51px;
  text-align: center;
  background-color: #FF7A00;
  border-radius: 5px;
}

.select-option-tabs:focus {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 45px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 50px;
  border: 2px solid #FF7A00;
  width: 271px;
  height: 51px;
  text-align: center;
  background-color: #FF7A00;
  border-radius: 5px;
}

/* @media only screen and (max-width: 600px) {
    .ant-form-item-control-input {
      margin: auto !important;
      width: 500px !important;
    }
  }
  @media only screen and (max-width: 550px) {
    .ant-form-item-control-input {
      margin: none !important;
      width: 100% !important;
    }
  } */


.select-option-header {
  display: flex;
  justify-content: flex-start;
  width: 370px;
}

.select-option-header_container {
  align-self: center;
  display: flex;
  justify-content: center;
}
.inspection-conatiner {
  background: #fff;
  width: 375px;
  height: calc(100vh - 66px);
  background-size: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden !important;

}


/* @media only screen and (max-width:1440px){
  .inspection-conatiner {
   height: calc(100vh - 66px);
  }
} */
/* @media only screen and (max-width:768px){
  .inspection-conatiner {
   height: 536px;
  }
} */

@media screen and (max-width: 450px) {
  .inspection-conatiner {
    width: 100%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.inspections-in-progress-text {
  padding: 15px;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 27px;
  letter-spacing: 0em;
}

.inspection-tag {
  color: #ff9900;
  text-align: center;
  padding: 4px;
  background-color: #fff5e5;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
  width: 99.5%;

}

.inspection-button {

  margin-top: 10px;
  padding: 0px 30px;
  width: 160px;
  height: 39px;
  background: #1468BA;
  border-radius: 5px;
  border: 1px solid #1468BA;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
}

.inspection-main-container {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B;
  position: absolute;
  min-height: 100%;
  height: auto;
  width: 100%;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  overflow: hidden !important;
}

@media only screen and (max-width:450px) {
  .inspection-main-container {
    display: block;
  }
}

.start-inspection-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  width: 200px;
  cursor: pointer;
  /* position: fixed; */
  /* bottom: 45px; */
  align-self: center;
  margin-top: 20px;
}

.inspection-bottom-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0px;
}

@media only screen and (min-width:500px) {
  .inspection-bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: unset;
  }
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.all-tiles {

  overflow-y: scroll;
  height: calc(100vh - 385px);
}

.inspection-progress-all-tiles {
  overflow-y: scroll;
  height: calc(100vh - 370px);
}

.certificate-all-tiles {
  overflow-y: scroll;
  height: calc(100vh - 400px);
}
.submitted-conatiner {
  background: #fff;
  width: 375px;
  height: calc(100vh - 79px);
  background-size: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}


@media screen and (max-width: 450px) {
  .submitted-conatiner {
    width: 100%;
  }
}

/*
  @media screen and (max-width: 385px) {
    .submitted-conatiner {
      height: 645px;
    
     
    }
  } */


.submitteds-in-progress-text {
  padding: 15px;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}

.submitted-tag {
  color: #ff9900;
  text-align: center;
  padding: 4px 30px;
  background-color: #fff5e5;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
  width: 99.5%;

}


.submitted-button {

  margin-top: 10px;
  padding: 0px 30px;
  width: 160px;
  height: 39px;
  background: #1468BA;
  border-radius: 5px;
  border: 1px solid #1468BA;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
}

.submitted-main-container {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B;
  position: absolute;
  min-height: 100%;
  height: auto;
  width: 100%;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  overflow: hidden !important;
}

@media only screen and (max-width:450px) {
  .submitted-main-container {
    display: block;
  }
}


.submitted-bottom-content {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.arrow-icon-container-submitted {
  border: 2px solid #FF7A00;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  top: 25px;
  right: 30px;
}

.submitted-tile-tag {
  border: 1px solid #20C18D;
  background-color: #20C18D;
  background: cover;
  /* padding: 0px 20px; */
  position: absolute;
  right: 0;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #fff;
  width: 69px;
  height: 15px;
  text-align: center;
  font-size: 10px;
  margin-right: -1px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 35px 2px !important;
  border-bottom: none !important;

}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  display: inline-block !important;
}

.submitted-tile-tag-in-review {
  border: 1px solid #1890ff;
  background-color: #1890ff;
  background: cover;
  /* padding: 0px 20px; */
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #fff;
  width: 74px;
  height: 15px;
  text-align: center;
  font-size: 10px;
  margin-right: -1px;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none !important;
}

.check-inspection-text {
  width: 70%;
  text-align: center;
  color: #1467B8;
  font-size: 15px;
}
.certificates-conatiner {
  background: #fff;
  width: 100%;
  height: calc(100vh - 66px);
  background-size: cover;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  overflow: hidden !important;


}

/* @media only screen and  (min-width:1100px){
  .certificates-conatiner{
  height: 100%;
  }
  } */

.certificates-in-progress-text {
  padding: 15px;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
}

.certificates-tag {
  color: #ff9900;
  text-align: center;
  padding: 4px;
  background-color: #fff5e5;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
  width: 99.5%;

}

.certificates-main-container {
  background: linear-gradient(182.55deg, #1876CC -17.67%, #001B51 115.19%), #191A1B;
  position: absolute;
  min-height: 100%;
  height: auto;
  width: 100%;
  border: none;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

@media only screen and (max-width:450px) {
  .certificates-main-container {
    display: block;
  }
}

.back-text {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  /* position: fixed; */
  /* bottom: -2px; */
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #1468BA;
  /* margin-bottom: 13px; */

}



.progress-close-icon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.home-text {
  margin-left: 3px;
  margin-right: 3px;
  -webkit-font-smoothing: antialiased;
}
.intro-mainBgss {
    background: linear-gradient(182.55deg, rgba(24, 118, 204, 0.1), rgba(0, 27, 81, 1) 115.19%), rgba(25, 26, 27, 0.1);
    position: relative;
    width: 100%;
    height: 100%;
}
.home-main-Container{
    width: 100%;
    /*background-color:  #F4F9FD;*/
    background-color: #FFFFFF;
}
.badge-container_home{
    width: 100%;
    height: 650px;
    background-color: #2a2a2a;
    /* background-image: url("https://res.cloudinary.com/techling/image/upload/v1640334033/uberBanner_zvx139.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom:80px;
}
.badge-image-inner-container{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    /*background-color: #8c9dae;*/
}
.badge-inner-left-home{
    width: 45%;

    height: 50%;
}
.badge-main-heading{
    font-family: Poppins;
    font-size: 44px;
    line-height: 53px;
    text-align: left;
    font-weight: bolder;
    color: #FFFFFF;
}
.badge-main-para_homeContent{
    font-family: Poppins;
    font-size: 20px;
    padding-right: 40px;
    /*line-height: 43px;*/
    text-align: left;
    color: #C4C4C4;
}
.contact-submit-btn{
    background: #2584e0 !important;
    border: none !important;
    outline: none !important;
    width: 30% !important;
    font-style: normal;
    font-size: 16px;
    float: left;
}
.badge-inner-right{
    width: 60%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.badge-inner-right > img{

    height: 400px;
    width: 500px;
}
.overlay-card-container{
    width: 100%;
    margin-top: -150px;
}
.overlay-card-inner-container{
    width: 80%;
    margin: 0 auto;
    /*background-color: #F4F9FD;*/
}

@media screen and (max-width: 600px) {
    .overlay-card-inner-container{

        width:100%;
        padding:10px;
        /*margin-left:5px;*/

    }

}

.images_about{
    width:100px;
    height: 80px;
}

@media screen and (max-width: 912px){
    .images_about{
        width:80px;
        height: 70px;
    }
    .badge-main-para_homeContent{
        font-family: Poppins;
        font-size: 16px;
        padding-right: 10px;
        /*line-height: 43px;*/
        text-align: left;
        color: #C4C4C4;
    }
}

@media screen and (max-width: 821px){
    .images_about{
        width:50px;
        height: 60px;
    }
    .badge-main-heading{
        font-size: 24px!important;
        line-height:30px !important;
    }
    .badge-main-para_homeContent{
        font-family: Poppins;
        font-size: 14px;
        padding-right: 20px;
        /*line-height: 43px;*/
        text-align: left;
        color: #C4C4C4;
    }
}
@media screen and (max-width: 767px){
    .images_about{
        width:130px;
        height: 120px;
    }
    .badge-main-para_homeContent{
        font-family: Poppins;
        font-size: 14px;
        padding-right: 0px;
        text-align: center;
        /*line-height: 43px;*/

        color: #C4C4C4;
    }

}
@media screen and (max-width: 445px){
    .images_about{
        width:100px;
        height: 80px;
    }
}
@media screen and (max-width: 392px){
    .images_about{
        width:80px;
        height: 70px;
    }
}
/*@media screen and (max-width: 767px){*/
/*    .badge-inner-left-home{*/
/*        margin-top:-100px;*/
/*        width: 10%;*/
/*        !*height: 50%;*!*/
/*    }*/

/*}*/

@media screen and (max-width: 767px){
    .badge-container_home{
        width: 100%;
        height: 400px;
        /* background-image: url("https://res.cloudinary.com/techling/image/upload/v1640334033/uberBanner_zvx139.png"); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom:40px;
        background-color: black;
        display: block;
    }
    /*.badge-inner-left{*/
    /*    width: 100%;*/
    /*    !*height: 50%;*!*/
    /*}*/
}

@media screen and (max-width: 575px) {
    .badge-container_home {
        padding-left: 30px;
        padding-right: 30px;
    }

}
.chexWorks-main-container{
    width: 100%;
    padding-bottom: 80px;
}
.chexwork-inner-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
}
.chaxwork-heading_home{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
}
.chaxwork-heading-span_home{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF7A00;
}
@media screen and (max-width: 420px) {
    .chaxwork-heading_home{
        font-family: Poppins;
        font-size: 30px!important;
        font-style: normal;
        font-weight: 600;
        line-height: 81px;
        letter-spacing: 0em;
        text-align: center;
    }
    .chaxwork-heading-span_home{
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 81px;
        letter-spacing: 0em;
        text-align: center;
        color: #FF7A00;
    }
}


@media screen and (max-width: 312px) {

    .badge-main-para_homeContent{

        font-size: 12px;
        padding-right: 0px;
        text-align: center;
    }
    .chaxwork-heading_home{
        font-family: Poppins;
        font-size: 22px!important;
        font-style: normal;
        font-weight: 600;
        line-height: 81px;
        letter-spacing: 0em;
        text-align: center;
    }
    .chaxwork-heading-span_home{
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 81px;
        letter-spacing: 0em;
        text-align: center;
        color: #FF7A00;
    }
}

.chexworks-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
}
.chexwork-img{
    height: 250px;
    width: 250px;
}
.chexwork-image-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-style: normal;
    line-height: 115.5%;
    text-align: center;
    margin-top: 10px;
    max-width: 250px;
}
.chexwork-image-desc{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-top: 10px;
    color: #6B717B;
    text-align: center;
    max-width: 250px;
}
.chexwork-image-getStarted-span{
    color: #FF7A00;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}
.chexwork-image-payment-span{
    color: #6B717B;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}
.chex-demo-video-main-contaier{
    width: 100%;
    background-color: #FFFFFF;
}
.chex-demo-video-container{
    width: 80%;
    height: 530px;
    background: url(/static/media/videoBg.d6ace65d.png);
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.chex-demo-video{
    border-radius: 3% ;
    height: 380px;
    width: 674px;
    border: none;
    z-index: 1;
}
.about-chex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
}
.about-chex-left{
    width: 50%;
    padding-top: 50px;
    padding-bottom: 50px;
}
.chex-about-left-inner{
    width: 85%;
    float: right;
}
.about-chex-left-title{
    font-family: Poppins, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: bolder;
    line-height: 44px;
    letter-spacing: 1px;
}
.about-chex-left-title-span{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #FF7A00;
}
.about-chex-container .about-chex-left .chex-about-left-inner > p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 160%;
    color: #6B717B;
    letter-spacing: 1px;
    margin-bottom: 5%;
}
.about-chex-inner-left-desc-span{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: #FF7A00;
}
.about-chex-right{
    width: 50%;
    background-color: #FFF8F1;
    height: 400px;
    margin-top: -200px;
    padding-top: 13%;
}
.about-chex-right-inner{
    width: 80%;
    background-color: aqua;
    height: 300px;
    margin: 0 auto;
}
.about-chex-right > h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #151922;
    margin-left: 20px;
}
.about-pricing-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}
.arrow-container{
    background-color: aqua;
    margin-top: -5%;
}
.review_container_main{
    width: 100%;

    background-color: #FFFFFF;
}
.main_section_review{
   background-color: #FFFFFF;
    margin-bottom: 30px;

    position: relative;
    margin-top: 30px;

}

@media screen and  (max-width: 767px){
    .main_section_review{
        margin-bottom: 0px;
    }

}
.review_container_main{
    width: 100%;
    background-color: #FFFFFF;

    padding-top: 0px;
    margin-bottom: 30px;
}
.rec.rec-arrow-right {
    background-color: #F4F9FF;
    border-radius: 30px;
    position: absolute;
    right:6%;
    bottom:-10%;

}
.rec.rec-arrow:hover{
    background-color: #F4F9FF!important;
    color:black!important;
}
.rec.rec-arrow:focus{
    background-color: #F4F9FF!important;
    color:black!important;
}

.rec.rec-arrow:hover{
    background-color: #F4F9FF;
}
.rec.rec-arrow-left{

    background-color:#F4F9FF;
    border-radius: 30px;
    z-index: 1;
    bottom:-10%;
    position: absolute;

    right: 10%;
}


@media only screen and (max-width: 1400px) {
    .badge-inner-left-home{
        width: 40%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color:green;*/
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 40px;
        line-height: 53px;
        text-align: left;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 35% !important;
        font-style: normal;
        font-size: 16px;
        float: left;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 80%;
        display: flex;
    }
    .badge-inner-right > img{
        height: 350px;
        width: 450px;
    }


}

@media only screen and (max-width: 1200px) {
    .badge-inner-left-home{
        width: 40%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color:red;*/
    }
    .badge-inner-left-home{
        width: 45%;
        height: 50%;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 36px;
        line-height: 53px;
        text-align: left;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 40% !important;
        font-style: normal;
        font-size: 16px;
        float: left;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 70%;
        display: flex;

    }
    .badge-inner-right > img{
        height: 350px;
        width: 450px;
    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 80px;
    }
    .chexwork-img{
        height: 200px;
        width: 200px;
    }
    .chexwork-image-title{
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 115.5%;
        text-align: center;
        margin-top: 10px;
        max-width: 200px;
    }
    .chexwork-image-desc{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-top: 10px;
        color: #6B717B;
        text-align: center;
        max-width: 200px;
    }
    .rec.rec-arrow-right {


        right:10%;


    }
    .rec.rec-arrow-left{

        right: 17%;

    }
}
@media screen and (max-width: 500px){
    .carousel_div_main {
        width: 100% !important;
        margin-top: 10px;
    }
}
@media screen and (max-width: 413px){
    .carousel_div_main {
        width: 70% !important;
        margin-top: 10px;
    }
}
@media screen and (max-width: 1024px) {
    .badge-inner-left-home{
        width: 40%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto;
        /*background-color:blue;*/
    }
    .badge-inner-left-home{
        width: 50%;
        height: 50%;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 27px;
        line-height: 53px;
        text-align: left;
        font-weight: bolder;
        color: #FFFFFF;
    }
    /*.contact-submit-btn {*/
    /*    background: #2584e0 !important;*/
    /*    border: none !important;*/
    /*    outline: none !important;*/
    /*    width: 40% !important;*/
    /*    font-style: normal;*/
    /*    font-size: 16px;*/
    /*    float: left;*/
    /*}*/
    .badge-inner-right{
        max-width: 50%;
        height: 70%;
        display: flex;

    }
    .badge-inner-right > img{
        height: 300px;
        width: 400px;
    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 50px;
    }
    .chexwork-img{
        height: 350px;
        width: 320px;
    }
    .chexwork-image-title{
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 115.5%;
        margin-top: 10px;
        max-width: 320px;
        margin-left: 10px;
    }
    .chexwork-image-desc{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-top: 10px;
        color: #6B717B;
        width: 100%;
        max-width: 320px;
    }
    .chex-demo-video{
        border-radius: 3% ;
        height: auto;
        width: 100%;
        /*border: 1px solid red;*/
    }
    /*.rec.rec-arrow-right {*/
    /*    margin-top: 400px;*/
    /*    background-color: #F4F9FF;*/
    /*    border-radius: 30px;*/
    /*    position: absolute;*/
    /*    right:11%;*/
    /*    !*margin-right: 30px;*!*/

    /*}*/
    /*.rec.rec-arrow-left{*/
    /*    margin-top: 400px;*/
    /*    background-color:#F4F9FF;*/
    /*    border-radius: 30px;*/
    /*    position: absolute;*/
    /*    left: 75.5%;*/
    /*}*/
}
@media only screen and (max-width: 900px) {
    .badge-inner-left-home{
        width: 40%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto !important;
        display: flex;
        align-items: center;
        /*background-color:palevioletred;*/
    }

    .badge-main-heading{
        font-family: Poppins;
        font-size: 24px!important;
        line-height: 43px !important;
        text-align: left;
        font-weight: bolder;
        color: #FFFFFF;
    }

    .badge-inner-right{
        max-width: 100%;
        height: 100%;

    }
    .badge-inner-right > img{
        height: 300px;
        width: 350px;

    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 60px;
    }

}
@media only screen and (max-width: 767px) {
    .badge-inner-left-home{
        margin-top: 0px;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color:bisque;*/
    }
    .badge-inner-left-home{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 28px;
        line-height: 53px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 40% !important;
        font-style: normal;
        font-size: 16px;
        float: left;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 80%;
        display: none;
    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 60px;
    }
    .chex-demo-video{
        border-radius: 3% ;

        width: 100%;
        /*border: 1px solid green;*/
    }
    .rec.rec-arrow-right {
        z-index: 1;
        right:10% ;


    }
    .rec.rec-arrow-left{
        right: 20%;

    }
}
@media only screen and (max-width: 600px) {
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /*background-color: #8c9dae;*/
    }
    .badge-inner-left-home{
        margin-top: 10px;
        width: 100%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /*background-color: #2a2a2a;*/
    }
    .badge-inner-left-home{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
       align-items: center;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 35px;
        line-height: 53px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 40% !important;
        font-style: normal;
        font-size: 16px;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 80%;
        display: none;
    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 80px;
        display: flex;
    }
    .chexworks-column{
        text-align: center;
    }
    .chexwork-img{
        height: 270px;
        width: 270px;
    }
    .chexwork-image-title{
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 115.5%;
        margin-top: 20px;
        max-width: 270px;
        text-align: center;
    }
    .chexwork-image-desc{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-top: 10px;
        color: #6B717B;
        width: 100%;
        max-width: 270px;
        text-align: center;
    }
    .chex-demo-video-container{
        width: 100%;
        height: 450px;
        /*background: url("../../Assets/videoBg.png");*/
        /*background-repeat: no-repeat;*/
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .chex-demo-video{
        border-radius: 3% ;
      width: 90%;
        border: 1px solid blue;
    }
    .rec.rec-arrow-right {
        z-index: 1;

        right:10%;

    }
    .rec.rec-arrow-left{
       right: 21%;
    }
}
@media only screen and (max-width: 550px){
    .chexwork-img{
        height: 270px;
        width: 270px;
    }
    .chexwork-image-title{
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 115.5%;
        margin-top: 10px;
        max-width: 270px;
    }
    .chexwork-image-desc{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-top: 10px;
        color: #6B717B;
        max-width: 270px;
    }
    .chex-demo-video{
        border-radius: 3% ;
       width: 80%;
        border: 1px solid transparent;
    }
}
@media only screen and (max-width: 480px) {
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /*background-color: #8c9dae;*/
    }
    .badge-inner-left-home{
        width: 100%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /*background-color: peru;*/
    }
    .badge-inner-left-home{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 30px;
        line-height: 53px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 60% !important;
        font-style: normal;
        font-size: 16px;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 80%;
        display: none;
    }
    .chexWorks-main-container{
        width: 100%;
        /*background-color: #F4F9FD;*/
        padding-bottom: 80px;
    }
    .chex-demo-video-container{
        width: 100%;
        height: 300px;
        /*background: url("../../Assets/videoBg.png");*/

    }
    .chex-demo-video{
        border-radius: 15px ;

        width: 90%;
        /*border: 1px solid red;*/
        margin-left: -5px;
    }

    .review_container_main{
        width: 100%;

        background-color: #FFFFFF;
        padding-top: 30px;
    }


    .rec.rec-arrow-right {

        right:10%;


    }
    .rec.rec-arrow-left{

        right: 25%;
    }
}


@media  screen and (max-width: 400px){

    .badge-main-heading{
        font-family: Poppins;
        font-size: 18px!important;
        line-height: 26px!important;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
}


@media  screen and (max-width: 360px){
    .rec.rec-arrow-right {

        right:10%;

    }
    .rec.rec-arrow-left{
      right: 28%;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 16px!important;

    }
}


@media  screen and (max-width: 330px){
    .rec.rec-arrow-right {

        right:10%;

    }
    .rec.rec-arrow-left{
        right: 30%;
    }
}


/*pricing card styling*/
.priceCardContainer_about{
    background-color:white;
    border: 15px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 10px;

    /*width: 300px;*/
    /*height: 200px;*/
    width:100%;
    height:240px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;

    /*text-align: center;*/


    /*margin:100px;*/
}
.pricetagBg_about {
    background-color: #FF7A00;
    border-radius: 5px;
    position: relative;
    left: -40px;
    margin-top: -60px;
    /*top:0px;*/
    /*left:0px;*/
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 35px;

}

.pricecardHeading_about{
    color: #FF7A00;
    font-family:Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 75px;
    line-height: 28px;
    margin-top: 20px;
    padding-top: 35px;

}
.pricecardParagraph_about{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    padding-right: 10px;
    padding-left:10px;
    word-spacing: 2px;
    letter-spacing: 2px;

    color: #5D6F84;
}

.pricetagBg_aboutPriceTagClr{
    /*background: #FF7A00;*/
    border-radius: 5px;
    position: relative;
    left:-40px;
    margin-top:-60px;
    /*top:0px;*/
    /*left:0px;*/
    text-align: center;
    padding-left:20px;
    padding-right: 20px;
    background-color: #2584E0!important;
    height: 35px;
}
/*.PriceTagClr{*/
/*    background-color: #2584E0!important;*/
/*}*/

.pricetagText_about{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /*line-height: 14px;*/
    /* identical to box height */
    /*background-color: aqua;*/
    padding-top: 5px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    /*word-spacing: 1px;*/
    letter-spacing: 2px;

    color: #FFFFFF;
}
@media screen and (max-width: 1547px) {

    .pricetagBg_about {
        background-color: #FF7A00;
        border-radius: 5px;
        position: relative;
        left: -30px;
        margin-top: -30px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        height: 35px;

    }
    .pricetagBg_aboutPriceTagClr{
        /*background: #FF7A00;*/
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-30px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;
        padding-left:20px;
        padding-right: 20px;
        background-color: #2584E0!important;
        height: 35px;
    }
    .pricecardHeading_about{
        color: #FF7A00;
        font-family:Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 65px;
        line-height: 28px;
        margin-top: 20px;
        padding-top: 20px;

    }
    .pricecardParagraph_about{
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        padding-right: 10px;
        padding-left:10px;
        word-spacing: 2px;
        letter-spacing: 2px;

        color: #5D6F84;
    }

}

@media screen and (max-width: 1200px) {

    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-110px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-90px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagText_about{
        font-size: 15px!important;
    }

}


@media screen and (max-width: 1100px){
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-90px;
        margin-top:-90px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-80px;
        margin-top:-90px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }

    .pricecardHeading_about{

        font-size: 40px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;


        color: #5D6F84;
    }

}

@media screen and (max-width:935px){
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-60px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-50px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagText_about{
        font-size: 16px!important;
    }
    .pricecardHeading_about{

        font-size: 45px;
        line-height: 25px;
        margin-top: 35px;
    }
    .pricecardParagraph_about{

        font-size: 20px;
        padding-right: 3px;
        padding-left:3px;

        color: #5D6F84;
    }
    .pricetagText_about{
        font-size: 12px;
    }

}
@media screen and (max-width:890px){
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-40px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }


}

@media screen and (max-width: 767px) {
    .priceCardContainer_about{
        width: 300px;
        margin-left: 180px;
    }

    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-10px;
        margin-top:-50px;

        /*top:0px;*/
        /*left:0px;*/

        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-10px;
        margin-top:-60px;

        /*top:0px;*/
        /*left:0px;*/

        text-align: center;

        padding-left:10px;
        padding-right: 10px;
    }
    .pricecardHeading_about{

        font-size: 40px;
        line-height: 25px;
        margin-top: 25px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
    .pricetagText_about{
        font-size: 14px;
    }
}

@media screen and (max-width: 620px) {
    .priceCardContainer_about{
        width: 400px;
        margin-left: 80px;
    }
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-50px;
        margin-top:-90px;


    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-50px;
        margin-top:-80px;

    }
}


@media screen and (max-width: 550px) {
    .priceCardContainer_about{
        width: 400px;
        margin-left: 40px;
    }
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-60px;
        margin-top:-90px;

    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-60px;

        margin-top:-90px;

    }

    .pricecardHeading_about{

        font-size: 40px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 22px;

    }
    .pricetagText_about{
        font-size: 20px;
    }

}
@media screen and (max-width: 500px) {
    .priceCardContainer_about {
        width: 350px;
        margin-left: 40px;
    }
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-40px;
        margin-top:-90px;

    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-90px;

        padding-left:20px;
        padding-right: 20px;}
    .pricecardHeading_about{

        font-size: 50px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
}

@media screen and (max-width: 460px) {
    .priceCardContainer_about {
        width: 350px;
        margin-left: 20px;
    }
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-40px;
        margin-top:-90px;

    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-90px;

        padding-left:20px;
        padding-right: 20px;}
    .pricecardHeading_about{

        font-size: 50px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
}
@media screen and (max-width: 412px) {
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-90px;

    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-20px;
        margin-top:-90px;

    }
    .priceCardContainer_about {
        width: 100%;
        margin-left: 0px;
    }
    .pricecardHeading_about{

        font-size: 50px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
    /*.pricetagBg_about PriceTagClr{*/
    /*    background: #FF7A00;*/
    /*    border-radius: 5px;*/
    /*    position: relative;*/
    /*    left:-60px;*/
    /*    margin-top:-70px;*/
    /*    !*top:0px;*!*/
    /*    !*left:0px;*!*/
    /*    text-align: center;*/

    /*    padding-left:10px;*/
    /*    padding-right: 10px;*/
    /*}*/
}
@media screen and (max-width: 372px) {
    .pricetagBg_about {
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left: -20px;
        margin-top: -60px;

    }
    .pricetagBg_aboutPriceTagClr {
        background-color: #2584E0 !important;
        border-radius: 5px;
        position: relative;
        left: -10px;
        margin-top: -60px;

    }
    .pricetagText_about{
        font-size: 14px!important;
    }
    .pricecardHeading_about{

        font-size: 50px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
}

@media screen and (max-width: 340px) {
    .pricetagBg_about {
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left: -20px;
        margin-top: -60px;

    }
    .pricetagBg_aboutPriceTagClr {
        background-color: #2584E0 !important;
        border-radius: 5px;
        position: relative;
        left: -0px;
        margin-top: -50px;

    }
    .pricetagText_about{
        font-size: 14px!important;
    }
    .pricecardHeading_about{

        font-size: 48px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 18px;

    }
}

/*pricing card styling end*/


/*.about_section_main{*/
/*    background-color: #FFFFFF;*/

/*    margin-bottom: 20px;*/

/*}*/
/*.about_desc_div{*/
/*    padding:60px;*/
/*    padding-bottom: 30px;*/
/*    width: 100%;*/
/*}*/
/*.paraAbout{*/
/*    font-size: 18px;*/
/*}*/
/*@media screen and (max-width:480px ){*/
/*    .about_desc_div{*/
/*        padding:30px;*/
/*        padding-bottom: 30px;*/
/*        width: 100%;*/
/*    }*/
/*}*/
/*@media screen and (max-width:331px ){*/
/*    .about_desc_div{*/
/*        padding:20px;*/
/*        padding-bottom: 20px;*/
/*        width: 100%;*/
/*    }*/
/*    .about-chex-left-title{*/
/*        font-size: 24px!important;*/
/*    }*/
/*    .about-chex-left-title-span{*/
/*        font-size: 24px!important;*/
/*    }*/
/*    .paraAbout{*/
/*        font-size: 16px;*/
/*    }*/
/*}*/
/*.slider_div_main{*/
/*    background-color: #F4F9FD;*/
/*    height: 150px;*/
/*    flex-direction: column;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/


/*.scrollContainer_mainDiv{*/
/*    width: 100%;*/

/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*}*/
/*.heading_partners{*/
/*    font-size:30px;*/
/*    font-family: Poppins;*/
/*    font-weight: bold;*/
/*    justify-content: center;*/
/*    align-items:center;*/
/*    color:#C5CBD3;*/
/*}*/

/*@media screen and (max-width: 500px) {*/
/*    .heading_partners{*/
/*        font-size:20px;*/
/*    }*/
/*}*/

/*.partner_div{*/
/*    !*background-color: green;*!*/
/*    padding:10px;*/
/*    margin-top: 10px;*/

/*}*/

/*@media screen and (min-width: 414px) {*/
/*    .partner_div{*/
/*        padding:10px;*/
/*        margin-top: 10px;*/
/*        width: 300px*/

/*    }*/
/*}*/

/*.pricing_main_div{*/
/*    background-color: #FFF8F1;*/
/*    padding-top: 60px;*/
/*    padding-left: 30px;*/
/*}*/
/*.pricingCardDiv{*/
/*    margin-right: 10px;*/
/*}*/
/*.mainDiv_pricingcards{*/
/*    padding:20px;*/
/*    padding-left: 0px;*/
/*}*/


/*.pricingParaDesign{*/
/*    color:#B8A59C;*/
/*    font-size: 14px;*/
/*    padding-right: 20px;*/
/*}*/
/*@media screen and (max-width: 413px) {*/
/*    .slider_div_main{*/
/*        background-color: #F4F9FD;*/

/*        height: 250px;*/
/*    }*/
/*    .scrollContainer_mainDiv{*/
/*        width: 100%;*/

/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: flex-start;*/
/*        align-items: center;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 1200px){*/
/*    .pricingCardDiv{*/
/*        margin-top: 50px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 430px){*/
/*    .scrollContainer_mainDiv{*/
/*        !*width: 80%;*!*/
/*        !*background-color: #d7efff;*!*/
/*        !*height: 50px;*!*/
/*        !*!*padding-bottom: 10%;*!*!*/
/*        !*position: relative;*!*/
/*        !*!*margin-top: 30%;*!*!*/
/*        display: flex;*/
/*        !*flex-direction: row;*!*/
/*        !*justify-content: flex-start;*!*/
/*        !*align-items: center;*!*/
/*    }*/

/*}*/
/*@media screen and (max-width: 767px){*/
/*    .pricingCardDiv{*/
/*        padding-bottom: 10px;*/
/*    }*/
/*}*/



/*business section styling*/

.homeBusinessSection{
    padding-bottom: 40px;
}
.businesstextContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start ;
    margin-top: 70px;
}
.dashboard-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    /*line-height: 41px;*/
    color: #1A1C1F;
    padding-right: 40px;
}
.dashboard-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 550;
    font-size: 22px;
    color: #6B717B;
}

.BusinessImageContainer{
    padding:30px;
    background-color: #FFFFFF;
}
.BusinessImageContainer  img{
    width: 100%;
}

@media screen and (max-width: 1100px){
    .businesstextContainer{
        margin-top: 30px;
    }
}
@media screen and (max-width: 767px){
    .businesstextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        margin-top: 55px;
        padding-left: 40px;
        padding-right: 40px;
    }
    .dashboard-heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 34px!important;
        /*line-height: 41px;*/
        color: #1A1C1F;
        padding-right: 40px;
        text-align: center;
    }
    .dashboard-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 550;
        text-align: center;
        font-size: 22px;
        color: #6B717B;
    }
    .BusinessImageContainer{
        margin-top: 50px;
        padding:20px;
        background-color: #FFFFFF;
    }

}


@media screen and (max-width: 510px) {
    .businesstextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        margin-top: 20px;
        padding-left: 10px;
        padding-right:10px;
    }
    .dashboard-heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 26px!important;
        /*line-height: 41px;*/
        color: #1A1C1F;
        padding-right: 40px;
        text-align: center;
    }
    .dashboard-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 550;
        text-align: center;
        font-size: 18px;
        color: #6B717B;
    }

}


@media screen and (max-width: 400px) {
    .businesstextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        margin-top: 20px;
        padding-left: 10px;
        padding-right:10px;
    }
    .dashboard-heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px!important;
        /*line-height: 41px;*/
        color: #1A1C1F;
        padding-right: 40px;
        text-align: center;
    }
    .dashboard-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 550;
        text-align: center;
        font-size: 16px;
        color: #6B717B;
    }

}






.dashboard-heading{
    font-size: 40px;
    font-weight: bold;
}
.dashboard-description{
    font-size: 18px;
}

.headingCustomerReviews{
    padding-left: 70px;
    font-size:35px;
    color: black;
    font-weight: bold;
}
@media screen and (max-width: 767px) {
    .headingCustomerReviews{
        padding-left:30px ;
        font-size:28px;
    }

}
@media screen and (max-width: 570px) {
    .headingCustomerReviews{
        padding-left:20px ;
        font-size:20px;
    }

}
@media screen and (max-width: 455px) {
    .headingCustomerReviews{
        padding-left:20px ;
        font-size:34px;
    }

}
@media screen and (max-width: 320px) {
    .headingCustomerReviews{
        padding-left:20px ;
        font-size:24px;
    }

}





/*About Section New Desgin Styling Start*/
.new_btn_AboutSection{
    margin-top: 70px !important;
    margin-bottom: 70px !important;
}
.new_about_section_main{
    padding-left:110px;
    padding-right:110px;
    padding-top: 110px;
    padding-bottom: 110px;
}
.about-chex-left-title-span_main_new{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #FF7A00;
}
.about_Content_section_wrapper{
    text-align: center;
}
.paraAbout_new{

    font-size: 20px;

    color:#000000;
    font-family: Poppins;
}
.about-chex-inner-left-desc-span_new_design{
    color:#FF7A00;
    font-size: 20px;
}
.techStar_LogoCompany{
    padding-top: 20px;

}

.text_techStart_para{
font-family: Poppins;
    font-size: 20px;
    color:#6B717B;
}

.a_text{
    font-size: 22px !important;
}
.img_techStar{
    width:10%;

}
.about-chex-left-title_main_new{
font-family: Poppins, sans-serif;
font-size: 36px;
font-style: normal;
font-weight: bolder;
line-height: 44px;
letter-spacing: 1px;
}
.company_logos_New{
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    width: 100%;


}
.item_company img{
    width: 160px;
    height: 130px;
    /*margin-left: 90px;*/

}



@media screen and (max-width: 1200px){
    .item_company img{
        width: 130px;
        height: 100px;
        /*margin-left: 60px;*/

    }
    .img_techStar{
        width:12%;

    }
}
@media screen and (max-width: 1030px){
    .item_company img{
        width: 110px;
        height: 80px;
        /*margin-left: 50px;*/

    }
    .img_techStar{
        width:14%;

    }
}

@media screen and (max-width: 850px){

    .paraAbout_new{
        font-size: 18px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 18px;
    }
    .item_company img{
        /*width: 100px;*/
        /*height: 80px;*/
        /*margin-left: 30px;*/

    }
    .img_techStar{
        width:17%;

    }
}

@media screen and (max-width: 767px){
    .techStar_LogoCompany{
        padding-top: 10px;

    }
    .new_btn_AboutSection{
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .text_techStart_para{
        font-family: Poppins;
        font-size: 24px;
    }
    .a_text{
        font-size: 22px;
    }

    .img_techStar{
        width:20%;

    }
    .paraAbout_new{
        font-size: 20px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 20px;
    }
    /*.item_company img{*/
    /*    width: 70px;*/
    /*    height: 50px;*/
    /*    margin-left: 30px;*/

    /*}*/
}


@media screen and (max-width: 720px){

    .new_about_section_main{
        padding-left:60px;
        padding-right:60px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .techStar_LogoCompany{
        padding-top: 10px;

    }

    .text_techStart_para{
        font-family: Poppins;
        font-size: 28px;
    }
    .a_text{
        font-size: 25px !important;
    }

    .img_techStar{
        width:23%;

    }
    .paraAbout_new{
        font-size: 20px;
        color: black !important;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 20px;
    }
    /*.item_company img{*/
    /*    width: 90px;*/
    /*    height: 70px;*/
    /*    margin-bottom: 30px;*/
    /*    margin-left: 20px;*/

    /*}*/
}

@media screen and (max-width: 600px){

    .new_about_section_main{
        padding-left:40px;
        padding-right:40px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .techStar_LogoCompany{
        padding-top: 10px;

    }

    .text_techStart_para{
        font-family: Poppins;
        font-size: 24px;
    }
    .a_text{
        font-size: 22px !important;
    }


    .img_techStar{
        width:30%;

    }
    .paraAbout_new{
        font-size: 20px;
    }
    .item_company img{
        width: 100px;
        height: 80px;


    }
}


@media screen and (max-width: 550px){

    .new_about_section_main{
        padding-left:40px;
        padding-right:40px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .techStar_LogoCompany{
        padding-top: 10px;

    }
    .new_btn_AboutSection{
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .text_techStart_para{
        font-family: Poppins;
        font-size: 20px;
    }
    .a_text{
        font-size: 18px !important;
    }


    .img_techStar{
        width:30%;

    }
    .paraAbout_new{
        font-size: 18px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 18px;
    }
    .item_company img{
        width:110px;
        height: 90px;


    }
}

@media screen and (max-width: 500px) {

    .paraAbout_new{
        font-size: 16px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 16px;
    }

}

@media screen and (max-width: 460px) {
    .new_about_section_main{
        padding-left:20px;
        padding-right:20px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .techStar_LogoCompany{
        padding-top: 10px;

    }

    .paraAbout_new{
        font-size: 16px;
    }


    .item_company img{
        width: 100px;
        height: 70px;


    }
}

@media screen and (max-width: 400px) {


    .paraAbout_new{
        font-size: 14px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 14px;
    }

}

@media screen and (max-width: 360px) {
    .new_about_section_main{
        padding-left:10px;
        padding-right:10px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .new_btn_AboutSection{
        margin-top: 50px !important;
        margin-bottom: 30px !important;
    }

    .text_techStart_para{
        font-size: 16px;
    }
    .a_text{
        font-size: 14px !important;
    }

    .paraAbout_new{
        font-size: 11px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 11px;
    }
    .about-chex-left-title-span_main_new{
        font-family: 'Poppins', sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        text-align: left;
        color: #FF7A00;
    }
    .about-chex-left-title_main_new{
        font-family: Poppins, sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: bolder;
        line-height: 44px;
        letter-spacing: 1px;
    }
    /*.item_company img{*/
    /*    width: 60px;*/
    /*    height: 40px;*/
    /*    margin-bottom: 0px;*/
    /*    margin-left: 5px;*/

    /*}*/

}



/*About Section New Design Styliong End*/


/*Company Logo Section New Design*/

.companylogo_container{
    position: relative;
    margin-top: 50px;

}

.companylogo_container .rec.rec-arrow-right {
    background-color: #F4F9FF;
    border-radius: 30px;
    position: absolute;
    top:25%;
    right:4%;
    bottom:-10%;


}
.companylogo_container .rec.rec-arrow-left {
    background-color: #F4F9FF;
    border-radius: 30px;
    position: absolute;
    top:25%;
    display: none;
    left:4%;
    z-index: 1;
    bottom:0% !important;
    position: absolute;

    /*right: 10%;*/


}

@media screen and (max-width: 580px) {
    .companylogo_container .rec.rec-arrow-right {
        background-color: #F4F9FF;
        border-radius: 30px;
        position: absolute;
        top:20%;
        right:4%;
        bottom:-10%;


    }
}


@media screen and (max-width: 400px) {
    .companylogo_container .rec.rec-arrow-right {
        background-color: #F4F9FF;
        border-radius: 30px;
        position: absolute;
        top:16%;
        right:4%;
        bottom:-10%;


    }
}



/*.rec.rec-arrow-right {*/
/*    background-color: #F4F9FF;*/
/*    border-radius: 30px;*/
/*    position: absolute;*/
/*    right:6%;*/
/*    bottom:-10%;*/

/*}*/
/*.rec.rec-arrow:hover{*/
/*    background-color: #F4F9FF!important;*/
/*    color:black!important;*/
/*}*/

/*.rec.rec-arrow-left{*/

/*    background-color:#F4F9FF;*/
/*    border-radius: 30px;*/
/*    z-index: 1;*/
/*    bottom:-10%;*/
/*    position: absolute;*/

/*    right: 10%;*/
/*}*/



/*Pricing Section Styling*/

.pricingSections_uber{

    padding:28px;
    padding-left: 100px;
    padding-right: 100px;
}
.headingPricing{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 31px;

    color: black;
}

.paragraphPricing{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;


    color: #414B55;

}
.new_btn_pricingSection{
    margin-top: 40px !important;
    margin-bottom: 10px !important;
}

.pricingPoints{
    display: flex;

    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;

}
.pricingPointsText{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /*line-height: 0px;*/
    color: #5D6F84;
}

@media screen and (max-width: 768px) {
    .pricingPoints{
        display: flex;

        flex-direction: row;
        padding-top: 5px;
        padding-bottom: 5px;

    }

}


.pricingCardRow{

    padding-top:30px;
    padding-bottom: 30px;
}
@media screen and (max-width: 767px){
    .pricingCardRow{
        margin-left: 170px;

    }
}

@media screen and (max-width: 672px){
    .pricingCardRow{
        margin-left: 120px;

    }
}

@media screen and (max-width: 540px){
    .pricingCardRow{
        margin-left: 80px;

    }
}

@media screen and (max-width: 500px){
    .pricingCardRow{
        margin-left: 70px;

    }
}
@media screen and (max-width: 473px){
    .pricingCardRow{
        margin-left: 50px;

    }
}

@media screen and (max-width: 450px){
    .pricingCardRow{
        margin-left: 0px;

    }
}



.pricingColumn{
    width: 100%;


}
.priceCardCompo{
    margin-right: 20px;

}


@media  screen and (max-width: 1200px) {
    .pricingColumn{
        margin-top: 50px;
    }
}


@media  screen and (max-width: 768px) {
    .pricingSections_uber{
        background: #FFF8F1;
        padding:28px;
        padding-left: 60px;
        padding-right: 60px;
    }
}
@media  screen and (max-width: 590px) {
    .pricingSections_uber{
        background: #FFF8F1;
        padding:28px;
        padding-left: 30px;
        padding-right: 30px;
    }

}

.btn_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_styling_uber{

    background-color:#FF7A00 ;
    padding:10px;
    padding-left:50px;
    padding-right: 50px;

    border:1px solid #FF7A00;
    border-radius: 50px;

    color:white;
    margin-bottom: 10px;

}

.btn_styling_uber:hover{

    background-color:#FFFFFF ;


    border:1px solid #FF7A00;

    color:#FF7A00;
    transition: 0.5s;

}




/*Vehicle Inspection Section*/

.vehicleIncpectionContainer{
    padding:10px;
    padding-left: 100px;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-end;*/
}
.vehicleIncpectionContainer h3{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;

}
.vehicleIncpectionContainer p{
    color:rgba(21, 25, 34, 1);
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}
.vehicleListContainer li{
    /*color:red;*/
    list-style: none;
    color:rgba(107, 113, 123, 1);
    font-size: 16px;
    /*float: left;*/
    margin:0px;
    padding:0px;

}
.vehicleListContainer li::before {
    content: "\2022";
    color: #2584E0;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 1.1rem;
}

.vehicleIncpectionContainerImg {
    display: flex;

}
.vehicleIncpectionContainerImg img{
    width: 100%;
    margin-top: -60px;

}
@media screen and (max-width: 900px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 50px;
        padding-right: 50px;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-end;*/
    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
    }

}

@media screen and (max-width: 768px){
    .vehicleIncpectionContainerImg {
        display: none;

    }

    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 100px;
        padding-right: 100px;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-end;*/
    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 34px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
    }
}

@media screen and (max-width: 684px) {
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }
}


@media screen and (max-width: 600px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 60px;
        padding-right: 60px;

    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }
}

@media screen and (max-width: 520px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 40px;
        padding-right: 40px;

    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
    }
    .vehicleListContainer li{
        /*color:red;*/
        list-style: none;
        color:rgba(107, 113, 123, 1);
        font-size: 14px;
        /*float: left;*/
        margin:0px;
        padding:0px;

    }
}


@media screen and (max-width: 360px) {
    .vehicleIncpectionContainer{
        padding:20px;


    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
    }
    .vehicleListContainer li{
        /*color:red;*/
        list-style: none;
        color:rgba(107, 113, 123, 1);
        font-size: 13px;
        /*float: left;*/
        margin:0px;
        padding:0px;

    }
}


/*Pricing Section Styling*/


/*How Chex Works*/
.chexWorks-main-container-lyft{
    width: 100%;
    padding-top: 20px ;
    padding-bottom: 80px;
}
.chexwork-inner-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
}
.chaxwork-heading{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
}
.chaxwork-heading-span{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF7A00;
}


.chexworks-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}
.chexwork-img{
    height: 250px;
    width: 250px;
}
.chexwork-image-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-style: normal;
    line-height: 115.5%;
    text-align: center;
    margin-top: 10px;
    max-width: 250px;
}
.chexwork-image-desc{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-top: 10px;
    color: #6B717B;
    text-align: center;
    max-width: 250px;
}
.chexwork-image-getStarted-span{
    color: #FF7A00;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}
.chexwork-image-payment-span{
    color: #6B717B;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}

/*Badge Banner*/

.btn_div_banner{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.btn_styling_banner{
    background-color:#2584E0 ;
    padding:10px;
    border:1px solid #2584E0;
    border-radius: 50px;
    padding-right: 40px;
    padding-left: 40px;
    color:white;
    margin-bottom: 10px;
    /*margin-top: 35px;*/

}
.btn_styling_banner:hover{
    background-color:#FFFFFF ;


    border:1px solid #2584E0;

    color:#2584E0;
    transition: 0.5s;
}

.badge-container{
    width: 100%;
    height: 720px;
    background-color: #2a2a2a;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom:80px;
}
.badge-image-inner-container{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    /*background-color: #8c9dae;*/
}
.badge-inner-left{
    width: 50%;

    /*height: 50%;*/
}

@media screen and (max-width: 890px){
    .badge-inner-left{
        width: 100%;
        /*height: 50%;*/
    }
}

@media screen and (max-width: 768px){
    .badge-container{
        width: 100%;
        height: 400px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom:40px;
        background-color: black;
        display: block;
    }
    .badge-inner-left{
        margin-top: -60px;
        width: 100%;
        /*height: 50%;*/
    }
}

@media only screen and (min-width: 1140px){
    .badge-container{
        width: 100%;
        height: 890px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom:40px;
        background-color: black;
        display: block;
    }
}

.badge-main-heading{
    font-family: Poppins;
    font-size: 34px;
    line-height: 53px;
    text-align: left;
    font-weight: bolder;
    color: #FFFFFF;
}
.badge-main-para{
    font-family: Poppins;
    font-size: 18px;
    line-height: 23px;
    text-align: left;
    margin-bottom: 30px;

    color: #FFFFFF;
}


@media screen and (max-width: 575px){
    .badge-container{
        padding-left: 30px;
        padding-right: 30px;
    }
    .badge-main-heading{
        text-align: center;
        font-size: 25px;
    }
    .badge-inner-left{
        display: flex;
        justify-content: center;

        align-items: center;
        margin-top: 10px!important;
        flex-direction: column;
        width: 100%;
        text-align: center;
        /*height: 50%;*/
    }
    .badge-main-para{
        text-align: center;
    }


}

@media screen and (max-width:500px ) {
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color: #8c9dae;*/
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .badge-main-para{
        font-family: Poppins;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 30px;

        color: #FFFFFF;
    }

}

@media screen and (max-width: 360px){

    .badge-main-heading{
        font-family: Poppins;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .badge-main-para{
        font-family: Poppins;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 30px;

        color: #FFFFFF;
    }

}

.about-chex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.about-chex-left{
    width: 50%;
    padding-top: 50px;
    background-color: #8c9dae;
    padding-bottom: 50px;
}
.chex-about-left-inner{
    width: 85%;
    float: right;
}
.about-chex-left-title{
    font-family: Poppins, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: bolder;
    line-height: 44px;
    letter-spacing: 1px;
}
.about-chex-left-title-span{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #FF7A00;
}
.about-chex-container .about-chex-left .chex-about-left-inner > p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 160%;
    color: #6B717B;
    letter-spacing: 1px;
    margin-bottom: 5%;
}
.about-chex-inner-left-desc-span{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: #FF7A00;
}
.about-chex-right{
    width: 50%;
    background-color: #FFF8F1;
    height: 400px;
    margin-top: -200px;
    padding-top: 13%;
}
.about-chex-right-inner{
    width: 80%;
    background-color: aqua;
    height: 300px;
    margin: 0 auto;
}
.about-chex-right > h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #151922;
    margin-left: 20px;
}
.about-pricing-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}
.about-chex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
}
.about-chex-left{
    width: 50%;
    padding-top: 50px;
    background-color: #8c9dae;
    padding-bottom: 50px;
}
.chex-about-left-inner{
    width: 85%;
    float: right;
}
.about-chex-left-title{
    font-family: Poppins, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: bolder;
    line-height: 44px;
    letter-spacing: 1px;
}
.about-chex-left-title-span{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #FF7A00;
}
.about-chex-container .about-chex-left .chex-about-left-inner > p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 160%;
    color: #6B717B;
    letter-spacing: 1px;
    margin-bottom: 5%;
}
.about-chex-inner-left-desc-span{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: #FF7A00;
}
.about-chex-right{
    width: 50%;
    background-color: #FFF8F1;
    height: 400px;
    margin-top: -200px;
    padding-top: 13%;
}
.about-chex-right-inner{
    width: 80%;
    background-color: aqua;
    height: 300px;
    margin: 0 auto;
}
.about-chex-right > h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #151922;
    margin-left: 20px;
}
.about-pricing-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}

.scrollContainer{
    width: 80%;
    background-color: #d7efff;
    height: 150px;
    padding-bottom: 10%;
    position: relative;
    margin-top: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.arrow-container{
    background-color: #d9e8ff;
    padding:5px;
    margin-top: 30px;
    border-radius: 50px;
}

.carousel_div_main{
    width: 75%;
}

@media screen and (max-width: 1050px){
    .carousel_div_main{
        width: 70%;
    }
}
@media screen and (max-width: 895px){
    .carousel_div_main{
        width: 65%;
    }
}
@media screen and (max-width: 768px){
    .carousel_div_main{
        width: 75%;
    }
}
@media screen and (max-width: 576px){
    .carousel_div_main{
        width: 68%;
    }
}
@media screen and (max-width: 500px){
    .heading_partners{
        margin-top: 20px;
    }
    .carousel_div_main{
        width: 75%;
        margin-top: 10px;
    }
}

@media screen and (max-width: 412px){
    .heading_partners{
        margin-top: 10px;
    }
    .carousel_div_main{
        width: 60%;
        margin-top: 10px;
    }
}
@media screen and (max-width: 330px){

    .carousel_div_main{
        width: 80%;
        margin-top: 10px;
    }
}

.footerContainer{
    background: #1B1C1F;
    padding-top: 50px;
    /*padding-left: 200px;*/
    padding-bottom: 50px;
    color:White;
}
.aboutSection{
    margin-right: 20px;
}
.footerLogoContainer{
    margin-bottom: 30px;
    width: 100%;
}
.footerAboutDescription{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #FFFFFF;
}

.headingFooter{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    margin-bottom:20px;
    line-height: 20px;
    color: #FFFFFF;
}
.footerMenuList{
    list-style: none;
    float: left;
    margin: 0;
    padding: 0;


}
.footerMenuList li{
    margin-bottom: 10px;
}
.footerMenuList li a{
    color: #808389!important;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}
.footerMenuList li a:hover{
    color: #FF7A00!important;
}
.textFooter{
    font-size: 14px;

    color: #FFFFFF;
    margin-bottom: 30px;

}
.iconsContainer{
    margin-bottom: 20px;
}
.iconsContainer a{
    /*background-color: red;*/
    /* padding:20px;*/
    margin-right: 15px;
}
.footerLinks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footerLinks span{
    margin-bottom: 10px;

}
.footerLinks span Link{
    color:#FFFFFF!important;
}
.footerLinks span:hover{
    color: #FF7A00!important;
}

.copyrightText{
    text-align: center;
}
@media screen and (max-width: 900px){
    .textFooter{
        font-size:11px
    }


}

@media screen and (max-width: 768px){
    .menuContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;

    }
    .aboutSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left:30px;
        padding-right: 30px;
        text-align: center;
        margin-bottom:20px;
    }
    .footerMenuList{
        text-align: center;

    }
    .headingFooter{

        font-size: 18px;

    }
    .ContactContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom:20px;
    }
    .textFooter{
        font-size: 14px;
    }
    .iconsContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .copyrightText{
        text-align: center;
    }
}



/*Vehicle Inspection Section*/

.vehicleIncpectionContainer{
    padding:10px;
    padding-left: 100px;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-end;*/
}
.vehicleIncpectionContainer h3{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;

}
.vehicleIncpectionContainer p{
    color:rgba(21, 25, 34, 1);
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
}
.vehicleListContainer_uber li{
    /*color:red;*/
    list-style: none;
    color:rgba(107, 113, 123, 1);
    font-size: 16px;
    /*float: left;*/
    margin:0px;
    padding:0px;

}
.vehicleListContainer_uber li::before {
    content: "\2022";
    color: #FF7A00;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 1.1rem;
}

.vehicleIncpectionContainerImg {
    display: flex;

}
.vehicleIncpectionContainerImg img{
    width: 100%;
    margin-top: -60px;

}
@media screen and (max-width: 900px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 50px;
        padding-right: 50px;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-end;*/
    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
    }

}
@media screen and (min-width: 1800px) {
.vehicleIncpectionContainerImg img{
    width: 100%;
    margin-top: 0px;

}
}

@media screen and (max-width: 768px){
    .vehicleIncpectionContainerImg {
        display: none;

    }

    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 100px;
        padding-right: 100px;
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-end;*/
    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 34px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
    }
}

@media screen and (max-width: 684px) {
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }
}


@media screen and (max-width: 600px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 60px;
        padding-right: 60px;

    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }
}

@media screen and (max-width: 520px) {
    .vehicleIncpectionContainer{
        padding:10px;
        padding-left: 40px;
        padding-right: 40px;

    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
    }
    .vehicleListContainer li{
        /*color:red;*/
        list-style: none;
        color:rgba(107, 113, 123, 1);
        font-size: 14px;
        /*float: left;*/
        margin:0px;
        padding:0px;

    }
}


@media screen and (max-width: 360px) {
    .vehicleIncpectionContainer{
        padding:20px;


    }
    .vehicleIncpectionContainer h3{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;

    }
    .vehicleIncpectionContainer p{
        color:rgba(21, 25, 34, 1);
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
    }
    .vehicleListContainer li{
        /*color:red;*/
        list-style: none;
        color:rgba(107, 113, 123, 1);
        font-size: 13px;
        /*float: left;*/
        margin:0px;
        padding:0px;

    }
}


/*Pricing Section Styling*/

/*.pricingSections_uber{*/
/*    background: #FFF8F1;*/
/*    padding:28px;*/
/*    padding-left: 100px;*/
/*    padding-right: 100px;*/
/*}*/
/*.headingPrcing{*/
/*    font-family: Poppins;*/
/*    font-style: normal;*/
/*    font-weight: bold;*/
/*    font-size: 28px;*/
/*    line-height: 31px;*/

/*    color: black;*/
/*}*/

/*.paragraphPricing{*/
/*    font-family: Poppins;*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 14px;*/
/*    line-height: 160%;*/


/*    color: #414B55;*/

/*}*/
/*.pricingPoints{*/
/*    display: flex;*/

/*    flex-direction: row;*/
/*    padding-top: 20px;*/
/*    padding-bottom: 20px;*/

/*}*/
/*.pricingPointsText{*/
/*    font-family: Poppins;*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    font-size: 14px;*/
/*    !*line-height: 0px;*!*/
/*    color: #5D6F84;*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*    .pricingPoints{*/
/*        display: flex;*/

/*        flex-direction: row;*/
/*        padding-top: 5px;*/
/*        padding-bottom: 5px;*/

/*    }*/

/*}*/


/*.pricingCardRow{*/
/*    padding-top:30px;*/
/*    padding-bottom: 30px;*/
/*}*/

/*.pricingColumn{*/
/*    width: 100%;*/


/*}*/
/*.priceCardCompo{*/
/*    margin-right: 20px;*/

/*}*/


/*@media  screen and (max-width: 1200px) {*/
/*    .pricingColumn{*/
/*        margin-top: 50px;*/
/*    }*/
/*}*/


/*@media  screen and (max-width: 768px) {*/
/*    .pricingSections_uber{*/
/*        background: #FFF8F1;*/
/*        padding:28px;*/
/*        padding-left: 60px;*/
/*        padding-right: 60px;*/
/*    }*/
/*}*/
/*@media  screen and (max-width: 590px) {*/
/*    .pricingSections_uber{*/
/*        background: #FFF8F1;*/
/*        padding:28px;*/
/*        padding-left: 30px;*/
/*        padding-right: 30px;*/
/*    }*/

/*}*/

/*.btn_div{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.btn_styling_uber{*/

/*    background-color:#FF7A00 ;*/
/*    padding:10px;*/

/*    border:1px solid #FF7A00;*/
/*    border-radius: 50px;*/
/*    padding-right: 30px;*/
/*    padding-left: 30px;*/
/*    color:white;*/
/*    margin-bottom: 10px;*/

/*}*/

/*.btn_styling_uber:hover{*/

/*    background-color:#FFFFFF ;*/


/*    border:1px solid #FF7A00;*/

/*    color:#FF7A00;*/
/*    transition: 0.5s;*/

/*}*/
.btn_styling_contact{

    background-color:#2584E0 ;
    padding:10px;

    border:1px solid #2584E0;
    border-radius: 50px;
    padding-right: 50px;
    padding-left: 50px;
    color:white;
    margin-bottom: 10px;
}
.btn_styling_contact:hover{
    background-color:#FFFFFF ;


    border:1px solid #2584E0;

    color:#2584E0;
    transition: 0.5s;
}

@media screen and (max-width: 768px){
    .btn_div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn_styling_uber{
        text-align: center;
    }
}



/*How Chex Works*/
.chexWorks-main-container-uber{
    width: 100%;
    padding-top: 20px ;
    padding-bottom: 80px;
}
.chexwork-inner-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
}
.chaxwork-heading{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
}
.chaxwork-heading-span{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF7A00;
}
.chexworks-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}
.chexwork-img{
    height: 250px;
    width: 250px;
}
.chexwork-image-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-style: normal;
    line-height: 115.5%;
    text-align: center;
    margin-top: 10px;
    max-width: 250px;
}
.chexwork-image-desc{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-top: 10px;
    color: #6B717B;
    text-align: center;
    max-width: 250px;
}
.chexwork-image-getStarted-span{
    color: #FF7A00;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}
.chexwork-image-payment-span{
    color: #6B717B;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}

/*Badge Banner*/

.btn_div_banner{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.btn_styling_banner_uber{
    background-color:#FF7A00!important ;
    padding:10px;

    border:1px solid #FF7A00;
    border-radius: 50px;
    padding-right: 50px;
    padding-left: 50px;
    color:white!important ;
    margin-bottom: 10px;

}
.btn_styling_banner_uber:hover{
    background-color:#FFFFFF!important  ;


    border:1px solid #FF7A00!important ;

    color:#FF7A00!important ;
    transition: 0.5s;
}

.badge-container-uber{
    width: 100%;
    height: 720px;
    background-color: #2a2a2a;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom:80px;
    /* opacity: 99%; */
}
.badge-image-inner-container{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    /*background-color: #8c9dae;*/
}
.badge-inner-left{
    width: 50%;

    /*height: 50%;*/
}

@media screen and (max-width: 890px){
    .badge-inner-left{
        width: 100%;
        /*height: 50%;*/
    }
}

@media only screen and (max-width: 768px){
    .badge-container-uber{
        width: 100%;
        height: 460px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom:40px;
        background-color: black;
        display: block;
    }
    .badge-inner-left{
        margin-top: -60px;
        width: 100%;
        /*height: 50%;*/
    }
}

@media only screen and (min-width: 1140px){
    .badge-container-uber{
        width: 100%;
        height: 890px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom:40px;
        background-color: black;
        display: block;
    }
}

.badge-main-heading{
    font-family: Poppins;
    font-size: 34px;
    line-height: 53px;
    text-align: left;
    font-weight: bolder;
    color: #FFFFFF;
}
.badge-main-para{
    font-family: Poppins;
    font-size: 18px;
    line-height: 23px;
    text-align: left;
    margin-bottom: 30px;

    color: #FFFFFF;
}
.contact-submit-btn {
    background: #FF7A00 !important;
    border:0px solid transparent;
    outline: none !important;
    width: 40% !important;
    height: auto;
    font-style: normal;
    font-weight:800;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    float: left;
    font-family: Poppins;

}
.contact-submit-btn:hover{
    background: #FFFFFF !important;
    border:1px solid #FF7A00 !important;
    color:#FF7A00;
    transition: 0.5s;
}

@media screen and (max-width: 575px){
    .badge-container-uber{
        padding-left: 0px;
        padding-right: 30px;
    }
    .badge-main-heading{
        text-align: center;
        font-size: 25px;
    }
    .badge-inner-left{
        display: flex;
        justify-content: center;

        align-items: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        /*height: 50%;*/
    }
    .badge-main-para{
        text-align: center;
    }
    .contact-submit-btn {
        background: #FF7A00 !important;
        border:0px solid transparent;
        outline: none !important;
        width: 60% !important;
        height: auto;
        font-style: normal;
        font-weight:800;
        font-size: 16px;
        padding-left: 20px;
        padding-right: 20px;
        float: left;
        font-family: Poppins;

    }

}

@media screen and (max-width:500px ) {
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color: #8c9dae;*/
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 24px;
        line-height: 20px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .badge-main-para{
        font-family: Poppins;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 30px;

        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #FF7A00 !important;
        border:0px solid transparent;
        outline: none !important;
        width: 50% !important;
        height: auto;
        font-style: normal;
        font-weight:800;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        float: left;
        font-family: Poppins;

    }
}

@media screen and (max-width: 360px){
    .contact-submit-btn {
        background: #FF7A00 !important;
        border:0px solid transparent;
        outline: none !important;
        width: 70% !important;
        height: auto;
        font-style: normal;
        font-weight:800;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        float: left;
        font-family: Poppins;

    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .badge-main-para{
        font-family: Poppins;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 30px;

        color: #FFFFFF;
    }

}

.chexBannerContainer{
    background-image:linear-gradient(to right,#254f8e5b, #254f8e57) ,url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470833/formrectangle_agiisk_rishru.png');
    background-size: cover;
    background-position: center;
    margin-top: 150px;
    margin-bottom: 270px;
    height: 350px;
    display: flex;
    align-items: center;
}
.bannerContentContainer{

    padding-left: 100px;
    margin-right: 100px;
}
.headingBanner_ChexForm{

    /* width: 70%; */
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    font-family: Poppins;
    color:white;
}
.contentBold{
    font-family: "Roboto"!important;
    font-weight: 800;
    letter-spacing: 1px;
}
.bannerFormContainer{
    background-color: #EEEEEE;
    border-top: 5px solid #FF7A00;
    padding: 30px;
    padding-left: 45px;
    padding-right: 45px;
    position: absolute;
    /*top:10%;*/
    /*bottom:110%;*/
    /*left: 0%;*/
    margin-top:-260px;
    /*margin-right: 100px;*/
    /* width: 600px; */
    /* max-width: 650px; */
}
.headingFormChex{
    font-size: 26px;
    font-weight: 800;
    color:#3C3B3B;
}
.formContainer_chex{
    padding-top: 20px;

}
.namingSections_chex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input_field_chex {
    background: rgb(255, 255, 255) !important;
    border: 1px solid #C1C1C1 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    width: 100% !important;
    margin: 0px auto 15px !important;
    padding: 15px 8px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: 'Poppins' !important;
    letter-spacing: 1px !important;

}

::-webkit-input-placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

:-ms-input-placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

::placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

.terms_conditionsContainer{
    display: flex;
    flex-direction: row;
}

.termsConditionsText{
    padding-left: 10px;
    font-size: 16px;
}

.button-wrapper_chex {
    background:  #FF7A00!important;
    border-radius: 6px !important;
    border: 1px solid #FF7A00 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 32px !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 10px 15px !important;
    text-decoration: none !important;
    cursor: pointer !important;
    display: block;
    width: 100% !important;
    margin: 0px auto !important;
    height: 54px !important;
}

.newcheckmark_chex {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e3e2e2;
    border:1px solid grey;
    border-radius: 3px;
}

.register-checkbox-text_chex{
    color:black;
}
.newcontainer:hover input ~ .newcheckmark_chex {
    background-color: #ccc;

}
.newcontainer input:checked ~ .newcheckmark_chex {
    background-color: #fff;

}
.newcheckmark_chex:after {
    content: '';
    position: absolute;
    display: none;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border:1px solid transparent;
    background-color: #d3d3d3;

}
.newcontainer input:checked ~ .newcheckmark_chex:after {
    content: '\2713';
    display: block;

  display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    /*margin-top: -10px;*/

    text-align: center;

    color: #ff7a00;
}


@media screen and (max-width: 1400px) {
    .bannerFormContainer{
        background-color: #EEEEEE;
        border-top: 5px solid #FF7A00;
        padding: 30px;
        padding-left: 40px;
        padding-right: 40px;
        position: absolute;
        margin-right: 10px;
        /*top:10%;*/
        /*bottom:110%;*/
        /*left: 0%;*/
        margin-top:-260px;
        /*margin-right: 100px;*/
        /* width: 600px; */
        /* max-width: 650px; */
    }
    .headingFormChex{
        font-size: 25px;
        font-weight: 800;
        color:#3C3B3B;
    }

}
@media screen and (max-width: 1300px) {
    .headingFormChex{
        font-size: 23px;
        font-weight: 800;
        color:#3C3B3B;
    }

    .bannerFormContainer{
        background-color: #EEEEEE;
        border-top: 5px solid #FF7A00;
        padding: 30px;
        padding-left: 30px;
        padding-right: 30px;
        position: absolute;
        margin-right: 40px;
        margin-top:-260px;
    }

}

@media screen and (max-width: 1799px) {
    .form-item-style-chex_first{
        width: 48%;
    }
}
@media screen and (max-width: 1225px) {
    .headingFormChex{
        font-size: 21px;
        font-weight: 800;
        color:#3C3B3B;
    }

}

@media screen and (max-width: 1150px) {
    .headingFormChex{
        font-size: 21px;
        font-weight: 800;
        color:#3C3B3B;
    }
    .bannerContentContainer{

        padding-left: 50px;
        margin-right: 50px;
    }
}

@media screen and (max-width: 985px) {

    .headingFormChex{
        font-size: 18px;
        font-weight: 800;
        color:#3C3B3B;
    }
    .bannerContentContainer{

        padding-left: 40px;
        margin-right: 40px;
    }
    .headingBanner_ChexForm{

        /* width: 70%; */
        font-size: 17px;
        font-weight: 500;
        text-align: center;

        color:white;
    }
}


@media screen and (max-width: 842px) {
    .form-item-style-chex_first{
        width: 100%;
    }
    .namingSections_chex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .headingFormChex{
        font-size: 16px;
        font-weight: 800;
        color:#3C3B3B;
    }
    .bannerContentContainer{

        padding-left: 40px;
        margin-right: 40px;
    }
    .headingBanner_ChexForm{

        /* width: 70%; */
        font-size: 17px;
        font-weight: 500;
        text-align: center;
        color:white;
    }

}

@media screen and (max-width: 769px){
    .chexBannerContainer{
        background-image:linear-gradient(to right,#254f8e5b, #254f8e57) ,url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470833/formrectangle_agiisk_rishru.png');
        background-size: cover;
        background-position: center;
        margin-top: 20px;
        margin-bottom: 0px;
        padding:20px;
        position:relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;
        height: auto;
        /*display: block;*/
    }

    .bannerFormContainer{
        background-color: #EEEEEE;
        border-top: 5px solid #FF7A00;
        padding: 20px;


        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        /*margin-left: 0px;*/
        /*top:10%;*/
        /*bottom:110%;*/
        /*left: 0%;*/
        margin-top:0px;
        /*margin-right: 100px;*/
        /*width: 540px;*/
        /* max-width: 650px; */
    }
    .bannerContentContainer{
text-align: center;
        padding-left: 0px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-right: 0px;
    }
    .headingFormChex{
        text-align: center;
        font-size: 24px;
        font-weight: 800;
    }
}


@media screen and (max-width: 600px){

    .bannerContentContainer{

        padding-left: 20px;
        padding-right: 20px;
        margin-right: 0px;
    }
    .bannerFormContainer{
        background-color: #EEEEEE;
        border-top: 5px solid #FF7A00;
        padding: 20px;
        /*margin-left: 10px;*/


        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        /*margin-left: 0px;*/
        /*top:10%;*/
        /*bottom:110%;*/
        /*left: 0%;*/
        margin-top:0px;
        /*margin-right: 100px;*/
        width: 100%;
        /* max-width: 650px; */
    }
}


@media screen and (max-width: 510px){
    .register-checkbox-text_chex{
        font-size: 12px;
    }
    .headingFormChex{
        text-align: center;
        font-size: 20px;
        font-weight: 800;
    }

}

@media screen and (max-width: 444px){
    .headingBanner_ChexForm{
        font-size: 15px;
    }
    .headingFormChex{
        text-align: center;
        font-size: 17px;
        font-weight: 800;
    }

}


@media screen and (max-width: 384px){
    .bannerContentContainer{

        padding-left: 10px;
        padding-right: 10px;
        margin-right: 0px;
    }
    .headingBanner_ChexForm{
        font-size: 15px;
    }
    .headingFormChex{
        text-align: center;
        font-size: 14px;
        font-weight: 800;
    }

}

@media screen and (min-width: 1800px) {
    .namingSections_chex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.login-landing-signup {
    display: "flex";
    margin-top: 15px;
}

@media screen and (min-width: 1440px) {
.login-landing-signup {
    display: "flex";
    margin-top: 3px;
}
}

@media screen and (min-width: 1800px) {
    .chexBannerContainer{
        background-image:linear-gradient(to right,#254f8e5b, #254f8e57) ,url('https://res.cloudinary.com/dgjordf6e/image/upload/v1665470833/formrectangle_agiisk_rishru.png');
        background-size: cover;
        background-position: center;
        margin-top: 150px;
        margin-bottom: 285px;
        height: 350px;
        display: flex;
        align-items: center;
    }
}


.box_badge_wrapper_outer{
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items:flex-end;
    flex-direction: row;
    /*background-color: red;*/
    margin-top: -80px;
    padding-bottom: 30px;
}

.text_techStart_para_landing{
    font-size: 28px;
}

.a_text_landing{
    font-size: 28px !important;
}

@media screen and (max-width: 643px){
    .box_badge_wrapper_outer{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items:center;
        flex-direction: row;
        flex-wrap: wrap;
        /*background-color: red;*/
        margin-top: 0px;
    }
    .text_techStart_para_landing{
        font-size: 24px;
    }

    .a_text_landing{
        font-size: 24px !important;
    }
}



/*key_benefits_wrapper_landing stying start*/

.key_benefits_wrapper_landing{

}


/*Community Wrapper Styling Start*/

.community_wrapper_Landing{
    /*margin-top: 20px;*/
    /*margin-bottom: 20px;*/
}







/*Landing Tailored Section styling start*/

.main_wrapper_tailored_section_outer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    padding-left: 100px;
    padding-right: 100px;

}


.main_wrapper_tailored_section_outer .heading_tailored_main{
    font-size: 32px;
    font-family: Poppins;
    font-weight: bold;
    text-align: center;
    color: #000000;
    padding-bottom: 20px;

}
.main_wrapper_tailored_section_outer .heading_tailored_main span{
    color: #FF9900;

}

.main_wrapper_tailored_section_inner{
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*flex-direction: column;*/
    /*background-color: white;*/
    /*background-color: rgba(123, 255, 0, 0.37);*/
    width: 100%;
    border-radius: 20px;
    padding-top: 4%;
    padding-bottom: 4%;
    box-shadow: 5px 5px 10px #A6B4C8;

}
.solution_wrapper_boxes_outer{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    /*background-color: rgba(0, 128, 0, 0.2);*/
    align-items: center;
    padding: 0px;

}

.solution_wrapper_box{
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*flex-direction: column;*/
    padding: 0px;
    width: 100%;
    /*width: 500px;*/
    height: 300px;
    /*background-color: rgba(255, 0, 0, 0.2);*/


}
.line_design{
    /*padding-right: 40px;*/
    /*padding-left: 40px;*/
    margin:0px;
    padding:0px;
    border-right: 0.5px solid #b4b1b1;

}
.solution_wrapper_box .image_wrapper_tailored_box{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;


}
.solution_wrapper_box .image_wrapper_tailored_box img{
    width:200px;
}
.image_wrapper_tailored_box .car_image_mobility{
    width: 170px !important;
}
.solution_wrapper_box h3{
    font-size: 20px;
    font-family: Poppins;
    font-weight: bold;
    color: #1468BA;
    text-align: center;
    padding-top: 10px;


}
.solution_wrapper_box p{

    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    color: #1B1C1F;
    text-align: center;
    padding-left: 70px;
    padding-right: 70px;


}

@media screen and (max-width: 1184px) {
    .solution_wrapper_box p{
        padding-left: 50px;
        padding-right: 50px;


    }

}

@media screen and (max-width: 1419px) {
    /*.solution_wrapper_box{*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    flex-direction: column;*/
    /*    padding: 30px;*/
    /*    width: 300px;*/
    /*    height: 350px;*/
    /*    !*background-color: rgba(255, 0, 0, 0.2);*!*/
    /*}*/


}



@media screen and (max-width: 1119px) {
    /*.solution_wrapper_box{*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    flex-direction: column;*/
    /*    padding: 30px;*/
    /*    width: 250px;*/
    /*    height: 350px;*/
    /*    !*background-color: rgba(255, 0, 0, 0.2);*!*/
    /*}*/


}




@media screen and (max-width: 1076px) {
    .main_wrapper_tailored_section_outer{
        padding: 50px;
        padding-left: 50px;
        padding-right: 50px;

    }
    .solution_wrapper_box h3{

        font-size: 16px;
    }
    .solution_wrapper_box p{

        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;


    }


}


@media screen and (max-width: 894px) {
    .solution_wrapper_box{
        padding: 10px;
        /*width: 240px;*/
        height: 300px;
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }
    .solution_wrapper_box .image_wrapper_tailored_box img{
        /*width: 80%;*/
    }
    .image_wrapper_tailored_box .car_image_mobility{
        width: 150px!important;
    }
    .solution_wrapper_box h3{
        font-size: 16px;
    }
    .solution_wrapper_box p{

        font-family: Poppins;
        font-size: 14px;



    }


}


@media screen and (max-width: 839px) {
    .solution_wrapper_box{

        padding: 10px;
        /*width: 340px;*/
        /*height: 300px;*/
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }
    .solution_wrapper_box .image_wrapper_tailored_box img{
        /*width: 80%;*/
    }
    .image_wrapper_tailored_box .car_image_mobility{
        width: 60% !important;
    }
    .solution_wrapper_box h3{
        font-size: 16px;
    }
    .solution_wrapper_box p{

        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;


    }

    .line_design{
        border: none;
    }

    .line_design_new{
        border-right: 0.5px solid #b4b1b1;
    }
}


@media screen and (max-width: 799px) {
    .solution_wrapper_box {
        padding: 10px;
        /*width: 300px;*/
        height: 320px;
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }




}

@media screen and (max-width: 767px) {
    .main_wrapper_tailored_section_outer{
        padding: 50px;
        padding-left: 80px;
        padding-right: 80px;

    }

    .main_wrapper_tailored_section_inner{
        padding-top: 0%;
        padding-bottom: 0%;
        padding-left: 4%;
        padding-right: 4%;
        box-shadow: 5px 5px 10px #A6B4C8;

    }
    .solution_wrapper_box {
        padding: 10px;
        /*width: 300px;*/
        height:auto;
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }
    .solution_wrapper_box .image_wrapper_tailored_box{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;


    }
    .solution_wrapper_box .image_wrapper_tailored_box img{
        width: 200px;
        padding-bottom: 0px;
    }
    .image_wrapper_tailored_box .car_image_mobility{
        width: 180px !important;

    }
    .solution_wrapper_box h3{
        font-size: 16px;
        padding-top: 20px;
    }
    .solution_wrapper_box p{

        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        color: #1B1C1F;
        text-align: center;
        padding-left: 0px;
        padding-right: 0px;


    }
    .line_design_new{
        border-right: none;

    }
    .bottom_line{
        border-bottom: 0.5px solid #b4b1b1;

    }
    .padding_style{
        padding: 20px;
    }


}

@media screen and (max-width: 719px) {
    .solution_wrapper_box {
        /*padding: 10px;*/
        /*width: 250px;*/
        /*height: 300px;*/
        /*background-color: rgba(255, 0, 0, 0.2);*/
    }

}

@media screen and (max-width: 619px) {
    .main_wrapper_tailored_section_outer .heading_tailored_main{
        font-size: 30px;
    }

    .solution_wrapper_box {

        padding: 0px;

        /*background-color: rgba(255, 0, 0, 0.2);*/
    }


}

@media screen and (max-width: 440px) {
    .main_wrapper_tailored_section_outer{
        padding: 30px;
        padding-left: 10px;
        padding-right: 10px;

    }
    .main_wrapper_tailored_section_outer .heading_tailored_main{
        font-size: 20px;

    }

}
.form_section_main_outer{
    padding: 50px;
    background-color: #FAFAFA;


}
.form_section_main_inner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.form_section_image_wrapper{
    width: 100%;

}
.form_section_image_wrapper img{
    width: 80%;

}
.form_section_content_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 40px;
    align-items: flex-start;
    /*background-color: rgba(255, 0, 0, 0.11);*/
}
.form_section_content_wrapper .form_heading_title{
    font-size: 28px;
    padding-bottom: 20px;
    font-weight: bold;
    font-family: Poppins;
    color: #000000;

}

.form_section_content_wrapper .form_heading_title span{
    color: #FF9900;
}


.namingSections_chex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input_field_chex_ride {
    background: rgb(255, 255, 255) !important;
    border: 1px solid #C1C1C1 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    width: 100% !important;
    margin: 0px auto 15px !important;
    padding: 15px 8px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: 'Poppins' !important;
    letter-spacing: 1px !important;

}

::-webkit-input-placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

:-ms-input-placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

::placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

.terms_conditionsContainer{
    display: flex;
    flex-direction: row;
}

.termsConditionsText{
    padding-left: 10px;
    font-size: 16px;
}

.button-wrapper_chex_ride {
    background:  #FF9900;
    border-radius: 5px !important;
    border: 1px solid #FF9900 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    /*line-height: 32px !important;*/
    text-align: center !important;
    color: #ffffff;

    text-decoration: none !important;
    cursor: pointer !important;
    display: block;
    width: 100% !important;
    margin: 0px auto !important;
    height: 44px !important;
}
.button-wrapper_chex_ride:hover{
    background-color: #ffffff;
    border: 1px solid #FF9900;
    color: #FF9900;


}
.button-wrapper_chex_ride:active{
    color: #FF9900!important;
    border: 1px solid #FF9900;
}



.newcheckmark_chex_ride {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e3e2e2;
    border:1px solid grey;
    border-radius: 3px;
}

.register-checkbox-text_chex_ride{
    color:black;
    padding-left: 4px;
}
.newcontainer_ride {
    /*text-align: center;*/
    /*position: relative;*/
    /*padding-left: 35px;*/
    /*margin-bottom: 12px;*/
    /*width: 20px;*/
    /*height: 20px;*/
    /*cursor: pointer;*/
    /*-webkit-user-select: none;*/
    /*-moz-user-select: none;*/
    /*-ms-user-select: none;*/
    /*user-select: none;*/
}
.newcontainer:hover input ~ .newcheckmark_chex_ride {
    background-color: #ccc!important;

}
.newcontainer input:checked ~ .newcheckmark_chex_ride {
    background-color: #fff;

}
.newcheckmark_chex_ride:after {
    content: '';
    position: absolute;
    display: none;
    height: 18px!important;
    width: 18px;
    border-radius: 2px;
    border:1px solid transparent;
    background-color: #d3d3d3;

}
.newcontainer input:checked ~ .newcheckmark_chex_ride:after {
    content: '\2713';
    display: block;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    /*margin-top: -10px;*/

    text-align: center;

    color: #ff7a00;
}


@media screen and (max-width: 1799px) {
    .form-item-style-chex_first_ride{
        width: 48%;
    }
}

@media screen and (max-width: 842px) {
    .form-item-style-chex_first_ride {
        width: 100%;
    }

    .namingSections_chex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

}

@media screen and (max-width: 510px){
    .register-checkbox-text_chex_ride{
        font-size: 12px;
    }

}



@media screen and (min-width: 1800px) {
    .namingSections_chex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.login-landing-signup_ride {
    display: "flex";
    margin-top: 15px;
}

@media screen and (min-width: 1440px) {
    .login-landing-signup_ride {
        display: "flex";
        margin-top: 3px;
    }
}



@media screen and (max-width:1020px) {
    .form_section_image_wrapper{
        width: 100%;

    }
    .form_section_image_wrapper img{
        width: 100%;

    }


}


@media screen and (max-width:820px) {
    .form_section_main_outer{
        /*background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662631072/Group_423_v53nfc.png");*/
        /*background-repeat: no-repeat;*/
        /*background-size: contain;*/
        padding:0px;
    }
    .form_section_main_inner{
        background-color: rgba(236, 236, 236, 0.7);
        padding: 30px;
    }
    .form_section_image_wrapper{
        width: 100%;
        display: none;

    }
    .form_section_content_wrapper{

    }


    .form_section_content_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        align-items: flex-start;
        /*background-color: rgba(255, 0, 0, 0.11);*/
    }
    .form_section_content_wrapper .form_heading_title{
        font-size: 28px;
        padding-bottom: 20px;
        font-weight: bold;
        font-family: Poppins;
        color: #000000;

    }


}

@media screen and (max-width: 600px) {
.form_section_content_wrapper{
    padding-left: 0px;
}
    
}

@media screen and (max-width: 380px) {
    .form_section_main_inner{
        padding: 10px;
    }

    .form_section_content_wrapper .form_heading_title{
        font-size: 20px;
    }

}





/*pricing wrapper ride start*/

.pricing_wrapper_main_ride{
    padding: 50px;
    background-color: #FAFAFA;
}
.pricing_wrapper_main_ride_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /*height: 400px;*/
}
.pricing_wrapper_main_ride_inner h2{
    font-size: 40px;
    font-family: Poppins;
    font-weight: bold;
    text-align: center;
}
.pricing_wrapper_main_ride_inner p{
    font-size: 20px;
    font-family: Poppins;
    font-weight: bold;
    text-align: center;
}

.pricing_wrapper_main_ride_inner .btn_pricing_ride{
    background-color: #1468BA;
    margin-top: 20px!important;
    color: #ffffff;
    border: 1px solid #1468BA;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}
.pricing_wrapper_main_ride_inner .btn_pricing_ride:hover{
    background-color: #ffffff;
    color: #1468BA;
    border: 1px solid #1468BA;
    transition: 0.6s;
}

.points_pricing_wrapper_main{
    border: 1px solid #FF9900;
    border-radius: 5px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    /*background-color: red;*/
    padding: 10px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px !important;
}
.points_pricing_wrapper_inner{
    /*display: flex;*/
    /*width: 100%;*/
    /*justify-content: space-between;*/
    /*align-items: center;*/
    /*flex-direction: row;*/
    /*background-color: red;*/
}
.points_pricing_wrapper_inner ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /*background-color: red;*/
    padding-left:0px;

}
.points_pricing_wrapper_inner ul li{
    list-style: none;
}



.point_wrapper_ride_style{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

}

.point_wrapper_ride_style p{
    font-size: 16px;
    font-family: Poppins;
    color: #000000;

}
.point_wrapper_ride_style p span{
    color: #FF7A00;
    font-weight: bold;

}


.pricing_card_main_outer_ride{
    padding-top: 50px;
    padding-bottom: 50px;
    width: 65%;

}

.pricing_cards_main_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}



@media screen and (max-width: 1139px) {
    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 16px;

    }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 85%;

    }

    
}




@media screen and (max-width: 960px) {
    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 16px;

    }
    .points_pricing_wrapper_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 20px;
        padding-bottom: 0px;
        padding-right: 20px;
        margin-bottom: 20px !important;
    }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 95%;

    }

}

@media screen and (max-width: 752px) {
    .points_pricing_wrapper_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 20px;
        padding-bottom: 0px;
        padding-right: 20px;
        margin-bottom: 20px !important;
    }


}


@media screen and (max-width: 713px) {
    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;

    }

}



@media screen and (max-width: 691px) {

    .pricing_cards_main_wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

    }
    .second_lists_inspection_style{
        margin-top: -15px;
    }
    .pricing_cards_main_wrapper .pricing_card_box_wrap{
        padding: 20px;
    }

    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 14px;

    }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 98%;

    }

    .points_pricing_wrapper_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 98%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
    }

}

@media screen and (max-width: 548px) {
    .pricing_card_main_outer_ride{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 98%;

    }

}


@media screen and (max-width: 422px) {
    .pricing_card_main_outer_ride{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;

    }
    .points_pricing_wrapper_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .points_pricing_wrapper_inner{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
}


@media screen and (max-width: 388px) {
    .pricing_wrapper_main_ride{
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #FAFAFA;
    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
.point_wrapper_ride_style p{
    font-size: 12px;
    text-align: left;
}

}




/*States Works Styling Start */

.states_section_main_wrapper_outer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.image_wrapper_states_cars {
    width: 80%;

}
.image_wrapper_states_cars img{
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 100%;

}


.content_wrapper_states_main{
    padding-left: 40px;
    width: 100%;
}
.content_inner_state_wrapper {
    width: 40%;


}
.content_inner_state_wrapper h2{
    color: #1B1C1F;
    font-size: 32px;
    font-family: Poppins;
    font-weight: bold;
}
.content_inner_state_wrapper h2 .orangeSpan {
    color: #FF9900;

}
.content_inner_state_wrapper h2 .blueSpan {
    color: #1468BA;
}


.content_inner_state_wrapper .states_content_data{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.states_content_data .unorder_lists ul li{
   list-style: none;
    color: #646464;
    font-size: 16px;
}


@media screen and (max-width: 1299px) {
    .content_inner_state_wrapper {
        width: 60%;


    }
    
}

@media screen and (max-width: 895px) {
    .content_inner_state_wrapper {
        width: 70%;


    }

}

@media screen and (max-width: 767px) {
    .content_inner_state_wrapper {
        width: 80%;


    }
    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 28px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 14px;
    }

}


@media screen and (max-width: 640px) {
    .states_section_main_wrapper_outer{
        padding: 20px;
    }
    .image_wrapper_states_cars{
        display: none;
    }
    .content_inner_state_wrapper {
        width: 80%;


    }
    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 26px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 14px;
    }

}


@media screen and (max-width: 398px) {
    .content_inner_state_wrapper {
        width: 100%;


    }
    .content_wrapper_states_main{
        padding-left: 0px;
        width: 100%;
    }

    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 22px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 12px;
    }

}





/*Chex Why Chex Ai wrapper styling start*/

.why_chex_section_ride{
    padding: 50px;

}
.main_wrapper_flex_chex{
    /*background-color: rgba(255, 0, 0, 0.36);*/
}
.why_chex_inner_section_wrapper_ride{

    padding-top: 5%;
    padding-bottom: 5%;

}

.straigt_ride_flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
.reverse_ride_flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
}


.why_chex_content_wrapper_ride{
    position: relative;


}


.reverse_chex_image_wrapper{
    position: absolute;
    top:-100%;
    right:0%;
    padding-left: 30%;
}
.straign_content_image_wrapper{
    position: absolute;
    top:-50%;
    left:-10%;
}
.chex_content_image_wrapper img{
    width: 70%;

}
.content_code_section{

}
.straigt_content_ride{
    padding-right: 30%;
}
.reverse_ride_content{
    padding-left: 30%;

}

.content_code_section h2{
    font-family: Poppins;
    font-size: 38px;
    color: #000000;

}

.content_code_section p{
    font-family: Poppins;
    font-size: 18px;

    color: #000000;

}
.content_code_section p span{

    color: #FF9900;
    font-weight: bold;

}

.why_chex_Image_wrapper_ride img{
    width: 70%;

}

@media screen and (max-width: 1290px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }
    
}

@media screen and (max-width: 1014px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }

    .content_code_section h2{
        font-family: Poppins;
        font-size: 34px;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 16px;

        color: #000000;

    }

    .straigt_content_ride{
        padding-right: 20%;
    }
    .reverse_ride_content{
        padding-left: 20%;

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        right:0%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-50%;
        left:-10%;
    }
    .chex_content_image_wrapper img{
        width: 60%;

    }

}


@media screen and (max-width: 915px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }

    .content_code_section h2{
        font-family: Poppins;
        font-size: 34px;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 16px;

        color: #000000;

    }

    .straigt_content_ride{
        padding-right: 20%;
    }
    .reverse_ride_content{
        padding-left: 20%;

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        left:-20%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-50%;
        left:-10%;
    }
    .chex_content_image_wrapper img{
        width: 60%;

    }

}




@media screen and (max-width: 757px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding: 40px;
    }

    .reverse_ride_flex{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    .content_code_section h2{
        font-family: Poppins;
        font-size: 34px;
        text-align: center;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 18px;
        text-align: center;

        color: #000000;

    }

    .straigt_content_ride{
        padding-right: 0%;
        padding-top: 10%;
    }
    .reverse_ride_content{
        padding-left: 0%;
        padding-top: 10%;

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        display: none;
        top:-100%;
        left:0%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-100%;
        display: none;
        left:0%;
    }
    .chex_content_image_wrapper img{
        width: 100%;

    }

    .why_chex_Image_wrapper_ride img{
        width: 100%;
        padding: 10%;

    }


}

@media screen and (max-width: 480px) {
    .why_chex_section_ride{
        padding: 20px;

    }
    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        left:0%;
        padding-left: 10%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-10%;
        left:0%;
    }
    .chex_content_image_wrapper img{
        width: 70%;

    }


    .why_chex_Image_wrapper_ride img{
        width: 100%;
        padding: 5%;

    }
    .content_code_section h2{
        font-family: Poppins;
        font-size: 26px;
        text-align: center;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 16px;
        text-align: center;

        color: #000000;

    }
}


@media screen and (max-width: 350px) {
    .why_chex_section_ride{
        padding: 10px;
        padding-top: 50px;
        padding-bottom: 50px;

    }
    .chex_content_image_wrapper img{
        display: none;

    }
    .content_code_section h2{
        font-family: Poppins;
        font-size: 20px;
        text-align: center;
        color: #000000;

    }

    .content_code_section p{
        font-family: Poppins;
        font-size: 12px;
        text-align: center;

        color: #000000;

    }


}
.section_pricing_micro_mobility{
    padding: 50px;
    background-color: #FAFAFA;

}
.inner_section_pricing_mobility{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 400px;

}

.inner_section_pricing_mobility h3{
    font-size: 30px;
    color: #000000;
    font-family: Poppins;
    font-weight: bold;

}
.inner_section_pricing_mobility p{
    font-size: 20px;
    color: #000000;
    font-family: Poppins;
    font-weight: 400;
    text-align: center;

}
.inner_section_pricing_mobility .btn_pricing_mobility{
    background-color: #1468BA;
    color: #ffffff;
    border: 1px solid #1468BA;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}
.inner_section_pricing_mobility .btn_pricing_mobility:hover{
    background-color: #ffffff;
    color: #1468BA;
    border: 1px solid #1468BA;
    transition: 0.6s;
}


@media screen and (max-width: 682px ) {
    .inner_section_pricing_mobility h3{
        font-size: 24px;
        color: #000000;
        font-family: Poppins;
        font-weight: bold;

    }
    .inner_section_pricing_mobility p{
        font-size: 16px;
        color: #000000;
        font-family: Poppins;
        font-weight: 400;
        text-align: center;

    }

}

@media screen and (max-width: 480px ) {
    .inner_section_pricing_mobility{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 300px;

    }
    .inner_section_pricing_mobility h3{
        font-size: 22px;
        color: #000000;
        font-family: Poppins;
        font-weight: bold;

    }
    .inner_section_pricing_mobility p{
        font-size: 14px;
        color: #000000;
        font-family: Poppins;
        font-weight: 400;
        text-align: center;

    }
    .inner_section_pricing_mobility .btn_pricing_mobility{
        background-color: #1468BA;
        color: #ffffff;
        border: 1px solid #1468BA;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }
}



@media screen and (max-width: 345px ) {
    .section_pricing_micro_mobility{
        padding: 20px;
        background-color: #FAFAFA;

    }
    .inner_section_pricing_mobility{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 300px;

    }
    .inner_section_pricing_mobility h3{
        font-size: 18px;
        color: #000000;
        font-family: Poppins;
        font-weight: bold;

    }
    .inner_section_pricing_mobility p{
        font-size: 12px;
        color: #000000;
        font-family: Poppins;
        font-weight: 400;
        text-align: center;

    }
    .inner_section_pricing_mobility .btn_pricing_mobility{
        background-color: #1468BA;
        color: #ffffff;
        border: 1px solid #1468BA;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.form_section_main_outer{
    padding: 50px;
    background-color: #FAFAFA;


}
.form_section_main_inner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.form_section_image_wrapper{
    width: 100%;

}
.form_section_image_wrapper img{
    width: 80%;

}
.form_section_content_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 40px;
    align-items: flex-start;
    /*background-color: rgba(255, 0, 0, 0.11);*/
}
.form_section_content_wrapper .form_heading_title{
    font-size: 28px;
    padding-bottom: 20px;
    font-weight: bold;
    font-family: Poppins;
    color: #000000;

}

.form_section_content_wrapper .form_heading_title span{
    color: #FF9900;
}


.namingSections_chex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input_field_chex_ride {
    background: rgb(255, 255, 255) !important;
    border: 1px solid #C1C1C1 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    width: 100% !important;
    margin: 0px auto 15px !important;
    padding: 15px 8px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: 'Poppins' !important;
    letter-spacing: 1px !important;

}

::-webkit-input-placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

:-ms-input-placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

::placeholder{
    color:#585757 !important;
    padding-left:10px;
    outline: none;

}

.terms_conditionsContainer{
    display: flex;
    flex-direction: row;
}

.termsConditionsText{
    padding-left: 10px;
    font-size: 16px;
}

.button-wrapper_chex_ride {
    background:  #FF9900;
    border-radius: 5px !important;
    border: 1px solid #FF9900 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    /*line-height: 32px !important;*/
    text-align: center !important;
    color: #ffffff;

    text-decoration: none !important;
    cursor: pointer !important;
    display: block;
    width: 100% !important;
    margin: 0px auto !important;
    height: 44px !important;
}
.button-wrapper_chex_ride:hover{
    background-color: #ffffff;
    border: 1px solid #FF9900;
    color: #FF9900;


}
.button-wrapper_chex_ride:active{
    color: #FF9900!important;
    border: 1px solid #FF9900;
}



.newcheckmark_chex_ride {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #e3e2e2;
    border:1px solid grey;
    border-radius: 3px;
}

.register-checkbox-text_chex_ride{
    color:black;
    padding-left: 4px;
}
.newcontainer_ride {
    /*text-align: center;*/
    /*position: relative;*/
    /*padding-left: 35px;*/
    /*margin-bottom: 12px;*/
    /*width: 20px;*/
    /*height: 20px;*/
    /*cursor: pointer;*/
    /*-webkit-user-select: none;*/
    /*-moz-user-select: none;*/
    /*-ms-user-select: none;*/
    /*user-select: none;*/
}
/*.image-style{*/
/*    width: 234px;*/
/*    height: 280px;*/
/*    left: 900px;*/
/*    top: 1200px;*/
/*}*/
.newcontainer:hover input ~ .newcheckmark_chex_ride {
    background-color: #ccc!important;

}
.newcontainer input:checked ~ .newcheckmark_chex_ride {
    background-color: #fff;

}
.newcheckmark_chex_ride:after {
    content: '';
    position: absolute;
    display: none;
    height: 18px!important;
    width: 18px;
    border-radius: 2px;
    border:1px solid transparent;
    background-color: #d3d3d3;

}
.newcontainer input:checked ~ .newcheckmark_chex_ride:after {
    content: '\2713';
    display: block;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    /*margin-top: -10px;*/

    text-align: center;

    color: #ff7a00;
}
.wrap-price-tab{
display: flex;
    flex-direction: row;
}
.back-circle{
    border-radius: 50%;
    background: rgba(255,242,221,0.2);
    width: 400px;
    height:400px;
    text-align:center ;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.Partner_content{
    text-align: left;
    margin-right: 25px;
    margin-left: 35px;
}
.Mobile_content{
    text-align: left;
    margin-right: 20px;
    margin-left: 22px;
}
.Digital_record_content{
    text-align: left;
    margin-right: 20px;
    margin-left: 20px;
}

@media screen and (max-width: 1799px) {
    .form-item-style-chex_first_ride{
        width: 48%;
    }
}

@media screen and (max-width: 842px) {
    .form-item-style-chex_first_ride {
        width: 100%;
    }

    .namingSections_chex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

}

@media screen and (max-width: 510px){
    .register-checkbox-text_chex_ride{
        font-size: 12px;
    }

}



@media screen and (min-width: 1800px) {
    .namingSections_chex{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.login-landing-signup_ride {
    display: "flex";
    margin-top: 15px;
}

@media screen and (min-width: 1440px) {
    .login-landing-signup_ride {
        display: "flex";
        margin-top: 3px;
    }
}



@media screen and (max-width:1020px) {
    .form_section_image_wrapper{
        width: 100%;

    }
    .form_section_image_wrapper img{
        width: 100%;

    }


}


@media screen and (max-width:820px) {
    .form_section_main_outer{
        /*background-image: url("https://res.cloudinary.com/techlingcompany/image/upload/v1662631072/Group_423_v53nfc.png");*/
        /*background-repeat: no-repeat;*/
        /*background-size: contain;*/
        padding:0px;
    }
    .form_section_main_inner{
        background-color: rgba(236, 236, 236, 0.7);
        padding: 30px;
    }
    .form_section_image_wrapper{
        width: 100%;
        display: none;

    }
    .form_section_content_wrapper{

    }


    .form_section_content_wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        align-items: flex-start;
        /*background-color: rgba(255, 0, 0, 0.11);*/
    }
    .form_section_content_wrapper .form_heading_title{
        font-size: 28px;
        padding-bottom: 20px;
        font-weight: bold;
        font-family: Poppins;
        color: #000000;

    }


}

@media screen and (max-width: 600px) {
    .form_section_content_wrapper{
        padding-left: 0px;
    }

}

@media screen and (max-width: 380px) {
    .form_section_main_inner{
        padding: 10px;
    }

    .form_section_content_wrapper .form_heading_title{
        font-size: 20px;
    }

}





/*pricing wrapper ride start*/

.pricing_wrapper_main_ride{
    padding: 50px;
    background-color: #FAFAFA;
}
.pricing_wrapper_main_ride_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /*height: 400px;*/
}
.pricing_wrapper_main_ride_inner h2{
    font-size: 40px;
    font-family: Poppins;
    font-weight: bold;
    text-align: center;
}
.pricing_wrapper_main_ride_inner p{
    font-size: 20px;
    font-family: Poppins;
    font-weight: bold;
    text-align: center;
}

.pricing_wrapper_main_ride_inner .btn_pricing_ride{
    background-color: #1468BA;
    margin-top: 20px!important;
    color: #ffffff;
    border: 1px solid #1468BA;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
}
.pricing_wrapper_main_ride_inner .btn_pricing_ride:hover{
    background-color: #ffffff;
    color: #1468BA;
    border: 1px solid #1468BA;
    transition: 0.6s;
}

.points_pricing_main{
    border: 1px solid #FF9900;
    border-radius: 5px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    height: 200px;
    margin-top: 60px;
    /*background-color: red;*/
    padding: 10px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px !important;
}
.points_pricing_wrapper_inner{
    /*display: flex;*/
    /*width: 100%;*/
    /*justify-content: space-between;*/
    /*align-items: center;*/
    /*flex-direction: row;*/
    /*background-color: red;*/
}
.points_pricing_wrapper_inner ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /*background-color: red;*/
    padding-left:0px;

}
.points_pricing_wrapper_inner ul li{
    list-style: none;
}



.point_wrap_style{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

}

.point_wrap_style p{
    font-size: 16px;
    font-family: Poppins;
    color: #000000;


}
.point_wrap_ride_style p span{
    color: #FF7A00;
    font-weight: bold;

}


.pricing_card_main_outer_ride{
    padding-top: 50px;
    padding-bottom: 50px;
    width: 65%;

}

.pricing_main_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}



@media screen and (max-width: 1139px) {
    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 16px;

    }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 85%;

    }


}

@media screen and (max-width: 1015px){
    .back-circle{
        border-radius: 50%;
        background: rgba(255,242,221,0.2);
        width: 350px;
        height:400px;
        text-align:center ;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .points_pricing_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 600px;
        height: 200px;
        margin-top: 60px;
        padding: 10px;
        padding-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 20px !important;
    }
    .point_wrap_style p{
        font-size: 12px;
        font-family: Poppins;
        color: #000000;


    }
    }

    @media screen and (max-width: 960px) {
    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 16px;

    }
    .points_pricing_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 550%;
        margin-left: 100px;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 20px;
        padding-bottom: 0px;
        padding-right: 20px;
        margin-bottom: 20px !important;
    }
        .point_wrap_style p{
            font-size: 10px;
            font-family: Poppins;
            color: #000000;
        }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 95%;

    }

}


@media screen and (max-width: 713px) {
    .wrap-price-tab{
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
    }
    .points_pricing_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin-right: 60px;
        align-items: center;
        width: 500px;
        height: 200px;
        margin-top: 60px;
        padding: 10px;
        padding-top: 25px;
        padding-left: 2px;
        padding-right: 2px;
        margin-bottom: 20px !important;
    }
    .point_wrap_style p{
        font-size: 10px;
        font-family: Poppins;
        color: #000000;
    }
    .pricing_main_wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

    }

}



@media screen and (max-width: 692px) {

    .pricing_main_wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;

    }
    .second_lists_inspection_style{
        margin-top: -15px;
    }
    .pricing_main_wrapper .pricing_card_box_wrap{
        padding: 20px;
    }

    .pricing_wrapper_main_ride_inner h2{
        font-size: 32px;

    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 14px;

    }
    .point_wrap_style p{
        font-size: 10px;
        font-family: Poppins;
        color: #000000;
    }

    .pricing_card_main_outer_ride{
        padding-top: 50px;
        padding-bottom: 50px;
        width: 98%;

    }

}
@media screen and (max-width: 614px) {
    .points_pricing_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 390px;
        height: 200px;
        margin-top: 60px;
        padding: 10px;
        padding-top: 25px;
        padding-left: 2px;
        padding-right: 2px;
        margin-bottom: 20px !important;
    }
    .point_wrap_style p{
        font-size: x-small;
        font-family: Poppins;
        color: #000000;
    }

}

@media screen and (max-width: 548px) {
    .pricing_card_main_outer_ride{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 98%;

    }

}


@media screen and (max-width: 422px) {
    .pricing_card_main_outer_ride{
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;

    }
    .points_pricing_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        /*background-color: red;*/
        padding: 10px;
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .points_pricing_wrapper_inner{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
}

    @media screen and (max-width: 388px) {
    .pricing_wrapper_main_ride{
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #FAFAFA;
    }
    .pricing_wrapper_main_ride_inner p{
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .point_wrapper_style p{
        font-size: xx-small;
        text-align: left;
    }
        .points_pricing_main{
            border: 1px solid #FF9900;
            border-radius: 5px;
            flex-direction: column;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width: 200px;
            height: 250px;

        }

}
@media screen and (max-width: 310px){
    .points_pricing_main{
        border: 1px solid #FF9900;
        border-radius: 5px;
        flex-direction: column;
        display: flex;
        margin-right: 100px;
        justify-content: flex-start;
        align-items: flex-start;
        width: 150px;
        height: 300px;

    }
    .point_wrapper_style p{
        font-size: 5px;
        text-align: left;
    }
}



/*States Works Styling Start */

.states_section_main_wrapper_outer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.image_wrapper_states_cars {
    width: 80%;

}
.image_wrapper_states_cars img{
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 100%;

}


.content_wrapper_states_main{
    padding-left: 40px;
    width: 100%;
}
.content_inner_state_wrapper {
    width: 40%;


}
.content_inner_state_wrapper h2{
    color: #1B1C1F;
    font-size: 32px;
    font-family: Poppins;
    font-weight: bold;
}
.content_inner_state_wrapper h2 .orangeSpan {
    color: #FF9900;

}
.content_inner_state_wrapper h2 .blueSpan {
    color: #1468BA;
}


.content_inner_state_wrapper .states_content_data{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.states_content_data .unorder_lists ul li{
    list-style: none;
    color: #646464;
    font-size: 16px;
}


@media screen and (max-width: 1299px) {
    .content_inner_state_wrapper {
        width: 60%;


    }

}

@media screen and (max-width: 895px) {
    .content_inner_state_wrapper {
        width: 70%;


    }

}

@media screen and (max-width: 767px) {
    .content_inner_state_wrapper {
        width: 80%;


    }
    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 28px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 14px;
    }

}


@media screen and (max-width: 640px) {
    .states_section_main_wrapper_outer{
        padding: 20px;
    }
    .image_wrapper_states_cars{
        display: none;
    }
    .content_inner_state_wrapper {
        width: 80%;


    }
    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 26px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 14px;
    }

}


@media screen and (max-width: 398px) {
    .content_inner_state_wrapper {
        width: 100%;


    }
    .content_wrapper_states_main{
        padding-left: 0px;
        width: 100%;
    }

    .content_inner_state_wrapper h2{
        color: #1B1C1F;
        font-size: 22px;

    }
    .states_content_data .unorder_lists ul li{

        font-size: 12px;
    }
    .points_pricing_main{
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 250px;
        margin-right: 80px;

    }

}





/*Chex Why Chex Ai wrapper styling start*/

.why_chex_section_ride{
    padding: 50px;

}
.main_wrapper_flex_chex{
    /*background-color: rgba(255, 0, 0, 0.36);*/
}
.why_chex_inner_section_wrapper_ride{

    padding-top: 5%;
    padding-bottom: 5%;

}

.straigt_ride_flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
.reverse_ride_flex{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
}


.why_chex_content_wrapper_ride{
    position: relative;


}


.reverse_chex_image_wrapper{
    position: absolute;
    top:-100%;
    right:0%;
    padding-left: 30%;
}
.straign_content_image_wrapper{
    position: absolute;
    top:-50%;
    left:-10%;
}
.chex_content_image_wrapper img{
    width: 70%;

}
.content_section{

}
.straigt_co_ride{
    padding-right: 30%;
}
.reverse_content{
    /*padding-left: 30%;*/

}

.content_section h2{
    font-family: Poppins;
    font-size: 38px;
    color: #000000;

}

.content_section p{
    font-family: Poppins;
    font-size: 18px;
    color: #000000;

}
.content_section p span{

    color: #FF9900;
    font-weight: bold;

}

.why_chex_Image_wrapper_ride img{
    width: 70%;
}
.chex_Img_wrapper img{
    width: 70%;


}

@media screen and (max-width: 1290px) {
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }

}

@media screen and (max-width: 1014px) {
    .back-circle{
        border-radius: 50%;
        background: rgba(255,242,221,0.2);
        width: 360px;
        height: 360px;
        text-align:center ;
    }
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }

    .content_section h2{
        font-family: Poppins;
        font-size: 34px;
        color: #000000;

    }

    .content_section p{
        font-family: Poppins;
        font-size: 16px;
        color: #000000;

    }

    .straigt_co_ride{
        padding-right: 20%;

    }
    .reverse_content{
        /*padding-left: 20%;*/

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        right:0%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-50%;
        left:-10%;
    }
    .chex_content_image_wrapper img{
        width: 60%;

    }

}


@media screen and (max-width: 915px) {
    .back-circle{
        border-radius: 50%;
        background: rgba(255,242,221,0.2);
        width: 290px;
        height: 290px;
        text-align:center ;
    }
    .straigt_ride_flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 50px;
    }

    .content_section h2{
        font-family: Poppins;
        font-size: 29px;
        color: #000000;

    }

    .content_section p{
        font-family: Poppins;
        font-size: 14px;
        color: #000000;

    }

    .straigt_co_ride{
        padding-right: 20%;
    }
    .reverse_content{
        /*padding-left: 20%;*/

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        left:-20%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-50%;
        left:-10%;
    }
    .chex_content_image_wrapper img{
        width: 60%;

    }

}




@media screen and (max-width: 757px) {
    .back-circle{
        border-radius: 50%;
        background: rgba(255,242,221,0.2);
        width: 330px;
        height: 330px;
        text-align:center ;
    }
    .Partner_content{
        text-align: left;
        margin-right: 25px;
        margin-left: 40px;
    }
    .Mobile_content{
        text-align: left;
        margin-right: 20px;
        margin-left: 53px;
    }
    .straigt_ride_flex{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding: 40px;
    }

    .reverse_ride_flex{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    .content_section h2{
        font-family: Poppins;
        font-size: 34px;
        color: #000000;

    }

    .content_section p{
        font-family: Poppins;
        font-size: 18px;

        color: #000000;

    }

    .straigt_co_ride{
        padding-right: 0%;
        padding-top: 10%;
    }
    .reverse_content{
        padding-left: 0%;
        /*padding-top: 10%;*/

    }

    .reverse_chex_image_wrapper{
        position: absolute;
        display: none;
        top:-100%;
        left:0%;
        padding-left: 30%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-100%;
        display: none;
        left:0%;
    }
    .chex_content_image_wrapper img{
        width: 100%;

    }

    .why_chex_Image_wrapper_ride img{
        width: 100%;
        padding: 10%;

    }
    .chex_Img_wrapper img{
        width: 120%;
        padding: 10%;

    }


}

@media screen and (max-width: 480px) {
    .why_chex_section_ride{
        padding: 20px;

    }
    .reverse_chex_image_wrapper{
        position: absolute;
        top:-50%;
        left:0%;
        padding-left: 10%;
    }
    .straign_content_image_wrapper{
        position: absolute;
        top:-10%;
        left:0%;
    }
    .chex_content_image_wrapper img{
        width: 70%;

    }


    .why_chex_Image_wrapper_ride img{
        width: 100%;
        padding: 5%;

    }
    .chex_Img_wrapper img{
        width: 100%;
        padding: 5%;

    }
    .content_section h2{
        font-family: Poppins;
        font-size: 26px;
        color: #000000;

    }

    .content_section p{
        font-family: Poppins;
        font-size: 16px;

        color: #000000;

    }
}


@media screen and (max-width: 350px) {
    .back-circle{
        border-radius: 50%;
        background: rgba(255,242,221,0.2);
        width: 220px;
        height: 220px;
        text-align:center ;
    }
    .why_chex_section_ride{
        padding: 10px;
        padding-top: 50px;
        padding-bottom: 50px;

    }
    .chex_content_image_wrapper img{
        display: none;

    }
    .content_section h2{
        font-family: Poppins;
        font-size: 20px;
        color: #000000;

    }

    .content_section p{
        font-family: Poppins;
        font-size: 12px;
        color: #000000;

    }


}
.veh-inspection-chexai_text-logo{
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0;
}


.inspection-image-card{
    width: 100%;
    height: 140px;
    border-radius: 10px;
    object-fit: initial;
}

.main_button_scooter_wrapper{
    padding-left: 30px;
    padding-right: 30px;
}
.inspection-button-style-scooter {
    background-color: #ff7a00;
    width:100%;
    padding: 7px;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 100px;
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    margin-top: 15px;
    cursor: pointer;
    height: 54px !important;
}

.heading_scooter_image h4{
    padding-top: 20px;
    font-size: 18px;
    font-weight: bold;
    font-family: Poppins;
    text-align: center;
}

.loader_wrapper_center{
    display: flex;
    height: 200px;
    justify-content: center;
    align-items: center;

}
.intro-mainBgss {
    background: linear-gradient(182.55deg, rgba(24, 118, 204, 0.1), rgba(0, 27, 81, 1) 115.19%), rgba(25, 26, 27, 0.1);
    position: relative;
    width: 100%;
    height: 100%;
}

.intro-imageBackground-main-reupoad {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: url(/static/media/scooter.8f561c08.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}
@media screen and (min-width: 768px) {
    .intro-imageBackground-main-reupoad{
        background-size: cover;
        background-position: center center;
    }
}


.gotoarticle :hover{
    color: #1468BA !important ;
}
.lisy{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.gotoarticle{ 
    color: rgb(26, 25, 25); 
    cursor: pointer;
    float:right;
}
.main--containerS{
    margin-left:3%;
}
.main--containerS h1{
    margin-left:3%;
}
.blog-card-manage
{
   height:470px;
   width: 91%;
   margin-top: 7%;
   margin-bottom: 20px;
}
.blog-card-manage:hover {
    box-shadow: 0 2px 8px rgb(228, 228, 231); 
  }
.publishbyat{
     display: flex; justify-content: flex-end
}
@media only screen and (max-width:991px) {
    .blog-card-manage
    {
       /* height:650px; */
       left:0%;
       width: 96%;
       margin-top: 7%;
       /* border-radius: 20px; */
    }
    .main--containerS{
        margin-left:3%;
    }
    
}

@media only screen and (max-width:576px) {
    .publishbyat{
        display: flex; justify-content: flex-start
   }
    
}


    
.header-stylingd{
    box-shadow:2px 2px 30px #e9e7e7;
    height:100px;
}
.Blog-images {
  position:relative;
  width:100%;

}
.blog-detail-images{
  width: 100%;
   height: 570px;
   object-fit: contain;
}
.DateContentS{
  font-size:13px;
  margin-top:4%;
  font-weight: 700;
  color: #fff;
}
.ContentStylingS{ 
  margin-top: 5% ;
  /* text-align:justify; */
  margin-left:5%;
  margin-right:5%
}
.Blog-title-styling{
  font-weight:700;
  font-size:39px;
  color: #fff; 
  /* margin-right: 4%;  */
  width:90%
}
.publishbytextstyle{
  float:right;
  margin-right:5%;
  font-size:13px
}
@media only screen and (max-width:1370px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-4.3% */
  }
  .Blog-title-styling{
    font-weight:700;
    font-size:36px;
    color: #fff;  
  }
  .blog-detail-images{
    width:100%;
    height:520px;
  }
 
}
@media only screen and (max-width:1237px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-4.3% */
  }
  .Blog-title-styling{
    font-weight:700;
    font-size:36px;
    color: #fff;  
  }
  .blog-detail-images{
    width:100%;
    height:510px;
  }
 
}
@media only screen and (max-width:1127px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-4.5% */
  }
  .Blog-title-styling{
    font-weight:700;
    font-size:34px;
    color: #fff;  
  }
  .blog-detail-images{
    width:100%;
    height:480px;
  }
}
@media only screen and (max-width:1040px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-4.9% */
  }
}
@media only screen and (max-width:1001px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-5.1% */
  }
}
@media only screen and (max-width:950px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-5.4% */
  }
}
@media only screen and (max-width:880px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-5.7% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:31px;
    color: #fff;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:470px;
  }

}
@media only screen and (max-width:830px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-6% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:28px;
    color: #fff;
    /* color: rgb(26, 25, 25); */
  }
}
@media only screen and (max-width:769px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-6.6% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:25px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:460px;
  }
}
@media only screen and (max-width:720px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-7%
     */
  }
  .ContentStylingS{ 
    margin-top: 5% ;
    margin-left:5%;
    margin-right:5%;
    /* margin-top:2%; */
  }
}
@media only screen and (max-width:670px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-7.5% */
  }
  .blog-detail-images{
    width:100%;
    height:430px;
  }
}
@media only screen and (max-width:620px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-8.2% */
  }
}
@media only screen and (max-width:588px) {
  
  .Blog-title-styling{
    font-weight:700;
    font-size:23px;
  }
}
@media only screen and (max-width:576px) {
  
  .DateContentS{
    font-size:11px;
    margin-top:2%;
  }
  .ContentStylingS{ 
    margin-top: 5% ;
    margin-left:5%;
    margin-right:5%;
    /* margin-top:2%; */
  }
  .publishbytextstyle{
    float:right;
    margin-right:5%;
    font-size:9px
  }
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-8.9% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:21.5px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:400px;
  }
}

@media only screen and (max-width:540px) {
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:20px;
    /* color: rgb(26, 25, 25); */
  }
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-9.3% */
  }
  .blog-detail-images{
    width:100%;
    height:380px;
  }
}
@media only screen and (max-width:497px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-10% */
  }
}
@media only screen and (max-width:479px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-10.4% */
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:18.5px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:360px;
  }
}
@media only screen and (max-width:458px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-11% */
  }
}
@media only screen and (max-width:439px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-11.5% */
  }
}
@media only screen and (max-width:420px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-12% */
  }
  .DateContentS{
    /* color: black; */
    
    font-size:10px;
    margin-top:2%;
  }

  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:16px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:340px;
  }
}
@media only screen and (max-width:400px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-12.5% */
  }
  .blog-detail-images{
    width:100%;
    height:325px;
  }
  .ContentStylingS{ 
    margin-top: 5% ;
    margin-left:5%;
    margin-right:5%;
    /* margin-top:2%; */
  }
}
@media only screen and (max-width:380px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-13.2% */
  }
}
@media only screen and (max-width:362px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-14% */
  }
  .DateContentS{
    /* color: black; */
    font-size:8px;
    margin-top:2%;
  }
  .Blog-title-styling{
    font-weight:700;
    /* text-align:justify; */
    font-size:15px;
    /* color: rgb(26, 25, 25); */
  }
  .blog-detail-images{
    width:100%;
    height:317px;
  }

}
@media only screen and (max-width:330px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-15% */
  }
  .blog-detail-images{
    width:100%;
    height:296px;
  }

}
@media only screen and (max-width:320px) {
  .Blog-images {
    position:relative;
    width:100%;
    /* margin-top:-16% */
  }
}







.faqsContainerStyle{

    padding-left: 100px;
    padding-right:100px;
}

.faqsDivWrapper{
    background-color: #fff;
    margin-bottom: 10px;
    border: 1px solid black;
}


@media screen and (max-width: 767px){
    .faqsContainerStyle{

        padding-left: 60px;
        padding-right:60px;
    }

}

@media screen and (max-width: 460px){
    .faqsContainerStyle{

        padding-left: 30px;
        padding-right:30px;
    }

    .contactText_faqs{
        font-size: 18px!important;
        font-weight: bold;
    }
}
.contact_headingContainer{
  background-color: transparent;
  /* padding:10px; */
  /* padding-top: 20px; */
  padding-bottom: 20px;
  text-align: center;
}
.contactText{
  font-size: 40px;
  padding-top: 100px;
  color: black;

}
.form-container_contact{
  width: 100%;
}

.main_contactWrap{ 
  background-size: cover;
  background-repeat: no-repeat;
}

.feedbackContainerMain{
  background-color: #fff;
  margin-top: 30px;

  padding:20px;
  margin-left: 0px;
  padding-right: 0px;
}
.feedbackHeading{
  font-weight: 700;
  font-family: Roboto;
  letter-spacing: 2px;
  font-size: 35px;
}
.paragraphfeedback{

  font-family: Roboto;
  letter-spacing: 2px;
  padding-right: 10px;


  font-size: 16px;
  color: #716f6f;
}



.input-field_contactUs{
  background:white !important;
  border-bottom: 1px solid #E3E7F2 !important;
  border-radius: 1px !important;
  width: 100% !important;
  margin: 0px auto 15px !important;
  padding: 15px 8px !important;
  font-style: normal !important;
  box-shadow: none;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: 'Poppins' !important;
  letter-spacing: 1px !important;
}
.text_area_contact{
  width: 100% !important;
}
::-webkit-input-placeholder{
  color:#585757 !important;
  padding-left:10px;
  outline: none;

}
:-ms-input-placeholder{
  color:#585757 !important;
  padding-left:10px;
  outline: none;

}
::placeholder{
  color:#585757 !important;
  padding-left:10px;
  outline: none;

}
.button-wrapper_contactus{
  background-color: #fff!important;
  border-radius: 0px!important;
  border: 2px solid black;
  margin-top:20px;
  margin-bottom: 20px;
  font-style: normal;
  height: auto!important;
  font-weight: 900;
  font-size: 15px;
  text-align: center;
  color: black !important;
  padding: 16px 70px;
  text-decoration: none;
  cursor: pointer;
}
.button-wrapper_contactus:hover{
  transition: 0.8s;
  background-color: #000 !important;
  border: 2px solid black;
  color: white !important;
}
.button-wrapper_contactus:focus {
  border: 2px solid black;
}

.button-wrapper_contactus-loading{
  background-color: #fff!important;
  border-radius: 0px!important;
  border: 2px solid black;
  margin-top:20px;
  margin-bottom: 20px;
  font-style: normal;
  height: auto!important;
  font-weight: 900;
  font-size: 15px;
  text-align: center;
  color: black !important;
  padding: 14px 85px;
  text-decoration: none;
  cursor: pointer;
}
/* .button-wrapper_contactus-loading:hover{
  transition: 0.8s;
  background-color: #000 !important;
  border: 2px solid black;
  color: white !important;
} */
/* .button-wrapper_contactus-loading:focus {
  border: 2px solid black;
} */
/* @media screen and (max-width: 1440px){

  .feedbackContainerMain{
    margin-left: 20px;
    padding-right: 400px;
  }

}

@media screen and (max-width: 1100px){

  .feedbackContainerMain{
    margin-left: 20px;
    padding-right: 200px;
  }

}
@media screen and (max-width: 1033px){

  .feedbackContainerMain{
    margin-left: 20px;
    padding-right: 100px;
  }

} */
/* @media screen and (max-width: 1100px){

  .feedbackContainerMain{
    margin-left: 20px;
    padding-right: 60p/x;
  }

} */

@media screen and (max-width: 767px) {
  .paragraphfeedback{
    text-align: center;
    font-size: 13px;
    padding-right: 10px;
  }

  .feedbackContainerMain{
    margin-left: 30px;
    margin-right: 30px;
    /*padding-right: 200px;*/
  }
  .form-container_contact{
    width: 100%;
  }


}

@media screen and (max-width: 608px){
  .input-field_contactUs{
    width: 80% !important;
  }
  .feedbackHeading {
    font-size: 24px;
  }
  .contactText {
    font-size: 30px;
    padding-top: 70px;
  }

}
@media screen and (max-width: 555px){
  .input-field_contactUs{
    width: 100% !important;
  }




  .feedbackContainerMain{
    margin-left: 10px;
    margin-right: 10px;
    padding:10px;
    /*padding-right: 20px;*/
  }

}
.input-field-style {
  background-color: white;
  border-bottom: 1px solid black;
  box-shadow: none;
  border-radius: 0px;
  padding: 4px;
  margin-top: 0px;
}
.input-field-style:focus {
  border: 1px solid black;
  box-shadow: -1px 2px 14px 2px rgba(0,0,0,0.07);
}
.input-field-label-contact-us {
  font-weight: 900;
  font-size: 16px;
  margin-top: 5px;
}
.contact-us-textArea {
  resize: none;
}
.contact-us-textArea:focus {
  box-shadow: -1px 2px 14px 2px rgba(0,0,0,0.07);
  outline: none;
}
.recapcha_desgin {
  margin-top: 30px;
}

.shahveer {
  color: red;
}
