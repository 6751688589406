.chex_solution_wrapper_main{
    padding: 30px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 50px;
}
.chex_solution_wrapper_main p{
    font-size: 20px;
    font-family: Poppins;
    font-weight: 500;
    text-align: center;
    color: #000000;
    padding-bottom: 20px;
    padding-left: 250px;
    padding-right: 250px;


}

.chex_solution_wrapper_main p span{
    color: #FF9900;

}

.chex_solution_wrapper_main .chex_solution_image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.chex_solution_wrapper_main .chex_solution_image img{
    width: 50%;

}


@media screen and (max-width: 980px){
    .chex_solution_wrapper_main p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 500;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
        padding-left: 120px;
        padding-right: 120px;


    }


}

@media screen and (max-width: 767px){
    .chex_solution_wrapper_main p{
        font-size: 20px;
        font-family: Poppins;
        font-weight: 500;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;


    }


}

@media screen and (max-width: 520px){

    .chex_solution_wrapper_main p{
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;


    }

    .chex_solution_wrapper_main .chex_solution_image{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    .chex_solution_wrapper_main .chex_solution_image img{
        width: 70%;

    }



}


@media screen and (max-width: 400px){
    .chex_solution_wrapper_main{
        padding: 30px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 20px;
    }
    .chex_solution_wrapper_main p{
        font-size: 14px;
        font-family: Poppins;
        font-weight: 500;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;


    }


}