.home-main-Container{
    width: 100%;
    /*background-color:  #F4F9FD;*/
    background-color: #FFFFFF;
}
.badge-container_home{
    width: 100%;
    height: 650px;
    background-color: #2a2a2a;
    /* background-image: url("https://res.cloudinary.com/techling/image/upload/v1640334033/uberBanner_zvx139.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom:80px;
}
.badge-image-inner-container{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    /*background-color: #8c9dae;*/
}
.badge-inner-left-home{
    width: 45%;

    height: 50%;
}
.badge-main-heading{
    font-family: Poppins;
    font-size: 44px;
    line-height: 53px;
    text-align: left;
    font-weight: bolder;
    color: #FFFFFF;
}
.badge-main-para_homeContent{
    font-family: Poppins;
    font-size: 20px;
    padding-right: 40px;
    /*line-height: 43px;*/
    text-align: left;
    color: #C4C4C4;
}
.contact-submit-btn{
    background: #2584e0 !important;
    border: none !important;
    outline: none !important;
    width: 30% !important;
    font-style: normal;
    font-size: 16px;
    float: left;
}
.badge-inner-right{
    width: 60%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.badge-inner-right > img{

    height: 400px;
    width: 500px;
}
.overlay-card-container{
    width: 100%;
    margin-top: -150px;
}
.overlay-card-inner-container{
    width: 80%;
    margin: 0 auto;
    /*background-color: #F4F9FD;*/
}

@media screen and (max-width: 600px) {
    .overlay-card-inner-container{

        width:100%;
        padding:10px;
        /*margin-left:5px;*/

    }

}

.images_about{
    width:100px;
    height: 80px;
}

@media screen and (max-width: 912px){
    .images_about{
        width:80px;
        height: 70px;
    }
    .badge-main-para_homeContent{
        font-family: Poppins;
        font-size: 16px;
        padding-right: 10px;
        /*line-height: 43px;*/
        text-align: left;
        color: #C4C4C4;
    }
}

@media screen and (max-width: 821px){
    .images_about{
        width:50px;
        height: 60px;
    }
    .badge-main-heading{
        font-size: 24px!important;
        line-height:30px !important;
    }
    .badge-main-para_homeContent{
        font-family: Poppins;
        font-size: 14px;
        padding-right: 20px;
        /*line-height: 43px;*/
        text-align: left;
        color: #C4C4C4;
    }
}
@media screen and (max-width: 767px){
    .images_about{
        width:130px;
        height: 120px;
    }
    .badge-main-para_homeContent{
        font-family: Poppins;
        font-size: 14px;
        padding-right: 0px;
        text-align: center;
        /*line-height: 43px;*/

        color: #C4C4C4;
    }

}
@media screen and (max-width: 445px){
    .images_about{
        width:100px;
        height: 80px;
    }
}
@media screen and (max-width: 392px){
    .images_about{
        width:80px;
        height: 70px;
    }
}
/*@media screen and (max-width: 767px){*/
/*    .badge-inner-left-home{*/
/*        margin-top:-100px;*/
/*        width: 10%;*/
/*        !*height: 50%;*!*/
/*    }*/

/*}*/

@media screen and (max-width: 767px){
    .badge-container_home{
        width: 100%;
        height: 400px;
        /* background-image: url("https://res.cloudinary.com/techling/image/upload/v1640334033/uberBanner_zvx139.png"); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom:40px;
        background-color: black;
        display: block;
    }
    /*.badge-inner-left{*/
    /*    width: 100%;*/
    /*    !*height: 50%;*!*/
    /*}*/
}

@media screen and (max-width: 575px) {
    .badge-container_home {
        padding-left: 30px;
        padding-right: 30px;
    }

}
.chexWorks-main-container{
    width: 100%;
    padding-bottom: 80px;
}
.chexwork-inner-container{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
}
.chaxwork-heading_home{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
}
.chaxwork-heading-span_home{
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 81px;
    letter-spacing: 0em;
    text-align: center;
    color: #FF7A00;
}
@media screen and (max-width: 420px) {
    .chaxwork-heading_home{
        font-family: Poppins;
        font-size: 30px!important;
        font-style: normal;
        font-weight: 600;
        line-height: 81px;
        letter-spacing: 0em;
        text-align: center;
    }
    .chaxwork-heading-span_home{
        font-family: Poppins;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 81px;
        letter-spacing: 0em;
        text-align: center;
        color: #FF7A00;
    }
}


@media screen and (max-width: 312px) {

    .badge-main-para_homeContent{

        font-size: 12px;
        padding-right: 0px;
        text-align: center;
    }
    .chaxwork-heading_home{
        font-family: Poppins;
        font-size: 22px!important;
        font-style: normal;
        font-weight: 600;
        line-height: 81px;
        letter-spacing: 0em;
        text-align: center;
    }
    .chaxwork-heading-span_home{
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 81px;
        letter-spacing: 0em;
        text-align: center;
        color: #FF7A00;
    }
}

.chexworks-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
}
.chexwork-img{
    height: 250px;
    width: 250px;
}
.chexwork-image-title{
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-style: normal;
    line-height: 115.5%;
    text-align: center;
    margin-top: 10px;
    max-width: 250px;
}
.chexwork-image-desc{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin-top: 10px;
    color: #6B717B;
    text-align: center;
    max-width: 250px;
}
.chexwork-image-getStarted-span{
    color: #FF7A00;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}
.chexwork-image-payment-span{
    color: #6B717B;
    font-weight: bolder;
    font-size: 15px;
    font-style: normal;
}
.chex-demo-video-main-contaier{
    width: 100%;
    background-color: #FFFFFF;
}
.chex-demo-video-container{
    width: 80%;
    height: 530px;
    background: url("../../Assets/videoBg.png");
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.chex-demo-video{
    border-radius: 3% ;
    height: 380px;
    width: 674px;
    border: none;
    z-index: 1;
}
.about-chex-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
}
.about-chex-left{
    width: 50%;
    padding-top: 50px;
    padding-bottom: 50px;
}
.chex-about-left-inner{
    width: 85%;
    float: right;
}
.about-chex-left-title{
    font-family: Poppins, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: bolder;
    line-height: 44px;
    letter-spacing: 1px;
}
.about-chex-left-title-span{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #FF7A00;
}
.about-chex-container .about-chex-left .chex-about-left-inner > p{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 160%;
    color: #6B717B;
    letter-spacing: 1px;
    margin-bottom: 5%;
}
.about-chex-inner-left-desc-span{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: #FF7A00;
}
.about-chex-right{
    width: 50%;
    background-color: #FFF8F1;
    height: 400px;
    margin-top: -200px;
    padding-top: 13%;
}
.about-chex-right-inner{
    width: 80%;
    background-color: aqua;
    height: 300px;
    margin: 0 auto;
}
.about-chex-right > h1{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #151922;
    margin-left: 20px;
}
.about-pricing-card-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}
.arrow-container{
    background-color: aqua;
    margin-top: -5%;
}
.review_container_main{
    width: 100%;

    background-color: #FFFFFF;
}
.main_section_review{
   background-color: #FFFFFF;
    margin-bottom: 30px;

    position: relative;
    margin-top: 30px;

}

@media screen and  (max-width: 767px){
    .main_section_review{
        margin-bottom: 0px;
    }

}
.review_container_main{
    width: 100%;
    background-color: #FFFFFF;

    padding-top: 0px;
    margin-bottom: 30px;
}
.rec.rec-arrow-right {
    background-color: #F4F9FF;
    border-radius: 30px;
    position: absolute;
    right:6%;
    bottom:-10%;

}
.rec.rec-arrow:hover{
    background-color: #F4F9FF!important;
    color:black!important;
}
.rec.rec-arrow:focus{
    background-color: #F4F9FF!important;
    color:black!important;
}

.rec.rec-arrow:hover{
    background-color: #F4F9FF;
}
.rec.rec-arrow-left{

    background-color:#F4F9FF;
    border-radius: 30px;
    z-index: 1;
    bottom:-10%;
    position: absolute;

    right: 10%;
}


@media only screen and (max-width: 1400px) {
    .badge-inner-left-home{
        width: 40%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color:green;*/
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 40px;
        line-height: 53px;
        text-align: left;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 35% !important;
        font-style: normal;
        font-size: 16px;
        float: left;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 80%;
        display: flex;
    }
    .badge-inner-right > img{
        height: 350px;
        width: 450px;
    }


}

@media only screen and (max-width: 1200px) {
    .badge-inner-left-home{
        width: 40%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color:red;*/
    }
    .badge-inner-left-home{
        width: 45%;
        height: 50%;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 36px;
        line-height: 53px;
        text-align: left;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 40% !important;
        font-style: normal;
        font-size: 16px;
        float: left;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 70%;
        display: flex;

    }
    .badge-inner-right > img{
        height: 350px;
        width: 450px;
    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 80px;
    }
    .chexwork-img{
        height: 200px;
        width: 200px;
    }
    .chexwork-image-title{
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 115.5%;
        text-align: center;
        margin-top: 10px;
        max-width: 200px;
    }
    .chexwork-image-desc{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-top: 10px;
        color: #6B717B;
        text-align: center;
        max-width: 200px;
    }
    .rec.rec-arrow-right {


        right:10%;


    }
    .rec.rec-arrow-left{

        right: 17%;

    }
}
@media screen and (max-width: 500px){
    .carousel_div_main {
        width: 100% !important;
        margin-top: 10px;
    }
}
@media screen and (max-width: 413px){
    .carousel_div_main {
        width: 70% !important;
        margin-top: 10px;
    }
}
@media screen and (max-width: 1024px) {
    .badge-inner-left-home{
        width: 40%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto;
        /*background-color:blue;*/
    }
    .badge-inner-left-home{
        width: 50%;
        height: 50%;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 27px;
        line-height: 53px;
        text-align: left;
        font-weight: bolder;
        color: #FFFFFF;
    }
    /*.contact-submit-btn {*/
    /*    background: #2584e0 !important;*/
    /*    border: none !important;*/
    /*    outline: none !important;*/
    /*    width: 40% !important;*/
    /*    font-style: normal;*/
    /*    font-size: 16px;*/
    /*    float: left;*/
    /*}*/
    .badge-inner-right{
        max-width: 50%;
        height: 70%;
        display: flex;

    }
    .badge-inner-right > img{
        height: 300px;
        width: 400px;
    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 50px;
    }
    .chexwork-img{
        height: 350px;
        width: 320px;
    }
    .chexwork-image-title{
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 115.5%;
        margin-top: 10px;
        max-width: 320px;
        margin-left: 10px;
    }
    .chexwork-image-desc{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-top: 10px;
        color: #6B717B;
        width: 100%;
        max-width: 320px;
    }
    .chex-demo-video{
        border-radius: 3% ;
        height: auto;
        width: 100%;
        /*border: 1px solid red;*/
    }
    /*.rec.rec-arrow-right {*/
    /*    margin-top: 400px;*/
    /*    background-color: #F4F9FF;*/
    /*    border-radius: 30px;*/
    /*    position: absolute;*/
    /*    right:11%;*/
    /*    !*margin-right: 30px;*!*/

    /*}*/
    /*.rec.rec-arrow-left{*/
    /*    margin-top: 400px;*/
    /*    background-color:#F4F9FF;*/
    /*    border-radius: 30px;*/
    /*    position: absolute;*/
    /*    left: 75.5%;*/
    /*}*/
}
@media only screen and (max-width: 900px) {
    .badge-inner-left-home{
        width: 40%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto !important;
        display: flex;
        align-items: center;
        /*background-color:palevioletred;*/
    }

    .badge-main-heading{
        font-family: Poppins;
        font-size: 24px!important;
        line-height: 43px !important;
        text-align: left;
        font-weight: bolder;
        color: #FFFFFF;
    }

    .badge-inner-right{
        max-width: 100%;
        height: 100%;

    }
    .badge-inner-right > img{
        height: 300px;
        width: 350px;

    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 60px;
    }

}
@media only screen and (max-width: 767px) {
    .badge-inner-left-home{
        margin-top: 0px;
    }
    .badge-image-inner-container{
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        /*background-color:bisque;*/
    }
    .badge-inner-left-home{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 28px;
        line-height: 53px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 40% !important;
        font-style: normal;
        font-size: 16px;
        float: left;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 80%;
        display: none;
    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 60px;
    }
    .chex-demo-video{
        border-radius: 3% ;

        width: 100%;
        /*border: 1px solid green;*/
    }
    .rec.rec-arrow-right {
        z-index: 1;
        right:10% ;


    }
    .rec.rec-arrow-left{
        right: 20%;

    }
}
@media only screen and (max-width: 600px) {
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /*background-color: #8c9dae;*/
    }
    .badge-inner-left-home{
        margin-top: 10px;
        width: 100%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /*background-color: #2a2a2a;*/
    }
    .badge-inner-left-home{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
       align-items: center;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 35px;
        line-height: 53px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 40% !important;
        font-style: normal;
        font-size: 16px;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 80%;
        display: none;
    }
    .chexWorks-main-container{
        width: 100%;
        padding-bottom: 80px;
        display: flex;
    }
    .chexworks-column{
        text-align: center;
    }
    .chexwork-img{
        height: 270px;
        width: 270px;
    }
    .chexwork-image-title{
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 115.5%;
        margin-top: 20px;
        max-width: 270px;
        text-align: center;
    }
    .chexwork-image-desc{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-top: 10px;
        color: #6B717B;
        width: 100%;
        max-width: 270px;
        text-align: center;
    }
    .chex-demo-video-container{
        width: 100%;
        height: 450px;
        /*background: url("../../Assets/videoBg.png");*/
        /*background-repeat: no-repeat;*/
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .chex-demo-video{
        border-radius: 3% ;
      width: 90%;
        border: 1px solid blue;
    }
    .rec.rec-arrow-right {
        z-index: 1;

        right:10%;

    }
    .rec.rec-arrow-left{
       right: 21%;
    }
}
@media only screen and (max-width: 550px){
    .chexwork-img{
        height: 270px;
        width: 270px;
    }
    .chexwork-image-title{
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        font-style: normal;
        line-height: 115.5%;
        margin-top: 10px;
        max-width: 270px;
    }
    .chexwork-image-desc{
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        margin-top: 10px;
        color: #6B717B;
        max-width: 270px;
    }
    .chex-demo-video{
        border-radius: 3% ;
       width: 80%;
        border: 1px solid transparent;
    }
}
@media only screen and (max-width: 480px) {
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /*background-color: #8c9dae;*/
    }
    .badge-inner-left-home{
        width: 100%;
        height: 50%;
    }
    .badge-image-inner-container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        /*background-color: peru;*/
    }
    .badge-inner-left-home{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 30px;
        line-height: 53px;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
    .contact-submit-btn {
        background: #2584e0 !important;
        border: none !important;
        outline: none !important;
        width: 60% !important;
        font-style: normal;
        font-size: 16px;
    }
    .badge-inner-right{
        max-width: 50%;
        height: 80%;
        display: none;
    }
    .chexWorks-main-container{
        width: 100%;
        /*background-color: #F4F9FD;*/
        padding-bottom: 80px;
    }
    .chex-demo-video-container{
        width: 100%;
        height: 300px;
        /*background: url("../../Assets/videoBg.png");*/

    }
    .chex-demo-video{
        border-radius: 15px ;

        width: 90%;
        /*border: 1px solid red;*/
        margin-left: -5px;
    }

    .review_container_main{
        width: 100%;

        background-color: #FFFFFF;
        padding-top: 30px;
    }


    .rec.rec-arrow-right {

        right:10%;


    }
    .rec.rec-arrow-left{

        right: 25%;
    }
}


@media  screen and (max-width: 400px){

    .badge-main-heading{
        font-family: Poppins;
        font-size: 18px!important;
        line-height: 26px!important;
        text-align: center;
        font-weight: bolder;
        color: #FFFFFF;
    }
}


@media  screen and (max-width: 360px){
    .rec.rec-arrow-right {

        right:10%;

    }
    .rec.rec-arrow-left{
      right: 28%;
    }
    .badge-main-heading{
        font-family: Poppins;
        font-size: 16px!important;

    }
}


@media  screen and (max-width: 330px){
    .rec.rec-arrow-right {

        right:10%;

    }
    .rec.rec-arrow-left{
        right: 30%;
    }
}


/*pricing card styling*/
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap');
.priceCardContainer_about{
    background-color:white;
    border: 15px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 20px 20px 90px rgba(13, 58, 126, 0.1);
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 10px;

    /*width: 300px;*/
    /*height: 200px;*/
    width:100%;
    height:240px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;

    /*text-align: center;*/


    /*margin:100px;*/
}
.pricetagBg_about {
    background-color: #FF7A00;
    border-radius: 5px;
    position: relative;
    left: -40px;
    margin-top: -60px;
    /*top:0px;*/
    /*left:0px;*/
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 35px;

}

.pricecardHeading_about{
    color: #FF7A00;
    font-family:Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 75px;
    line-height: 28px;
    margin-top: 20px;
    padding-top: 35px;

}
.pricecardParagraph_about{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    padding-right: 10px;
    padding-left:10px;
    word-spacing: 2px;
    letter-spacing: 2px;

    color: #5D6F84;
}

.pricetagBg_aboutPriceTagClr{
    /*background: #FF7A00;*/
    border-radius: 5px;
    position: relative;
    left:-40px;
    margin-top:-60px;
    /*top:0px;*/
    /*left:0px;*/
    text-align: center;
    padding-left:20px;
    padding-right: 20px;
    background-color: #2584E0!important;
    height: 35px;
}
/*.PriceTagClr{*/
/*    background-color: #2584E0!important;*/
/*}*/

.pricetagText_about{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /*line-height: 14px;*/
    /* identical to box height */
    /*background-color: aqua;*/
    padding-top: 5px;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    /*word-spacing: 1px;*/
    letter-spacing: 2px;

    color: #FFFFFF;
}
@media screen and (max-width: 1547px) {

    .pricetagBg_about {
        background-color: #FF7A00;
        border-radius: 5px;
        position: relative;
        left: -30px;
        margin-top: -30px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        height: 35px;

    }
    .pricetagBg_aboutPriceTagClr{
        /*background: #FF7A00;*/
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-30px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;
        padding-left:20px;
        padding-right: 20px;
        background-color: #2584E0!important;
        height: 35px;
    }
    .pricecardHeading_about{
        color: #FF7A00;
        font-family:Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 65px;
        line-height: 28px;
        margin-top: 20px;
        padding-top: 20px;

    }
    .pricecardParagraph_about{
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        padding-right: 10px;
        padding-left:10px;
        word-spacing: 2px;
        letter-spacing: 2px;

        color: #5D6F84;
    }

}

@media screen and (max-width: 1200px) {

    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-110px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-90px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagText_about{
        font-size: 15px!important;
    }

}


@media screen and (max-width: 1100px){
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-90px;
        margin-top:-90px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-80px;
        margin-top:-90px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }

    .pricecardHeading_about{

        font-size: 40px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;


        color: #5D6F84;
    }

}

@media screen and (max-width:935px){
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-60px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-50px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagText_about{
        font-size: 16px!important;
    }
    .pricecardHeading_about{

        font-size: 45px;
        line-height: 25px;
        margin-top: 35px;
    }
    .pricecardParagraph_about{

        font-size: 20px;
        padding-right: 3px;
        padding-left:3px;

        color: #5D6F84;
    }
    .pricetagText_about{
        font-size: 12px;
    }

}
@media screen and (max-width:890px){
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-40px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-70px;
        /*top:0px;*/
        /*left:0px;*/
        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }


}

@media screen and (max-width: 767px) {
    .priceCardContainer_about{
        width: 300px;
        margin-left: 180px;
    }

    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-10px;
        margin-top:-50px;

        /*top:0px;*/
        /*left:0px;*/

        text-align: center;

        padding-left:20px;
        padding-right: 20px;
    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-10px;
        margin-top:-60px;

        /*top:0px;*/
        /*left:0px;*/

        text-align: center;

        padding-left:10px;
        padding-right: 10px;
    }
    .pricecardHeading_about{

        font-size: 40px;
        line-height: 25px;
        margin-top: 25px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
    .pricetagText_about{
        font-size: 14px;
    }
}

@media screen and (max-width: 620px) {
    .priceCardContainer_about{
        width: 400px;
        margin-left: 80px;
    }
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-50px;
        margin-top:-90px;


    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-50px;
        margin-top:-80px;

    }
}


@media screen and (max-width: 550px) {
    .priceCardContainer_about{
        width: 400px;
        margin-left: 40px;
    }
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-60px;
        margin-top:-90px;

    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-60px;

        margin-top:-90px;

    }

    .pricecardHeading_about{

        font-size: 40px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 22px;

    }
    .pricetagText_about{
        font-size: 20px;
    }

}
@media screen and (max-width: 500px) {
    .priceCardContainer_about {
        width: 350px;
        margin-left: 40px;
    }
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-40px;
        margin-top:-90px;

    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-90px;

        padding-left:20px;
        padding-right: 20px;}
    .pricecardHeading_about{

        font-size: 50px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
}

@media screen and (max-width: 460px) {
    .priceCardContainer_about {
        width: 350px;
        margin-left: 20px;
    }
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-40px;
        margin-top:-90px;

    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-90px;

        padding-left:20px;
        padding-right: 20px;}
    .pricecardHeading_about{

        font-size: 50px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
}
@media screen and (max-width: 412px) {
    .pricetagBg_about{
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left:-30px;
        margin-top:-90px;

    }
    .pricetagBg_aboutPriceTagClr{
        background-color: #2584E0!important;
        border-radius: 5px;
        position: relative;
        left:-20px;
        margin-top:-90px;

    }
    .priceCardContainer_about {
        width: 100%;
        margin-left: 0px;
    }
    .pricecardHeading_about{

        font-size: 50px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
    /*.pricetagBg_about PriceTagClr{*/
    /*    background: #FF7A00;*/
    /*    border-radius: 5px;*/
    /*    position: relative;*/
    /*    left:-60px;*/
    /*    margin-top:-70px;*/
    /*    !*top:0px;*!*/
    /*    !*left:0px;*!*/
    /*    text-align: center;*/

    /*    padding-left:10px;*/
    /*    padding-right: 10px;*/
    /*}*/
}
@media screen and (max-width: 372px) {
    .pricetagBg_about {
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left: -20px;
        margin-top: -60px;

    }
    .pricetagBg_aboutPriceTagClr {
        background-color: #2584E0 !important;
        border-radius: 5px;
        position: relative;
        left: -10px;
        margin-top: -60px;

    }
    .pricetagText_about{
        font-size: 14px!important;
    }
    .pricecardHeading_about{

        font-size: 50px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 20px;

    }
}

@media screen and (max-width: 340px) {
    .pricetagBg_about {
        background: #FF7A00;
        border-radius: 5px;
        position: relative;
        left: -20px;
        margin-top: -60px;

    }
    .pricetagBg_aboutPriceTagClr {
        background-color: #2584E0 !important;
        border-radius: 5px;
        position: relative;
        left: -0px;
        margin-top: -50px;

    }
    .pricetagText_about{
        font-size: 14px!important;
    }
    .pricecardHeading_about{

        font-size: 48px;
        line-height: 25px;
        margin-top: 20px;
    }
    .pricecardParagraph_about{

        font-size: 18px;

    }
}

/*pricing card styling end*/


/*.about_section_main{*/
/*    background-color: #FFFFFF;*/

/*    margin-bottom: 20px;*/

/*}*/
/*.about_desc_div{*/
/*    padding:60px;*/
/*    padding-bottom: 30px;*/
/*    width: 100%;*/
/*}*/
/*.paraAbout{*/
/*    font-size: 18px;*/
/*}*/
/*@media screen and (max-width:480px ){*/
/*    .about_desc_div{*/
/*        padding:30px;*/
/*        padding-bottom: 30px;*/
/*        width: 100%;*/
/*    }*/
/*}*/
/*@media screen and (max-width:331px ){*/
/*    .about_desc_div{*/
/*        padding:20px;*/
/*        padding-bottom: 20px;*/
/*        width: 100%;*/
/*    }*/
/*    .about-chex-left-title{*/
/*        font-size: 24px!important;*/
/*    }*/
/*    .about-chex-left-title-span{*/
/*        font-size: 24px!important;*/
/*    }*/
/*    .paraAbout{*/
/*        font-size: 16px;*/
/*    }*/
/*}*/
/*.slider_div_main{*/
/*    background-color: #F4F9FD;*/
/*    height: 150px;*/
/*    flex-direction: column;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/


/*.scrollContainer_mainDiv{*/
/*    width: 100%;*/

/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*}*/
/*.heading_partners{*/
/*    font-size:30px;*/
/*    font-family: Poppins;*/
/*    font-weight: bold;*/
/*    justify-content: center;*/
/*    align-items:center;*/
/*    color:#C5CBD3;*/
/*}*/

/*@media screen and (max-width: 500px) {*/
/*    .heading_partners{*/
/*        font-size:20px;*/
/*    }*/
/*}*/

/*.partner_div{*/
/*    !*background-color: green;*!*/
/*    padding:10px;*/
/*    margin-top: 10px;*/

/*}*/

/*@media screen and (min-width: 414px) {*/
/*    .partner_div{*/
/*        padding:10px;*/
/*        margin-top: 10px;*/
/*        width: 300px*/

/*    }*/
/*}*/

/*.pricing_main_div{*/
/*    background-color: #FFF8F1;*/
/*    padding-top: 60px;*/
/*    padding-left: 30px;*/
/*}*/
/*.pricingCardDiv{*/
/*    margin-right: 10px;*/
/*}*/
/*.mainDiv_pricingcards{*/
/*    padding:20px;*/
/*    padding-left: 0px;*/
/*}*/


/*.pricingParaDesign{*/
/*    color:#B8A59C;*/
/*    font-size: 14px;*/
/*    padding-right: 20px;*/
/*}*/
/*@media screen and (max-width: 413px) {*/
/*    .slider_div_main{*/
/*        background-color: #F4F9FD;*/

/*        height: 250px;*/
/*    }*/
/*    .scrollContainer_mainDiv{*/
/*        width: 100%;*/

/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: flex-start;*/
/*        align-items: center;*/
/*    }*/
/*}*/


/*@media screen and (max-width: 1200px){*/
/*    .pricingCardDiv{*/
/*        margin-top: 50px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 430px){*/
/*    .scrollContainer_mainDiv{*/
/*        !*width: 80%;*!*/
/*        !*background-color: #d7efff;*!*/
/*        !*height: 50px;*!*/
/*        !*!*padding-bottom: 10%;*!*!*/
/*        !*position: relative;*!*/
/*        !*!*margin-top: 30%;*!*!*/
/*        display: flex;*/
/*        !*flex-direction: row;*!*/
/*        !*justify-content: flex-start;*!*/
/*        !*align-items: center;*!*/
/*    }*/

/*}*/
/*@media screen and (max-width: 767px){*/
/*    .pricingCardDiv{*/
/*        padding-bottom: 10px;*/
/*    }*/
/*}*/



/*business section styling*/

.homeBusinessSection{
    padding-bottom: 40px;
}
.businesstextContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start ;
    margin-top: 70px;
}
.dashboard-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    /*line-height: 41px;*/
    color: #1A1C1F;
    padding-right: 40px;
}
.dashboard-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 550;
    font-size: 22px;
    color: #6B717B;
}

.BusinessImageContainer{
    padding:30px;
    background-color: #FFFFFF;
}
.BusinessImageContainer  img{
    width: 100%;
}

@media screen and (max-width: 1100px){
    .businesstextContainer{
        margin-top: 30px;
    }
}
@media screen and (max-width: 767px){
    .businesstextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        margin-top: 55px;
        padding-left: 40px;
        padding-right: 40px;
    }
    .dashboard-heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 34px!important;
        /*line-height: 41px;*/
        color: #1A1C1F;
        padding-right: 40px;
        text-align: center;
    }
    .dashboard-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 550;
        text-align: center;
        font-size: 22px;
        color: #6B717B;
    }
    .BusinessImageContainer{
        margin-top: 50px;
        padding:20px;
        background-color: #FFFFFF;
    }

}


@media screen and (max-width: 510px) {
    .businesstextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        margin-top: 20px;
        padding-left: 10px;
        padding-right:10px;
    }
    .dashboard-heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 26px!important;
        /*line-height: 41px;*/
        color: #1A1C1F;
        padding-right: 40px;
        text-align: center;
    }
    .dashboard-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 550;
        text-align: center;
        font-size: 18px;
        color: #6B717B;
    }

}


@media screen and (max-width: 400px) {
    .businesstextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        margin-top: 20px;
        padding-left: 10px;
        padding-right:10px;
    }
    .dashboard-heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px!important;
        /*line-height: 41px;*/
        color: #1A1C1F;
        padding-right: 40px;
        text-align: center;
    }
    .dashboard-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 550;
        text-align: center;
        font-size: 16px;
        color: #6B717B;
    }

}






.dashboard-heading{
    font-size: 40px;
    font-weight: bold;
}
.dashboard-description{
    font-size: 18px;
}

.headingCustomerReviews{
    padding-left: 70px;
    font-size:35px;
    color: black;
    font-weight: bold;
}
@media screen and (max-width: 767px) {
    .headingCustomerReviews{
        padding-left:30px ;
        font-size:28px;
    }

}
@media screen and (max-width: 570px) {
    .headingCustomerReviews{
        padding-left:20px ;
        font-size:20px;
    }

}
@media screen and (max-width: 455px) {
    .headingCustomerReviews{
        padding-left:20px ;
        font-size:34px;
    }

}
@media screen and (max-width: 320px) {
    .headingCustomerReviews{
        padding-left:20px ;
        font-size:24px;
    }

}





/*About Section New Desgin Styling Start*/
.new_btn_AboutSection{
    margin-top: 70px !important;
    margin-bottom: 70px !important;
}
.new_about_section_main{
    padding-left:110px;
    padding-right:110px;
    padding-top: 110px;
    padding-bottom: 110px;
}
.about-chex-left-title-span_main_new{
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    text-align: left;
    color: #FF7A00;
}
.about_Content_section_wrapper{
    text-align: center;
}
.paraAbout_new{

    font-size: 20px;

    color:#000000;
    font-family: Poppins;
}
.about-chex-inner-left-desc-span_new_design{
    color:#FF7A00;
    font-size: 20px;
}
.techStar_LogoCompany{
    padding-top: 20px;

}

.text_techStart_para{
font-family: Poppins;
    font-size: 20px;
    color:#6B717B;
}

.a_text{
    font-size: 22px !important;
}
.img_techStar{
    width:10%;

}
.about-chex-left-title_main_new{
font-family: Poppins, sans-serif;
font-size: 36px;
font-style: normal;
font-weight: bolder;
line-height: 44px;
letter-spacing: 1px;
}
.company_logos_New{
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    width: 100%;


}
.item_company img{
    width: 160px;
    height: 130px;
    /*margin-left: 90px;*/

}



@media screen and (max-width: 1200px){
    .item_company img{
        width: 130px;
        height: 100px;
        /*margin-left: 60px;*/

    }
    .img_techStar{
        width:12%;

    }
}
@media screen and (max-width: 1030px){
    .item_company img{
        width: 110px;
        height: 80px;
        /*margin-left: 50px;*/

    }
    .img_techStar{
        width:14%;

    }
}

@media screen and (max-width: 850px){

    .paraAbout_new{
        font-size: 18px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 18px;
    }
    .item_company img{
        /*width: 100px;*/
        /*height: 80px;*/
        /*margin-left: 30px;*/

    }
    .img_techStar{
        width:17%;

    }
}

@media screen and (max-width: 767px){
    .techStar_LogoCompany{
        padding-top: 10px;

    }
    .new_btn_AboutSection{
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .text_techStart_para{
        font-family: Poppins;
        font-size: 24px;
    }
    .a_text{
        font-size: 22px;
    }

    .img_techStar{
        width:20%;

    }
    .paraAbout_new{
        font-size: 20px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 20px;
    }
    /*.item_company img{*/
    /*    width: 70px;*/
    /*    height: 50px;*/
    /*    margin-left: 30px;*/

    /*}*/
}


@media screen and (max-width: 720px){

    .new_about_section_main{
        padding-left:60px;
        padding-right:60px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .techStar_LogoCompany{
        padding-top: 10px;

    }

    .text_techStart_para{
        font-family: Poppins;
        font-size: 28px;
    }
    .a_text{
        font-size: 25px !important;
    }

    .img_techStar{
        width:23%;

    }
    .paraAbout_new{
        font-size: 20px;
        color: black !important;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 20px;
    }
    /*.item_company img{*/
    /*    width: 90px;*/
    /*    height: 70px;*/
    /*    margin-bottom: 30px;*/
    /*    margin-left: 20px;*/

    /*}*/
}

@media screen and (max-width: 600px){

    .new_about_section_main{
        padding-left:40px;
        padding-right:40px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .techStar_LogoCompany{
        padding-top: 10px;

    }

    .text_techStart_para{
        font-family: Poppins;
        font-size: 24px;
    }
    .a_text{
        font-size: 22px !important;
    }


    .img_techStar{
        width:30%;

    }
    .paraAbout_new{
        font-size: 20px;
    }
    .item_company img{
        width: 100px;
        height: 80px;


    }
}


@media screen and (max-width: 550px){

    .new_about_section_main{
        padding-left:40px;
        padding-right:40px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .techStar_LogoCompany{
        padding-top: 10px;

    }
    .new_btn_AboutSection{
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .text_techStart_para{
        font-family: Poppins;
        font-size: 20px;
    }
    .a_text{
        font-size: 18px !important;
    }


    .img_techStar{
        width:30%;

    }
    .paraAbout_new{
        font-size: 18px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 18px;
    }
    .item_company img{
        width:110px;
        height: 90px;


    }
}

@media screen and (max-width: 500px) {

    .paraAbout_new{
        font-size: 16px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 16px;
    }

}

@media screen and (max-width: 460px) {
    .new_about_section_main{
        padding-left:20px;
        padding-right:20px;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .techStar_LogoCompany{
        padding-top: 10px;

    }

    .paraAbout_new{
        font-size: 16px;
    }


    .item_company img{
        width: 100px;
        height: 70px;


    }
}

@media screen and (max-width: 400px) {


    .paraAbout_new{
        font-size: 14px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 14px;
    }

}

@media screen and (max-width: 360px) {
    .new_about_section_main{
        padding-left:10px;
        padding-right:10px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .new_btn_AboutSection{
        margin-top: 50px !important;
        margin-bottom: 30px !important;
    }

    .text_techStart_para{
        font-size: 16px;
    }
    .a_text{
        font-size: 14px !important;
    }

    .paraAbout_new{
        font-size: 11px;
    }
    .about-chex-inner-left-desc-span_new_design{
        color:#FF7A00;
        font-size: 11px;
    }
    .about-chex-left-title-span_main_new{
        font-family: 'Poppins', sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        text-align: left;
        color: #FF7A00;
    }
    .about-chex-left-title_main_new{
        font-family: Poppins, sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: bolder;
        line-height: 44px;
        letter-spacing: 1px;
    }
    /*.item_company img{*/
    /*    width: 60px;*/
    /*    height: 40px;*/
    /*    margin-bottom: 0px;*/
    /*    margin-left: 5px;*/

    /*}*/

}



/*About Section New Design Styliong End*/


/*Company Logo Section New Design*/

.companylogo_container{
    position: relative;
    margin-top: 50px;

}

.companylogo_container .rec.rec-arrow-right {
    background-color: #F4F9FF;
    border-radius: 30px;
    position: absolute;
    top:25%;
    right:4%;
    bottom:-10%;


}
.companylogo_container .rec.rec-arrow-left {
    background-color: #F4F9FF;
    border-radius: 30px;
    position: absolute;
    top:25%;
    display: none;
    left:4%;
    z-index: 1;
    bottom:0% !important;
    position: absolute;

    /*right: 10%;*/


}

@media screen and (max-width: 580px) {
    .companylogo_container .rec.rec-arrow-right {
        background-color: #F4F9FF;
        border-radius: 30px;
        position: absolute;
        top:20%;
        right:4%;
        bottom:-10%;


    }
}


@media screen and (max-width: 400px) {
    .companylogo_container .rec.rec-arrow-right {
        background-color: #F4F9FF;
        border-radius: 30px;
        position: absolute;
        top:16%;
        right:4%;
        bottom:-10%;


    }
}



/*.rec.rec-arrow-right {*/
/*    background-color: #F4F9FF;*/
/*    border-radius: 30px;*/
/*    position: absolute;*/
/*    right:6%;*/
/*    bottom:-10%;*/

/*}*/
/*.rec.rec-arrow:hover{*/
/*    background-color: #F4F9FF!important;*/
/*    color:black!important;*/
/*}*/

/*.rec.rec-arrow-left{*/

/*    background-color:#F4F9FF;*/
/*    border-radius: 30px;*/
/*    z-index: 1;*/
/*    bottom:-10%;*/
/*    position: absolute;*/

/*    right: 10%;*/
/*}*/
